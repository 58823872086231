import React from 'react';
import { Link } from 'react-router-dom';

import styled from 'styled-components';
import { Col, Row } from 'antd';
import { config } from '../config';
import { Newsletter } from './Newsletter';
import device from './utils/devices';

const ATBVenturesLogo = styled.img`
  width: 300px;
  margin-top: 1rem;
`;

const CopyrightText = styled.p`
  color: white;
  font-size: 0.8rem;
  margin-top: 3rem;
  text-align: center;
`;

const FooterWrapper = styled.div`
  padding-top: 3rem;
  padding-bottom: 3rem;
  padding-left: 3rem;
  padding-right: 3rem;
  background-color: black;
  bottom: 0;
  z-index: 20;
`;

const GroupCol = styled(Col)``;
const FooterLinkCol = styled(Col)`
  @media ${device.tablet} {
    padding-left: 5rem;
    padding-right: 5rem;
  }
`;

export interface PageLink {
  title: string;
  url: string;
  isExternal?: boolean;
}

export interface PageGroup {
  title: string;
  url: string;
  isExternal?: boolean;
  subpages: PageLink[];
}

export function Footer() {
  const footerLinks: PageGroup[] = [
    {
      title: 'Access',
      url: '#',
      subpages: [
        {
          title: 'API Catalogue',
          url: '/home/apis-and-sdks',
        },
        {
          title: 'Algorithm Tour',
          url: `${config.cms.url}/openai`,
          isExternal: true,
        },
        {
          title: 'Open Data Usecases',
          url: `${config.cms.url}/opendata`,
          isExternal: true,
        },
      ],
    },
    {
      title: 'Resources',
      url: `${config.cms.url}/dev`,
      isExternal: true,
      subpages: [
        { title: 'About Us', url: `${config.cms.url}/dev`, isExternal: true },
        {
          title: 'Terms + Conditions',
          url: `${config.cms.url}/dev/termsconditions`,
          isExternal: true,
        },
        {
          title: 'Privacy Policy',
          url: `${config.cms.url}/dev/privacypolicy`,
          isExternal: true,
        },
      ],
    },
  ];

  const getPageLinks = (pageLinks: PageLink[]) =>
    pageLinks.map((pageLink: PageLink) => {
      var link;
      if (pageLink.isExternal)
        link = (
          <a className="footer-pagelink" href={pageLink.url}>
            {pageLink.title}
          </a>
        );
      else
        link = (
          <Link to={pageLink.url} className="footer-pagelink">
            {pageLink.title}
          </Link>
        );
      return (
        <div key={pageLink.title}>
          {link}
          <br />
        </div>
      );
    });

  const getPageGroup = (pageGroup: PageGroup) => {
    return (
      <GroupCol key={pageGroup.url} xs={12} sm={12} md={12} lg={12} xl={12}>
        <h1 className="footer-pagetitle">{pageGroup.title}</h1>
        {getPageLinks(pageGroup.subpages)}
      </GroupCol>
    );
  };

  return (
    <FooterWrapper>
      <Row>
        <Col xs={24} sm={12} md={12} lg={8} xl={8}>
          <a href="https://atbventures.com/">
            <ATBVenturesLogo src="/atbventures.png" />
          </a>
        </Col>
        <Col xs={24} sm={12} md={12} lg={5} xl={5}>
          <Newsletter />
        </Col>
        <FooterLinkCol xs={24} sm={24} md={24} lg={11} xl={11}>
          <Row>
            {footerLinks.map((pageGroup: PageGroup) => getPageGroup(pageGroup))}
          </Row>
          <Row>
            <CopyrightText className="word-wrap">
              {' '}
              &#169; 2020 ATB Financial
            </CopyrightText>
          </Row>
        </FooterLinkCol>
      </Row>
    </FooterWrapper>
  );
}
