import axios from 'axios';
import { config } from '../config';
import { Application } from './types';

const { portal } = config;

// const instance = axios.create({
//     baseURL: portal.apiUrl + '/devapps',
//     headers: {‘Some-Auth-Header’: ‘token’}
//     });

let devappUrl = portal.apiUrl + '/devapps';

const retrieveApplications = async (jwt: string): Promise<Application[]> => {
  const response = await axios.get(devappUrl, {
    headers: { Authorization: 'Bearer ' + jwt },
  });
  return response.data;
};

const retrieveApplicationDetails = async (
  jwt: string,
  appName: string,
): Promise<Application> => {
  const response = await axios.get(devappUrl + '/' + appName, {
    headers: { Authorization: 'Bearer ' + jwt },
  });
  return response.data;
};

const generateNewKeyPairs = async (
  jwt: string,
  appName: string,
): Promise<Application> => {
  const response = await axios.post(
    devappUrl + '/' + appName,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + jwt,
        'Content-Type': 'application/json',
      },
    },
  );

  return response.data;
};

const deleteKeyPair = async (
  jwt: string,
  appName: string,
  appKey: string,
): Promise<any> => {
  const response = await axios.delete(
    devappUrl + '/' + appName + '/keys/' + appKey,
    {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    },
  );
  return response.data;
};

const createApplication = async (
  jwt: string,
  application: Application,
): Promise<Application> => {
  var data = {
    app: {
      ...application,
      displayName: application.appName,
    },
  };

  const response = await axios.post(devappUrl, data, {
    headers: {
      Authorization: 'Bearer ' + jwt,
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

const updateApplication = async (
  jwt: string,
  application: Application,
): Promise<Application> => {
  var data = {
    app: {
      appName: application.appName,
      appType: application.appType,
      appLogo: application.appLogo,
      displayName: application.appName,
      redirectUrl: application.redirectUrl,
      homepageLink: application.homepageLink,
      appDescription: application.appDescription,
      privacyPolicyLink: application.privacyPolicyLink,
      termsOfServiceLink: application.termsOfServiceLink,
    },
  };
  const response = await axios.put(
    devappUrl + '/' + application.appName,
    data,
    {
      headers: {
        Authorization: 'Bearer ' + jwt,
        'Content-Type': 'application/json',
      },
    },
  );

  return response.data;
};

const deleteApplication = async (
  jwt: string,
  appName: string,
): Promise<Application> => {
  const response = await axios.delete(devappUrl + '/' + appName, {
    headers: {
      Authorization: 'Bearer ' + jwt,
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

const updateDatasetApplications = async (
  jwt: string,
  datasetId: string,
  appNames: string[],
) => {
  var data = {
    datasetId,
    appNames,
  };

  const response = await axios.post(`${devappUrl}/application-datasets`, data, {
    headers: {
      Authorization: `Bearer ${jwt}`,
      'Content-Type': 'application/json',
    },
  });
  return response.data;
};

const retrieveDatasetApplications = async (jwt: string, datasetId: string) => {
  const response = await axios.get(
    `${devappUrl}/application-datasets/${datasetId}`,
    {
      headers: {
        Authorization: `Bearer ${jwt}`,
      },
    },
  );
  return response.data.map((record: any) => record.app_name);
};

const getErrorMessage = (error: any) => {
  const data = error.response ? error.response.data : error;
  return data.message
    ? data.message
    : data.status
    ? `${data.status} - ${data.statusText}`
    : 'Server error';
};

const createEmptyApplication = (): Application => {
  return {
    appId: '',
    appType: '',
    appName: '',
    redirectUrl: '',
    devEmail: '',
    credentials: [],
    appDescription: '',
    displayName: '',
    environment: '',
    loginUrl: '',
    sandbox1username: '',
    sandbox1password: '',
    sandbox2username: '',
    sandbox2password: '',
    homepageLink: '',
    privacyPolicyLink: '',
    termsOfServiceLink: '',
    appLogo: '',
  };
};

export {
  deleteKeyPair,
  getErrorMessage,
  createApplication,
  updateApplication,
  deleteApplication,
  generateNewKeyPairs,
  retrieveApplications,
  createEmptyApplication,
  updateDatasetApplications,
  retrieveApplicationDetails,
  retrieveDatasetApplications,
};
