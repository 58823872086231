import axios from 'axios';
import { config } from '../config';

const { portal } = config;

const instance = axios.create({
  baseURL: portal.apiUrl,
  headers: {
    'Content-Type': 'application/json',
  },
});

const sendLog = (error: any) => {
  instance.post('/sendLog', {
    event: error,
  });
};

export { sendLog };
