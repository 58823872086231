import React from 'react';
import { Table } from 'antd';
import { Application } from '../../../api/types';
import { ApplicationProps } from './ApplicationList';

type SortType = 'ascend' | 'descend' | undefined;
const ascending: SortType = 'ascend';

const DesktopApplicationTable = (props: ApplicationProps) => {
  const columns = [
    {
      title: 'App Name',
      dataIndex: 'displayName',
      key: 'displayName',
      defaultSortOrder: ascending,
      sorter: (a: Application, b: Application) =>
        a.displayName.localeCompare(b.displayName),
    },
    {
      title: 'App Type',
      dataIndex: 'appType',
      key: 'appType',
    },
    {
      title: 'URL',
      dataIndex: 'redirectUrl',
      key: 'redirectUrl',
    },
    {
      title: 'Description',
      dataIndex: 'appDescription',
      key: 'appDescription',
    },
    {
      title: 'Actions',
      key: 'actions',
      render: (text: string, application: Application) => (
        <span>
          <button
            type="button"
            className="link-button highlighted-link"
            onClick={() => props.onRowClick(application)}
          >
            Edit
          </button>
        </span>
      ),
    },
  ];

  return (
    <Table
      columns={columns}
      dataSource={props.applications}
      loading={props.loading}
      pagination={false} // We just have 3 apps per user
      rowKey="appName"
    />
  );
};

export default DesktopApplicationTable;
