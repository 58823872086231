import React from 'react';

export interface ContentProps {
  wide?: boolean;
  noMenu?: boolean;
  children: any;
}

export function Content({ children, wide, noMenu }: ContentProps) {
  return <div className="content">{children}</div>;
}
