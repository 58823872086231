import { connect } from 'react-redux'
import { Dispatch, bindActionCreators } from 'redux'
import { fetchDocs, renderDocs } from '../redux/docsSlice'
import { ApiDocs } from '../components/screens/ApiDocs'
import { RootState } from '../App'

function mapStateToProps(state: RootState) {
  return {
    loading: state.docs.loading,
    apiNav: state.docs.apiNavSections,
    institutions: state.user.institutions,
    orderedApiDocs: state.docs.orderedApiDocs
  }
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      fetchDocs,
      renderDocs
    },
    dispatch
  )
}

export const ApiDocsContainer = connect(
  mapStateToProps,
  mapDispatchToProps
)(ApiDocs)
