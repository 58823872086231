import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { fetchDocs } from '../redux/docsSlice';
import { RootState } from '../App';
import { Profile } from '../components/screens/Profile';

function mapStateToProps(state: RootState) {
  return {
    userName: state.user.userName,
    nickName: state.user.nickName,
    userEmail: state.user.userEmail,
    imageUrl: state.user.userImageUrl,
    emailVerified: state.user.emailVerified,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      fetchDocs,
    },
    dispatch,
  );
}

export const ProfileContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Profile);
