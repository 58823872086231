import React from 'react';
import { Provider } from 'react-redux';
import { Router, Route, Switch } from 'react-router-dom';
import { configureStore } from '@reduxjs/toolkit';
import { createBrowserHistory } from 'history';

import { NotFound } from './components/screens/NotFound';
import { docsReducer, DocsState } from './redux/docsSlice';
import { userReducer, UserState } from './redux/userSlice';
import { HomeContainer } from './container/HomeContainer';

export interface RootState {
  docs: DocsState;
  user: UserState;
}

const history = createBrowserHistory();

const store = configureStore({
  devTools: true,
  reducer: {
    docs: docsReducer,
    user: userReducer,
  },
});

function App() {
  return (
    <Provider store={store}>
      <Router history={history}>
        <Switch>
          <Route exact path="/" component={HomeContainer} />
          <Route path="/home" component={HomeContainer} />
          <Route component={NotFound} />
        </Switch>
      </Router>
    </Provider>
  );
}

export default App;
