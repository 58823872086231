import ReactDOM from 'react-dom';
import * as serviceWorker from './serviceWorker';
// import * as Sentry from '@sentry/browser'
import { config } from './config';
import React from 'react';
import { Auth0Provider } from 'use-auth0-hooks';
import App from './App';
import { sendLog } from './api/Splunk';

//  Sentry.init({dsn: "https://2e266c7fade44b7cb1e9f6d44e37fb39@sentry.io/2258360"});

const { auth0, splunk } = config;

if (splunk.sendLogs === '1') {
  window.addEventListener('error', (event: any) => {
    if (event.error === undefined || event.error.hasBeenCaught !== undefined) {
      return false;
    }
    event.error.hasBeenCaught = true;
    sendLog(event.error.stack);
  });
}

ReactDOM.render(
  <Auth0Provider
    domain={auth0.domain}
    clientId={auth0.clientId}
    redirectUri={auth0.redirectUri}
    audience={auth0.audience}
    scope={auth0.scope}
    onLoginError={(err) => {
      //redirect to verify-email page
    }}
  >
    <App />
  </Auth0Provider>,
  document.getElementById('root'),
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
