import React, { useEffect } from 'react';
import { History } from 'history';
import { Route, match, Switch, withRouter } from 'react-router-dom';
import { useAuth, withLoginRequired } from 'use-auth0-hooks';
import { Layout } from 'antd';

import { NavBar, NavBarItem } from '../NavBar';
import { CenteredSpinner } from '../CenteredSpinner';
import { OpenData } from './OpenData';

import { ApiDocsContainer } from '../../container/ApiDocContainer';
import { ProfileContainer } from '../../container/ProfileContainer';
import { GettingStartedContainer } from '../../container/GettingStartedContainer';
import { NewApplicationContainer } from '../../container/NewApplicationContainer';
import { EditApplicationContainer } from '../../container/EditApplicationContainer';
import { User } from '../types/types';
import OAuth from './OAuth';
import { Footer } from '../Footer';
import { ApplicationsContainer } from '../../container/ApplicationsContainer';

export interface HomeProps {
  history: History;
  match: match;
  setUserData: (userData: User) => void;
  auth: any;
}

function Home(props: any) {
  const { history, match, setUserData } = props;
  const { isLoading, user, isAuthenticated, logout } = useAuth();

  useEffect(() => {
    if (match.path === '/' && match.isExact) {
      history.push('/home/getting-started');
    }
  }, [match, history]);

  useEffect(() => {
    if (isAuthenticated && user) {
      setUserData(user);
    }
  }, [isAuthenticated, user, isLoading, history, setUserData]);

  const menuItems: NavBarItem[] = [
    {
      key: `${match.url}/apis-and-sdks`,
      name: 'APIs & SDKs',
      selected: false,
      onClick: () => history.push(`${match.url}/apis-and-sdks`),
    },
    {
      key: `${match.url}/data-catalogue`,
      name: 'Data Catalogue',
      selected: false,
      onClick: () => history.push(`${match.url}/data-catalogue`),
    },
    {
      key: `${match.url}/applications`,
      name: 'Applications',
      selected: false,
      onClick: () => history.push(`${match.url}/applications`),
    },
    // {
    //   key: 'open-ai',
    //   name: 'OpenAI',
    //   selected: false,
    //   onClick: () => window.open('https://openatb.com/openai', '_blank'),
    // },
    // {
    //   key: 'resources',
    //   name: 'Resources',
    //   selected: false,
    //   onClick: () => window.open('https://openatb.com/dev', '_blank'),
    // },
  ];

  if (isLoading) {
    return <CenteredSpinner />;
  }

  menuItems.forEach((item) => {
    item.selected = history.location.pathname === item.key;
  });

  return (
    <Layout id="main-layout">
      <NavBar items={menuItems} email={user.email} logout={logout} />
      <div id="content">
        <Switch>
          <Route
            path={`${match.path}/welcome`}
            component={GettingStartedContainer}
          />
          <Route path={`${match.path}/oauth`} component={OAuth} />
          <Route
            path={`${match.path}/getting-started`}
            component={GettingStartedContainer}
          />
          <Route
            path={`${match.path}/apis-and-sdks`}
            component={ApiDocsContainer}
          />
          <Route path={`${match.path}/data-catalogue`} component={OpenData} />
          {/* <Route path={`${match.path}/open-ai`} component={OpenAI} /> */}
          <Route path={`${match.path}/profile`} component={ProfileContainer} />
          <Route
            path={`${match.path}/create-application`}
            component={NewApplicationContainer}
          />
          <Route
            path={`${match.path}/edit-application/:appName`}
            component={EditApplicationContainer}
          />
          <Route
            path={`${match.path}/applications`}
            component={ApplicationsContainer}
          />
        </Switch>
      </div>
      <Footer />
    </Layout>
  );
}

export default withLoginRequired(withRouter(Home));
