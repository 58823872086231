import React, { useEffect, useState } from 'react';
import { message, Row, Col } from 'antd';
import { useAuth } from 'use-auth0-hooks';
import { History } from 'history';

import { config } from '../../config';
import { Content } from '../Content';
import { configureMessageContainer, StyledContainer } from '../StyleUtility';
import { retrieveApplications, getErrorMessage } from '../../api/Application';
import { Application } from '../../api/types';
import ApplicationList from './Application/ApplicationList';

const { auth0 } = config;

export interface ApplicationsProps {
  emailVerified: boolean;
  history: History;
}

export function Applications({ history, emailVerified }: ApplicationsProps) {
  const { accessToken } = useAuth({
    audience: auth0.audience,
    scope: auth0.scope,
  });

  const [loadingAppTable, setLoadingAppTable] = useState(emailVerified);
  const [applications, setApplications] = useState([] as Application[]);

  configureMessageContainer();

  useEffect(() => {
    const getApplications = async () => {
      setLoadingAppTable(true);
      const result: Application[] = await retrieveApplications(
        accessToken!,
      ).catch((error) => {
        message.error(getErrorMessage(error));
        return [];
      });
      setApplications(result);
      setLoadingAppTable(false);
    };
    if (emailVerified) getApplications();
  }, [emailVerified, accessToken]);

  const appTableOnRowClick = (application: Application) => {
    history.push({
      pathname: '/home/edit-application/' + application.appName,
    });
  };

  return (
    <StyledContainer>
      <Row>
        <Col xs={24} md={6} sm={24}></Col>
        <Col xs={24} md={12} sm={24}>
          <Content>
            <h2>Applications</h2>
            <ApplicationList
              applications={applications}
              loading={loadingAppTable}
              onRowClick={appTableOnRowClick}
              emailVerified={emailVerified}
            />
          </Content>
        </Col>
      </Row>
    </StyledContainer>
  );
}
