import React, { useState } from 'react';
import { PlusSquareFilled, MinusSquareFilled } from '@ant-design/icons';

interface ResponseSectionProps {
  response: any;
  isCollapsible: boolean;
}

const ResponseSection = ({ response, isCollapsible }: ResponseSectionProps) => {
  const [showResponse, setShowResponse] = useState(!isCollapsible);

  const getIndent = (indent: number) => {
    return (
      <span className="w">
        {'\n'}
        {indent > 0 && ' '.repeat(indent)}
      </span>
    );
  };

  const parseJson = (response: any, indent: number) => {
    const valType = typeof response;

    if (valType === 'string') return <span className="s2">"{response}"</span>;
    if (valType === 'number') return <span className="mf">{response}</span>;
    if (valType === 'boolean')
      return <span className="kc">{String(response)}</span>;
    // it is an object/array
    if (Array.isArray(response)) {
      // Parse each children recursively and create inner components
      const children = [];
      for (var elem of response) children.push(parseJson(elem, indent + 2));

      // Return the children inside []  with proper indentation
      return (
        <React.Fragment>
          <span className="p">{'['}</span>
          {children.map((value, index) => {
            return (
              <React.Fragment key={`ch_${indent}_${index}`}>
                {index > 0 && <span className="p">,</span>}
                {getIndent(indent)}
                {value}
              </React.Fragment>
            );
          })}
          {getIndent(indent - 2)}
          <span className="p">{']'}</span>
        </React.Fragment>
      );
    }

    // complex object, parse recursively
    const children = [];
    for (var key in response) {
      if (response.hasOwnProperty(key)) {
        const result = (
          <React.Fragment>
            <span className="s2">"{key}"</span>
            <span className="p">:</span>
            <span className="w"> </span>
            {parseJson(response[key], indent + 2)}
          </React.Fragment>
        );

        children.push(result);
      }
    }

    return (
      <React.Fragment>
        <span className="p">{'{'}</span>
        {children.map((value, index) => {
          return (
            <React.Fragment key={`ch_${indent}_${index}`}>
              {index > 0 && <span className="p">,</span>}
              {getIndent(indent)}
              {value}
            </React.Fragment>
          );
        })}
        {getIndent(indent - 2)}
        <span className="p">{'}'}</span>
      </React.Fragment>
    );
  };

  const toggleShowResponse = () => {
    setShowResponse(!showResponse);
  };

  const responseBlock = (
    <pre
      className={`highlight ${showResponse ? 'expanded-pre' : 'collapsed-pre'}`}
    >
      <code>{parseJson(response, 0)}</code>
    </pre>
  );

  const titleBlock = (
    <blockquote>
      <div>
        <p>Response</p>
      </div>
      {isCollapsible && (
        <button
          type="button"
          className="expand-button link-button"
          onClick={toggleShowResponse}
        >
          {!showResponse && <PlusSquareFilled />}
          {showResponse && <MinusSquareFilled />}
        </button>
      )}
    </blockquote>
  );
  return (
    <React.Fragment>
      {titleBlock}
      {responseBlock}
    </React.Fragment>
  );
};

export default ResponseSection;
