import React from 'react';
import { Row, Col, Card } from 'antd';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { EyeFilled, SettingOutlined } from '@ant-design/icons';
import { StyledContainer } from '../StyleUtility';

const StyledCard = styled(Card)`
  width: 350px;
  height: 250px;
  border: 1px solid #002761;
  margin: 1rem;
`;

const AddAppWrapper = styled.div`
  margin: 4.5rem 4rem 0 1rem;
`;

const StyledImage = styled.img`
  margin-left: 4rem;
  width: 80%;
`;

const CardDiv = styled.div`
  font-weight: bold;
`;

const SettingsIcon = (
  <SettingOutlined style={{ color: '#0092c3', fontSize: '2rem' }} />
);

const GettingStarted = () => {
  const getStyledCard = (title: string, description: string, actions: any) => {
    return (
      <StyledCard
        title={<CardDiv>{title}</CardDiv>}
        extra={SettingsIcon}
        headStyle={{ border: 0 }}
        actions={actions}
      >
        <p>{description}</p>
      </StyledCard>
    );
  };

  return (
    <StyledContainer>
      <Row>
        <Col md={4} sm={24}>
          <AddAppWrapper>
            <p>
              Create an application to leverage the full API catalogue in your
              product development
            </p>
            <Link to="/home/applications">+ APPLICATIONS</Link>
          </AddAppWrapper>
        </Col>
        <Col md={20} sm={24}>
          <Row>
            <Col md={14} sm={24}>
              <h1>Developing with Open ATB</h1>
              <p>
                Transform your vision into creation on a secure platform through
                a provision of API services. The future of data exchange and
                product development will be built with structured balance
                between innovation &amp; security.
              </p>

              <h2>What you can access</h2>
              <p>
                Developers have extensive access to core banking features. Here
                are some of the resources you can work with:
              </p>
              <ul>
                <li>Use Open Data to access public information</li>
                <li>
                  Use Accounts &amp; Transactions to build the next generation
                  of fintech apps (PSD2 standard)
                </li>
                <li>
                  Use our write APIs to create accounts, transactions and
                  products that suit your customers
                </li>
              </ul>
            </Col>
            <Col md={10} sm={24}>
              <StyledImage
                alt="OpenATB getting started"
                src="/openATB_getting_started.svg"
              />
            </Col>
          </Row>
          <Row>
            {getStyledCard(
              'Code sample',
              'Browse our github repository for code samples',
              [
                <a
                  href="https://github.com/openatb"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CardDiv>
                    VIEW <EyeFilled key="view_code" />
                  </CardDiv>
                </a>,
              ],
            )}
            {getStyledCard(
              'Demo',
              "We've created a sample application which exchanges credentials for token and accesses account information.",
              [
                <a
                  href="https://demo.openatb.com"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  <CardDiv>
                    VIEW <EyeFilled key="view_demo" />
                  </CardDiv>
                </a>,
              ],
            )}
            {getStyledCard(
              'Authentication',
              'How To Use OAuth Authentication with Open ATB as a User or Developer',
              [
                <Link to="/home/oauth">
                  <CardDiv>
                    VIEW <EyeFilled key="view_oauth" />
                  </CardDiv>
                </Link>,
              ],
            )}
          </Row>
        </Col>
      </Row>
    </StyledContainer>
  );
};

export { GettingStarted };
