/* tslint:disable */
/* eslint-disable */
import React, { useState } from 'react';
import {
  buildGetATMsSection,
  buildGetConsentSection,
  buildGetBranchesSection,
  buildCreateConsentSection,
  buildGetCounterpartySection,
  buildExchangeCurrenySection,
  buildGetExchangeRatesSection,
  buildGetCounterpartiesSection,
  buildGetAccountDetailsSection,
  buildGetDomesticPaymentSection,
  buildCreateCounterpartySection,
  buildCancelRevokeConsentSection,
  buildGetAccountDirectDebitsSection,
  buildGetAccountTransactionsSection,
  buildGetAccountBeneficiariesSection,
  buildCreateAccountTransactionSection,
  buildGetDomesticPaymentConsentSection,
  buildCreateDomesticPaymentConsentSection,
  buildCreateCounterpartyTransactionSection,
  buildGetAccountsSection,
  buildGetAccountBalancesSection,
  buildGetFundsConfirmationSection,
  buildCreateDomesticPaymentSection,
} from './apis-sdks-util';
import { ReactGa } from '../components/ReactGa';
import { logEvent } from '../components/utils/analytics';
import styled from 'styled-components';

const SANDBOX_API_BASE_URL =
  process.env.REACT_APP_SANDBOX_API_BASE_URL ||
  'https://nonprod-preprod.openatb.com';

const PROD_API_BASE_URL =
  process.env.REACT_APP_PROD_API_BASE_URL || 'https://prod.openatb.com';

const FeedbackButton = styled.button`
  margin-left: 10px;
`;

const OpenBankingAPI = () => {
  const [environment, setEnvironment] = useState('sandbox');

  const refs: { [key: string]: any } = {};

  const changeEnvironment = (event: any) => {
    setEnvironment(event.target.value);
  };
  const buildFeedbackSection = (category: string, label: string) => {
    const divLabel = `${category}_${label}_feedback`;
    refs[divLabel] = React.createRef();
    return (
      <div className="feedback">
        <div className="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Relative-sc-16pg6n7-3 gZdeAQ">
          <div className="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Absolute-sc-16pg6n7-2 frnnzh">
            <div
              ref={refs[divLabel]}
              className="Box-sc-1xuamqz-0 Text__TextBox-ezbrsv-1 dvMOMu"
            >
              Was this section helpful?
              <FeedbackButton
                type="button"
                className="link-button highlighted-link"
                onClick={(e) => {
                  logEvent({
                    prefix: 'sandbox',
                    category,
                    action: 'sandbox-doc-feedback-click',
                    label,
                    value: 1,
                  });
                  refs[divLabel].current.innerHTML =
                    'Thanks for your feedback!';
                }}
              >
                Yes
              </FeedbackButton>
              <FeedbackButton
                type="button"
                className="link-button highlighted-link"
                onClick={(e) => {
                  logEvent({
                    prefix: 'sandbox',
                    category,
                    action: 'sandbox-doc-feedback-click',
                    label,
                    value: 0,
                  });
                  refs[divLabel].current.innerHTML =
                    'Thanks for your feedback!';
                }}
              >
                No
              </FeedbackButton>
            </div>
          </div>
        </div>
      </div>
    );
  };

  const buildRequestTitle = (title: string, envSelector: boolean) => {
    return (
      <blockquote>
        <div>
          <p>{title}</p>
        </div>
        {envSelector && (
          <div className="env-selector">
            <select value={environment} onChange={changeEnvironment}>
              <option value="sandbox">Sandbox</option>
              <option value="production">Production</option>
            </select>
          </div>
        )}
      </blockquote>
    );
  };

  return (
    <ReactGa>
      <div className="content">
        <h2 id="open-banking-api-getting-started">
          <a href="#open-banking-api-getting-started" className="header-link">
            <i />
          </a>
          Getting started
        </h2>
        <h3 id="open-banking-api-getting-started-introduction">
          <a
            href="#open-banking-api-getting-started-introduction"
            className="header-link"
          >
            <i />
          </a>
          Introduction
        </h3>
        <p>
          Welcome to the Open ATB API Sandbox! We envision a world where systems
          enable innovation, security and experience for every customer; we’re
          excited to build that future with you.
        </p>
        <p>
          Here you will find all the information you need to get started
          building your application in our sandbox. For access to our SDK,
          review code samples in our{' '}
          <a href="https://github.com/openatb" target="_blank">
            Github Repository
          </a>
          .
        </p>
        <h3 id="open-banking-api-getting-started-usage-and-limits">
          <a
            href="#open-banking-api-getting-started-usage-and-limits"
            className="header-link"
          >
            <i />
          </a>
          Usage and Limits
        </h3>
        <p>
          In order to maximize safe and fair resource sharing for our users and
          partners, the following usage limits have been enabled:
        </p>
        <ul>
          <li>
            The sandbox APIs follow the UK Open Banking standard and PSD2 SCA
            regulations
          </li>
          <li>Maximum of 3 sandbox applications at once, per user</li>
          <li>Maximum of 60 API requests per minute, per application</li>
          <li>
            Expiration after 90 days, triggering an automated, explicit
            reauthorization for both: developers on the platform &amp; the
            future users of your application
          </li>
          <li>
            Progressive authorization required for live-environment access
          </li>
        </ul>
        {buildFeedbackSection('Getting started', 'Usage and limits')}
        <h3 id="open-banking-api-getting-started-demo-account">
          <a
            href="#open-banking-api-getting-started-demo-account"
            className="header-link"
          >
            <i />
          </a>
          Demo Account
        </h3>
        <p>
          This platform was built by developers, for developers. To make your
          onboarding as simple as possible, we’ve optimized the onboarding
          experience to enable application creation with a combination of
          public, live data and synthetic data, replicating the nuanced data
          attributes and functionalities required to develop your applications,
          without friction or compromise to data security.
        </p>
        <p>
          Leverage our demo account here:{' '}
          <a href="https://demo.openatb.com/" target="_blank">
            https://demo.openatb.com
          </a>
        </p>
        {buildFeedbackSection('Getting started', 'Demo account')}
        {/* <h2 id="open-banking-api-authentication">
        <a href="#open-banking-api-authentication" className="header-link">
          <i />
        </a>
        Authentication
      </h2>
      <h3 id="open-banking-api-authentication-setting-up-your-account">
        <a
          href="#open-banking-api-authentication-setting-up-your-account"
          className="header-link"
        >
          <i />
        </a>
        Setting Up Your Account
      </h3>
      <p>
        You will need to create an applicaton to get started with APIs. You can
        do this via the <Link to="/home/applications">profile page</Link> under
        registered applications.
      </p>
      <p>
        Access to our API Sandbox utilizes the OAuth 2.0 Client Authentication
        flow standard by leveraging JSON Web Token (JWT) to ensure security and
        limits are met, while allowing you full access to develop your custom
        applications.
      </p>
      <p>
        At the end of the authentication flow, you will receive a user access
        token. This is what you will have to use to perform any API call,
        leveraging public/private keys to sign your token requests in the
        sandbox.
      </p>
      <p>
        Ready to move your application into production? Reach out to us in our{' '}
        <a href="https://openatb.com/dev">Resources Page</a>.
      </p>
      <h4 id="open-banking-api-authentication-open-banking-api-authentication-setting-up-access-to-your-business-account-step-1-generate-a-pair-of-public-private-keys">
        <a
          href="#open-banking-api-authentication-open-banking-api-authentication-setting-up-access-to-your-business-account-step-1-generate-a-pair-of-public-private-keys"
          className="header-link"
        >
          <i />
        </a>
        Step 1: Generate a pair of public/private keys
      </h4>
      <blockquote>
        <div>
          <p>The basic steps to generate a private and public key</p>
        </div>
        <div className="env-selector">
          <select>
            <option value="sandbox">Sandbox</option>
            <option value="production">Production</option>
          </select>
        </div>
      </blockquote>
      <pre className="highlight shell tab-shell">
        <code>
          openssl genrsa -out privatekey.pem 1024{'\n'}openssl req -new -x509
          -key privatekey.pem -out publickey.cer -days 1825{'\n'}
        </code>
      </pre>
      <p>
        To get started with creating a public/private key-pair we recommend the
        use of <a href="https://www.openssl.org/">OpenSSL</a>. When you open
        your terminal and excute the basic steps on the right, you are prompted
        to fill in some fields. For the purposes of the sandbox you can enter a
        country code, a province, a city and then “.” to leave the rest blank.
        For production certificates, please fill in all the fields.
      </p>
      <p>
        <strong>Mac users </strong>
        OpenSSL comes shipped with Mac OS X version 10.6.2 onwards. You can use
        Terminal to run OpenSSL commands.
      </p>
      <aside className="warning">
        You might need admin access to run OpenSSL commands. If so, please add{' '}
        <code>sudo</code> before each command line.
      </aside>
      <p>
        <strong>Windows users </strong>
        Download OpenSSL for Windows
      </p>
      <p>
        To run the commands below, go to the OpenSSL32 directory on your PC, and
        change to the /bin directory.
      </p>
      <aside className="warning">
        You might need admin privileges (Run as administrator) to execute the
        commands. If OpenSSL has just been installed, you might need to restart
        your computer before it can generate certs.
      </aside>
      <h4 id="open-banking-api-authentication-open-banking-api-authentication-setting-up-access-to-your-business-account-step-2-upload-your-public-key">
        <a
          href="#open-banking-api-authentication-open-banking-api-authentication-setting-up-access-to-your-business-account-step-2-upload-your-public-key"
          className="header-link"
        >
          <i />
        </a>
        Step 2: Upload your public key
      </h4>
      <p>
        This is simple. Go to the directory in which you have generated the
        public keys and copy the text in the "publickey.cer" certificate file.
        Head over to your Open ATB account{' '}
        <a href="https://api.openatb.com/settings/api">API settings</a>, click
        on "Add certificate", then paste your public key in the "X509 public
        key" input.
      </p>
      <p>
        In order to complete the setup, you will also need to provide a OAuth
        redirect URI. The redirect URI is a url to which you will be redirected
        after the authorization step. For sandbox you could use{' '}
        <a href="https://demo.openatb.com/">our JWT test application</a> as a
        redirect URI, as it will simplify the next steps for you.
      </p>
      <h4 id="open-banking-api-authentication-open-banking-api-authentication-setting-up-access-to-your-business-account-step-3-sign-a-jwt">
        <a
          href="#open-banking-api-authentication-open-banking-api-authentication-setting-up-access-to-your-business-account-step-3-sign-a-jwt"
          className="header-link"
        >
          <i />
        </a>
        Step 3: Sign a JWT
      </h4>
      <blockquote>
        <div>
          <p>Generating a JWT:</p>
        </div>
        <div className="env-selector">
          <select>
            <option value="sandbox">Sandbox</option>
            <option value="production">Production</option>
          </select>
        </div>
      </blockquote>
      <pre className="highlight shell tab-shell">
        <code>
          RSASHA256<span className="o">(</span>
          {'\n'}base64UrlEncode<span className="o">(</span>header
          <span className="o">)</span> + <span className="s2">"."</span> +{'\n'}
          base64UrlEncode<span className="o">(</span>payload
          <span className="o">)</span>,{'\n'}Signature{'\n'}
          <span className="o">)</span>
          {'\n'}
        </code>
      </pre>
      <blockquote>
        <div>
          <p>JWT generating example:</p>
        </div>
        <div className="env-selector">
          <select>
            <option value="sandbox">Sandbox</option>
            <option value="production">Production</option>
          </select>
        </div>
      </blockquote>
      <pre className="highlight javascript">
        <code>
          <span className="kr">const</span> <span className="nx">fs</span>{' '}
          <span className="o">=</span> <span className="nx">require</span>
          <span className="p">(</span>
          <span className="s1">'fs'</span>
          <span className="p">)</span>
          {'\n'}
          <span className="kr">const</span> <span className="nx">jwt</span>{' '}
          <span className="o">=</span> <span className="nx">require</span>
          <span className="p">(</span>
          <span className="s1">'jsonwebtoken'</span>
          <span className="p">)</span>
          {'\n'}
          <span className="err">​</span>
          {'\n'}
          <span className="kr">const</span>{' '}
          <span className="nx">privateKeyName</span>{' '}
          <span className="o">=</span>{' '}
          <span className="s1">'privatekey.pem'</span>{' '}
          <span className="c1">// Should be valid path to the private key</span>
          {'\n'}
          <span className="kr">
            const
          </span> <span className="nx">issuer</span>{' '}
          <span className="o">=</span> <span className="s1">'127.0.0.1'</span>{' '}
          <span className="c1">
            // Issuer for JWT, should be derived from your redirect URL
          </span>
          {'\n'}
          <span className="kr">const</span>{' '}
          <span className="nx">client_id</span> <span className="o">=</span>{' '}
          <span className="s1">'&lt;CLIENT_ID&gt;'</span>{' '}
          <span className="c1">// Your client ID</span>
          {'\n'}
          <span className="kr">const</span> <span className="nx">aud</span>{' '}
          <span className="o">=</span>{' '}
          <span className="s1">'https://api.openatb.com'</span>{' '}
          <span className="c1">// Constant</span>
          {'\n'}
          <span className="kr">
            const
          </span> <span className="nx">payload</span>{' '}
          <span className="o">=</span> <span className="p">{'{'}</span>
          {'\n'}
          <span className="s2">"iss"</span>
          <span className="p">:</span> <span className="nx">issuer</span>
          <span className="p">,</span>
          {'\n'}
          <span className="s2">"sub"</span>
          <span className="p">:</span> <span className="nx">client_id</span>
          <span className="p">,</span>
          {'\n'}
          <span className="s2">"aud"</span>
          <span className="p">:</span> <span className="nx">aud</span>
          {'\n'}
          <span className="p">{'}'}</span>
          {'\n'}
          <span className="kr">const</span>{' '}
          <span className="nx">privateKey</span> <span className="o">=</span>{' '}
          <span className="nx">fs</span>
          <span className="p">.</span>
          <span className="nx">readFileSync</span>
          <span className="p">(</span>
          <span className="nx">privateKeyName</span>
          <span className="p">);</span>
          {'\n'}
          <span className="kr">
            const
          </span> <span className="nx">token</span> <span className="o">=</span>{' '}
          <span className="nx">jwt</span>
          <span className="p">.</span>
          <span className="nx">sign</span>
          <span className="p">(</span>
          <span className="nx">payload</span>
          <span className="p">,</span> <span className="nx">privateKey</span>
          <span className="p">,</span> <span className="p">{'{'}</span>{' '}
          <span className="na">algorithm</span>
          <span className="p">:</span> <span className="s1">'RS256'</span>
          <span className="p">,</span> <span className="na">expiresIn</span>
          <span className="p">:</span> <span className="mi">60</span>{' '}
          <span className="o">*</span> <span className="mi">60</span>
          <span className="p">{'}'});</span>
          {'\n'}
        </code>
      </pre>
      <p>
        In order to perform requests to the API, you will need to provide your
        client_id and a JSON Web Token. At the end of the previous step, you
        will be assigned a client_id.
      </p>
      <p>
        In brief, a JWT is an encrypted JSON object made up of the following
        components:
      </p>
      <p>
        Header - base64 from headers describing the signature algorithm and the
        token type
      </p>
      <table>
        <thead>
          <tr>
            <th>Field</th>
            <th>Description</th>
            <th>Format</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>alg</td>
            <td>"RS256"</td>
            <td>Text</td>
          </tr>
          <tr>
            <td>typ</td>
            <td>"JWT"</td>
            <td>Text</td>
          </tr>
        </tbody>
      </table>
      <p>Payload - base64-encoded content</p>
      <table>
        <thead>
          <tr>
            <th>Field</th>
            <th>Description</th>
            <th>Format</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>iss</td>
            <td>Domain from redirect_url (without http://)</td>
            <td>Text</td>
          </tr>
          <tr>
            <td>sub</td>
            <td>Your cliend_id</td>
            <td>Text</td>
          </tr>
          <tr>
            <td>aud</td>
            <td>https://api.openatb.com</td>
            <td>Text</td>
          </tr>
          <tr>
            <td>exp</td>
            <td>timestamp - JWT expiration date</td>
            <td>UNIX timestamp in seconds</td>
          </tr>
        </tbody>
      </table>
      <p>Signature - base64-encoded private key</p>
      <p>
        To test the validity of your JWT, you can use an online tool such as{' '}
        <a href="https://jwt.io/">jwt.io</a>.
      </p>
      <h4 id="open-banking-api-authentication-open-banking-api-authentication-setting-up-access-to-your-business-account-step-4-authorise-your-app">
        <a
          href="#open-banking-api-authentication-open-banking-api-authentication-setting-up-access-to-your-business-account-step-4-authorise-your-app"
          className="header-link"
        >
          <i />
        </a>
        Step 4: Authorise your app
      </h4>
      <p>
        The next step is to authorise the API to access your account. In order
        to do so, in the web interface, click on the "Authorise API access"
        button. You wil see an authorization popup with a summary of the
        resources the API will be able to access.
      </p>
      <p>
        Once you click on authorise, you will be redirected to your specified
        OAuth redirect link. The redirect url will have attached an
        authorization code.
      </p>
      <h4 id="open-banking-api-authentication-open-banking-api-authentication-setting-up-access-to-your-business-account-step-5-request-a-reusable-access-token">
        <a
          href="#open-banking-api-authentication-open-banking-api-authentication-setting-up-access-to-your-business-account-step-5-request-a-reusable-access-token"
          className="header-link"
        >
          <i />
        </a>
        Step 5: Request a reusable access token
      </h4>
      <p>
        Once you have received the authorization code, you can exchange it for
        an access token.
      </p>
      <p>
        <a href="#open-banking-api-oauth-exchange-authorization-code">
          See more here about token exchange endpoint and its parameters
        </a>
      </p>
      <h4 id="open-banking-api-authentication-open-banking-api-authentication-setting-up-access-to-your-business-account-step-6-you-are-ready-to-go">
        <a
          href="#open-banking-api-authentication-open-banking-api-authentication-setting-up-access-to-your-business-account-step-6-you-are-ready-to-go"
          className="header-link"
        >
          <i />
        </a>
        Step 6: You are ready to go!
      </h4>
      <p>
        Once you have the access token, you can use any of the API endpoints
        described below (according to the permissions granted to your app),
        applying the access token everywhere you see the mention{' '}
        <code>&lt;your access token&gt;</code>. Please make sure you store
        access tokens somewhere secure.
      </p>
      <h4 id="open-banking-api-authentication-open-banking-api-authentication-setting-up-access-to-your-business-account-step-7-refreshing-the-access-token">
        <a
          href="#open-banking-api-authentication-open-banking-api-authentication-setting-up-access-to-your-business-account-step-7-refreshing-the-access-token"
          className="header-link"
        >
          <i />
        </a>
        Step 7: Refreshing the access token
      </h4>
      <p>
        After step 5, you will receive two tokens: an access token and a refresh
        token.
      </p>
      <p>
        Every access token is valid for 40 minutes, after which you can use the
        refresh_token to request a new one.
      </p>
      <aside className="warning">
        Please note: Businesses on the Freelancer plan: you can do this for 90
        days, after which you will need to repeat the authorization process
        (from Step 4), as required by the PSD2 regulations.
      </aside>
      <p>
        <a href="#open-banking-api-oauth-refresh-access-token">
          Check the corresponding endpoint documentation
        </a>
      </p>
      {buildFeedbackSection(
        'Authentication',
        'Setting up access to your Business account',
      )} */}
        <h2 id="open-banking-api-oauth">
          <a href="#open-banking-api-oauth" className="header-link">
            <i />
          </a>
          OAuth
        </h2>
        <h3 id="open-banking-api-oauth-get-authorization-code">
          <a
            href="#open-banking-api-oauth-get-authorization-code"
            className="header-link"
          >
            <i />
          </a>
          Get authorization code
        </h3>
        {buildRequestTitle('Example URL', false)}
        <pre
          className="highlight shell tab-shell--sandbox"
          style={{ display: 'block' }}
        >
          <code>
            curl {SANDBOX_API_BASE_URL}
            /oauth/v2/authorize?response_type=code&client_id=CLIENT_ID&nonce=RANDOM_VARIABLE&state=RANDOM_VARIABLE&response_type=code&scope=openid&redirect_uri=CALLBACK_URL
          </code>
        </pre>
        <pre
          className="highlight shell tab-shell--production"
          style={{ display: 'none' }}
        >
          <code>
            curl {PROD_API_BASE_URL}
            /oauth/v2/authorize?response_type=code&client_id=CLIENT_ID&nonce=RANDOM_VARIABLE&state=RANDOM_VARIABLE&response_type=code&scope=openid&redirect_uri=CALLBACK_URL
          </code>
        </pre>
        <p>
          If your application needs to access APIs that are consumer specific,
          use the Authorization Code Flow. To request an authorization code, you
          must direct the customers's browser to Open ATB's OAuth 2.0
          authorization page, where the customer either accepts or denies your
          application's permission request.
        </p>
        <h4 id="open-banking-api-oauth-open-banking-api-oauth-get-authorization-code-request">
          <a
            href="#open-banking-api-oauth-open-banking-api-oauth-get-authorization-code-request"
            className="header-link"
          >
            <i />
          </a>
          Request
        </h4>
        <p>
          <code>GET {SANDBOX_API_BASE_URL}/oauth/v2/authorize</code>
        </p>
        <h6 id="open-banking-api-oauth-open-banking-api-oauth-get-authorization-code-open-banking-api-oauth-open-banking-api-oauth-get-authorization-code-request-get-parameters">
          <a
            href="#open-banking-api-oauth-open-banking-api-oauth-get-authorization-code-open-banking-api-oauth-open-banking-api-oauth-get-authorization-code-request-get-parameters"
            className="header-link"
          >
            <i />
          </a>
        </h6>

        {buildFeedbackSection('OAuth', 'Get authorization code')}
        <h3 id="open-banking-api-oauth-exchange-authorization-code">
          <a
            href="#open-banking-api-oauth-exchange-authorization-code"
            className="header-link"
          >
            <i />
          </a>
          Exchange Authorization Code
        </h3>
        {buildRequestTitle('Exchange auth code to an access token', false)}
        <pre
          className="highlight shell tab-shell--sandbox"
          style={{ display: 'block' }}
        >
          <code>curl {SANDBOX_API_BASE_URL}/oauth/v2/authorize</code>
        </pre>
        <pre
          className="highlight shell tab-shell--production"
          style={{ display: 'none' }}
        >
          <code>
            curl -X POST {PROD_API_BASE_URL}
            /oauth/v2/accessToken?grant_type=authorization_code&code=AUTHORIZATION_CODE&redirect_uri=CALLBACK_URL&client_id=your_client_id&client_secret=your_client_secret
          </code>
        </pre>
        <blockquote>
          <div>
            <p>Response</p>
          </div>
        </blockquote>
        <pre className="highlight json tab-json">
          <code>
            <span className="p">{'{'}</span>
            <span className="w">{'\n'}</span>
            <span className="s2">"access_token"</span>
            <span className="p">:</span>
            <span className="w"> </span>
            <span className="s2">
              "oa_sand_rPo9OmbMAuguhQffR6RLR4nvmzpx4NJtpdyvGKkrS3U"
            </span>
            <span className="p">,</span>
            <span className="w">{'\n'}</span>
            <span className="s2">"token_type"</span>
            <span className="p">:</span>
            <span className="w"> </span>
            <span className="s2">"bearer"</span>
            <span className="p">,</span>
            <span className="w">{'\n'}</span>
            <span className="s2">"expires_in"</span>
            <span className="p">:</span>
            <span className="w"> </span>
            <span className="mi">2399</span>
            <span className="p">,</span>
            <span className="w">{'\n'}</span>
            <span className="s2">"refresh_token"</span>
            <span className="p">:</span>
            <span className="w"> </span>
            <span className="s2">
              "oa_prod_hQacSGnwx-luIfj3dlVByrytVV9rWAnyHkpJTwG_Tr8"
            </span>
            <span className="w">{'\n'}</span>
            <span className="p">{'}'}</span>
            <span className="w">{'\n'}</span>
          </code>
        </pre>
        <p>
          It is used to get an access token for your application using the
          authorization code.
        </p>
        <h4 id="open-banking-api-oauth-open-banking-api-exchange-authorization-code-request">
          <a
            href="#open-banking-api-oauth-open-banking-api-oauth-exchange-authorization-code-request"
            className="header-link"
          >
            <i />
          </a>
          Request
        </h4>
        <p>
          <code>
            POST {SANDBOX_API_BASE_URL}
            /oauth/v2/accessToken
          </code>
        </p>
        <h6 id="open-banking-api-oauth-open-banking-api-oauth-exchange-authorization-code-open-banking-api-oauth-open-banking-api-oauth-exchange-authorization-code-request-request-fields-url-encoded">
          <a
            href="#open-banking-api-oauth-open-banking-api-oauth-exchange-authorization-code-open-banking-api-oauth-open-banking-api-oauth-exchange-authorization-code-request-request-fields-url-encoded"
            className="header-link"
          >
            <i />
          </a>
        </h6>
        <h4 id="open-banking-api-oauth-open-banking-api-oauth-exchange-authorization-code-response">
          <a
            href="#open-banking-api-oauth-open-banking-api-oauth-exchange-authorization-code-response"
            className="header-link"
          >
            <i />
          </a>
          Response
        </h4>
        <table>
          <thead>
            <tr>
              <th>Field</th>
              <th>Description</th>
              <th>Format</th>
            </tr>
          </thead>
          <tbody>
            <tr>
              <td>access_token</td>
              <td>the access token</td>
              <td>Text</td>
            </tr>
            <tr>
              <td>token_type</td>
              <td>"bearer" means that this token is valid to access the API</td>
              <td>Text</td>
            </tr>
            <tr>
              <td>expires_in</td>
              <td>token expiration time in seconds</td>
              <td>Integer</td>
            </tr>
            <tr>
              <td>refresh_token</td>
              <td>A token to be used to request a new access token</td>
              <td>Text</td>
            </tr>
          </tbody>
        </table>
        {buildFeedbackSection('OAuth', 'Exchange Authorization Code')}
        <h3 id="open-banking-api-oauth-refresh-access-token">
          <a
            href="#open-banking-api-oauth-refresh-access-token"
            className="header-link"
          >
            <i />
          </a>
          Refresh Access Token
        </h3>

        <p>
          To protect customers' data, Open ATB does not generate long-lived
          access tokens. Make sure your application refreshes access tokens
          before they expire, to avoid unnecessarily sending your application's
          users through the authorization process again.
        </p>
        <p>
          Refreshing an access token is a seamless user experience. To refresh
          an access token, go through the authorization process again to fetch a
          new token. This time however, in the refresh workflow, the
          authorization screen is bypassed and the member is redirected to your
          callback URL, provided the following conditions are met:
        </p>
        <ul>
          <li>The customer is still logged into openatb.com</li>
          <li>The customer's current access token has not expired</li>
        </ul>

        {buildFeedbackSection('OAuth', 'Refresh Access Token')}
        <h2 id="open-banking-api-atms-and-branches">
          <a href="#open-banking-api-atms-and-branches" className="header-link">
            <i />
          </a>
          ATMs and Branches
        </h2>
        <p>You can retrieve detailed information about ATMs and branches</p>
        {buildGetATMsSection(environment, changeEnvironment)}
        {buildGetBranchesSection(environment, changeEnvironment)}
        <h2 id="open-banking-api-counterparties">
          <a href="#open-banking-api-counterparties" className="header-link">
            <i />
          </a>
          Counterparties
        </h2>
        <p>
          Before a transfer or payment can be made, you need to add the
          counterparties with which you intend to transact with.
        </p>
        <aside className="notice">
          In the sandbox environment, it is not possible to add "real" people
          and businesses as counterparties. So, to help you simulate "Add
          Counterparty" requests, we have created some test accounts.
        </aside>
        {buildCreateCounterpartySection(environment, changeEnvironment)}
        {buildCreateCounterpartyTransactionSection(
          environment,
          changeEnvironment,
        )}
        {buildGetCounterpartySection(environment, changeEnvironment)}
        {buildGetCounterpartiesSection(environment, changeEnvironment)}
        <h2 id="open-banking-api-exchanges">
          <a href="#open-banking-api-exchanges" className="header-link">
            <i />
          </a>
          Exchanges
        </h2>
        {buildGetExchangeRatesSection(environment, changeEnvironment)}
        {buildExchangeCurrenySection(environment, changeEnvironment)}
        <h2 id="open-banking-api-accounts-and-transactions">
          <a
            href="#open-banking-api-accounts-and-transactions"
            className="header-link"
          >
            <i />
          </a>
          Accounts and Transactions
        </h2>
        <p>
          <code>Accounts and Transactions</code> contains endpoints related to
          consents, accounts, balances and transactions.
        </p>
        <h3 id="open-banking-api-accounts-and-transactions-account-access-consent">
          <a
            href="#open-banking-api-accounts-and-transactions-account-access-consent"
            className="header-link"
          >
            <i />
          </a>
          Account Access Consent
        </h3>
        {buildCancelRevokeConsentSection(environment, changeEnvironment)}
        {buildGetConsentSection(environment, changeEnvironment)}
        {buildCreateConsentSection(environment, changeEnvironment)}
        {buildCreateAccountTransactionSection(environment, changeEnvironment)}
        {buildGetAccountsSection(environment, changeEnvironment)}
        {buildGetAccountDetailsSection(environment, changeEnvironment)}
        {buildGetAccountBalancesSection(environment, changeEnvironment)}
        {buildGetAccountTransactionsSection(environment, changeEnvironment)}
        {buildGetAccountBeneficiariesSection(environment, changeEnvironment)}
        {buildGetAccountDirectDebitsSection(environment, changeEnvironment)}
        <h2 id="open-banking-api-domestic-payments">
          <a href="#open-banking-api-domestic-payments" className="header-link">
            <i />
          </a>
          Domestic Payments
        </h2>
        {buildCreateDomesticPaymentConsentSection(
          environment,
          changeEnvironment,
        )}
        {buildGetDomesticPaymentConsentSection(environment, changeEnvironment)}
        {buildGetFundsConfirmationSection(environment, changeEnvironment)}
        {buildCreateDomesticPaymentSection(environment, changeEnvironment)}
        {buildGetDomesticPaymentSection(environment, changeEnvironment)}
      </div>
    </ReactGa>
  );
};

export default OpenBankingAPI;

// Removed from the documentation
const internationalPayments = `
<h3 id="open-banking-api-domestic-payments-create-international-payment-consent"><a href="#open-banking-api-domestic-payments-create-international-payment-consent" class="header-link"><i></i></a>Create International Payment Consent</h3>
<blockquote>
  <div>
    <p>Create International Payment Consent</p>
  </div>
  <div class="env-selector" style="display: block;">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight shell tab-shell--sandbox" style="display: none;"><code>curl -X POST https://sandbox-oba.revolut.com/international-payment-consents
</code></pre>
<pre class="highlight shell tab-shell--production" style="display: block;"><code>curl -X POST https://oba.revolut.com/international-payment-consents
</code></pre>
<blockquote>
  <div>
    <p>Request:</p>
  </div>
  <div class="env-selector">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight json tab-json"><code><span class="p">{</span><span class="w">
</span><span class="s2">"Data"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
  </span><span class="s2">"Initiation"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
    </span><span class="s2">"InstructionIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"ACME412"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"EndToEndIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"FRESCO.21302.GFX.20"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"InstructionPriority"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Normal"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"CurrencyOfTransfer"</span><span class="p">:</span><span class="w"> </span><span class="s2">"USD"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"InstructedAmount"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"Amount"</span><span class="p">:</span><span class="w"> </span><span class="s2">"165.88"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Currency"</span><span class="p">:</span><span class="w"> </span><span class="s2">"CAD"</span><span class="w">
    </span><span class="p">},</span><span class="w">
    </span><span class="s2">"CreditorAccount"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"SchemeName"</span><span class="p">:</span><span class="w"> </span><span class="s2">"UK.OBIE.IBAN"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Identification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"DE89370400440532013000"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Name"</span><span class="p">:</span><span class="w"> </span><span class="s2">"ACME Inc"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"SecondaryIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"0002"</span><span class="w">
    </span><span class="p">},</span><span class="w">
    </span><span class="s2">"DebtorAccount"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"SchemeName"</span><span class="p">:</span><span class="w"> </span><span class="s2">"UK.OBIE.IBAN"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Identification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"GB38REVO00996907112483"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Name"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Name"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"SecondaryIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"0002"</span><span class="w">
    </span><span class="p">},</span><span class="w">
    </span><span class="s2">"RemittanceInformation"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"Reference"</span><span class="p">:</span><span class="w"> </span><span class="s2">"FRESCO-101"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Unstructured"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Internal ops code 5120101"</span><span class="w">
    </span><span class="p">},</span><span class="w">
    </span><span class="s2">"ExchangeRateInformation"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"UnitCurrency"</span><span class="p">:</span><span class="w"> </span><span class="s2">"CAD"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"RateType"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Indicative"</span><span class="w">
    </span><span class="p">}</span><span class="w">
  </span><span class="p">}</span><span class="w">
</span><span class="p">},</span><span class="w">
</span><span class="s2">"Risk"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
  </span><span class="s2">"PaymentContextCode"</span><span class="p">:</span><span class="w"> </span><span class="s2">"EcommerceGoods"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"MerchantCategoryCode"</span><span class="p">:</span><span class="w"> </span><span class="s2">"5967"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"MerchantCustomerIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"053598653254"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"DeliveryAddress"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
    </span><span class="s2">"AddressLine"</span><span class="p">:</span><span class="w"> </span><span class="p">[</span><span class="s2">"Flat 7"</span><span class="p">,</span><span class="w"> </span><span class="s2">"Acacia Lodge"</span><span class="p">],</span><span class="w">
    </span><span class="s2">"StreetName"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Acacia Avenue"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"BuildingNumber"</span><span class="p">:</span><span class="w"> </span><span class="s2">"27"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"PostCode"</span><span class="p">:</span><span class="w"> </span><span class="s2">"GU31 2ZZ"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"TownName"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Sparsholt"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"Country"</span><span class="p">:</span><span class="w"> </span><span class="s2">"UK"</span><span class="w">
  </span><span class="p">}</span><span class="w">
</span><span class="p">}</span><span class="w">
</span><span class="p">}</span><span class="w">
</span></code></pre>
<p>This endpoint is for creating payment consent as described in Open Banking API documentation. International Payments are used for international SWIFT payments in all currencies supported by Revolut.</p>

<p>Only payments with <code>InstructedAmount</code> in the same currency as <code>CurrencyOfTransfer</code> are supported.</p>

<p>At the moment, currency exchange is not supported, which means that a PSU should have enough funds in a currency specified in the <code>CurrencyOfTransfer</code> field to execute a payment.</p>

<p>As part of API requirement, you must pass <code>x-jws-signature</code> header in your request. The signature must be generated from a request body with TPP signing key that is specified in the JWS header. As it specified in the documentation, you must only pass <code>head</code> and <code>signature</code> in <code>&lt;jws_head&gt;..&lt;jws_signature&gt;</code> format</p>

<aside class="notice">
  Unfortunately, cannot support the <b>EndToEndIdentification</b> field as it is described in the <a href="https://openbanking.atlassian.net/wiki/spaces/DZ/pages/937656404/Read+Write+Data+API+Specification+-+v3.1">documentation</a>. We will update the documentation once we get support for it.
</aside>

<div class="feedback">
  <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Relative-sc-16pg6n7-3 gZdeAQ">
    <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Absolute-sc-16pg6n7-2 frnnzh">
      <div class="Box-sc-1xuamqz-0 Text__TextBox-ezbrsv-1 dvMOMu" style="opacity: 1;">Was this section helpful?<button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">Yes</button><button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">No</button></div>
    </div>
  </div>
</div>
<h3 id="open-banking-api-domestic-payments-get-international-payment-consent"><a href="#open-banking-api-domestic-payments-get-international-payment-consent" class="header-link"><i></i></a>Get International Payment Consent</h3>
<blockquote>
  <div>
    <p>Get International Payment Consent details</p>
  </div>
  <div class="env-selector" style="display: block;">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight shell tab-shell--sandbox" style="display: none;"><code>curl -X GET https://sandbox-oba.revolut.com/international-payment-consents/<span class="o">{</span>conset_id<span class="o">}</span>
</code></pre>
<pre class="highlight shell tab-shell--production" style="display: block;"><code>curl -X GET https://oba.revolut.com/international-payment-consents/<span class="o">{</span>consent_id<span class="o">}</span>
</code></pre>
<blockquote>
  <div>
    <p>Response</p>
  </div>
  <div class="env-selector">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight json tab-json"><code><span class="p">{</span><span class="w">
</span><span class="s2">"Data"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
  </span><span class="s2">"ConsentId"</span><span class="p">:</span><span class="w"> </span><span class="s2">"b4206fed-5265-48ce-91af-cb7182018991"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"Status"</span><span class="p">:</span><span class="w"> </span><span class="s2">"AwaitingAuthorization"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"CreationDateTime"</span><span class="p">:</span><span class="w"> </span><span class="s2">"2019-07-06T16:17:22+00:00"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"StatusUpdateDateTime"</span><span class="p">:</span><span class="w"> </span><span class="s2">"2019-07-07T13:25:22+00:00"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"Initiation"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
    </span><span class="s2">"InstructionIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"ACME412"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"EndToEndIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"FRESCO.21302.GFX.20"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"InstructionPriority"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Normal"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"CurrencyOfTransfer"</span><span class="p">:</span><span class="w"> </span><span class="s2">"USD"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"InstructedAmount"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"Amount"</span><span class="p">:</span><span class="w"> </span><span class="s2">"165.88"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Currency"</span><span class="p">:</span><span class="w"> </span><span class="s2">"CAD"</span><span class="w">
    </span><span class="p">},</span><span class="w">
    </span><span class="s2">"CreditorAccount"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"SchemeName"</span><span class="p">:</span><span class="w"> </span><span class="s2">"UK.OBIE.IBAN"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Identification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"DE89370400440532013000"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Name"</span><span class="p">:</span><span class="w"> </span><span class="s2">"ACME Inc"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"SecondaryIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"0002"</span><span class="w">
    </span><span class="p">},</span><span class="w">
    </span><span class="s2">"DebtorAccount"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"SchemeName"</span><span class="p">:</span><span class="w"> </span><span class="s2">"UK.OBIE.IBAN"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Identification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"GB38REVO00996907112483"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Name"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Revolut LTD"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"SecondaryIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"0002"</span><span class="w">
    </span><span class="p">},</span><span class="w">
    </span><span class="s2">"RemittanceInformation"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"Reference"</span><span class="p">:</span><span class="w"> </span><span class="s2">"FRESCO-101"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Unstructured"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Internal ops code 5120101"</span><span class="w">
    </span><span class="p">},</span><span class="w">
    </span><span class="s2">"ExchangeRateInformation"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"UnitCurrency"</span><span class="p">:</span><span class="w"> </span><span class="s2">"CAD"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"RateType"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Indicative"</span><span class="w">
    </span><span class="p">}</span><span class="w">
  </span><span class="p">},</span><span class="w">
  </span><span class="s2">"ExchangeRateInformation"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
    </span><span class="s2">"UnitCurrency"</span><span class="p">:</span><span class="w"> </span><span class="s2">"CAD"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"ExchangeRate"</span><span class="p">:</span><span class="w"> </span><span class="s2">"1.6050"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"RateType"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Indicative"</span><span class="w">
  </span><span class="p">}</span><span class="w">
</span><span class="p">},</span><span class="w">
</span><span class="s2">"Risk"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
  </span><span class="s2">"PaymentContextCode"</span><span class="p">:</span><span class="w"> </span><span class="s2">"EcommerceGoods"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"MerchantCategoryCode"</span><span class="p">:</span><span class="w"> </span><span class="s2">"5967"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"MerchantCustomerIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"053598653254"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"DeliveryAddress"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
    </span><span class="s2">"AddressLine"</span><span class="p">:</span><span class="w"> </span><span class="p">[</span><span class="s2">"Flat 7"</span><span class="p">,</span><span class="w"> </span><span class="s2">"Acacia Lodge"</span><span class="p">],</span><span class="w">
    </span><span class="s2">"StreetName"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Acacia Avenue"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"BuildingNumber"</span><span class="p">:</span><span class="w"> </span><span class="s2">"27"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"PostCode"</span><span class="p">:</span><span class="w"> </span><span class="s2">"GU31 2ZZ"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"TownName"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Sparsholt"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"Country"</span><span class="p">:</span><span class="w"> </span><span class="s2">"UK"</span><span class="w">
  </span><span class="p">}</span><span class="w">
</span><span class="p">},</span><span class="w">
</span><span class="s2">"Links"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
  </span><span class="s2">"Self"</span><span class="p">:</span><span class="w"> </span><span class="s2">"http://localhost:33453/international-payment-consents/b4206fed-5265-48ce-91af-cb7182018991"</span><span class="w">
</span><span class="p">},</span><span class="w">
</span><span class="s2">"Meta"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w"> </span><span class="s2">"TotalPages"</span><span class="p">:</span><span class="w"> </span><span class="mi">1</span><span class="w"> </span><span class="p">}</span><span class="w">
</span><span class="p">}</span><span class="w">
</span></code></pre>
<p>This endpoint returns consent details as it is described in the official documentation.</p>

<div class="feedback">
  <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Relative-sc-16pg6n7-3 gZdeAQ">
    <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Absolute-sc-16pg6n7-2 frnnzh">
      <div class="Box-sc-1xuamqz-0 Text__TextBox-ezbrsv-1 dvMOMu" style="opacity: 1;">Was this section helpful?<button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">Yes</button><button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">No</button></div>
    </div>
  </div>
</div>
<h3 id="open-banking-api-domestic-payments-get-international-payment-consent-funds-confirmation"><a href="#open-banking-api-domestic-payments-get-international-payment-consent-funds-confirmation" class="header-link"><i></i></a>Get International Payment Consent Funds Confirmation</h3>
<blockquote>
  <div>
    <p>Get Funds Confirmation</p>
  </div>
  <div class="env-selector" style="display: block;">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight shell tab-shell--sandbox" style="display: none;"><code>curl -X GET https://sandbox-oba.revolut.com/international-payment-consents/<span class="o">{</span>conset_id<span class="o">}</span>/funds-confirmation
</code></pre>
<pre class="highlight shell tab-shell--production" style="display: block;"><code>curl -X GET https://oba.revolut.com/international-payment-consents/<span class="o">{</span>consent_id<span class="o">}</span>/funds-confirmation
</code></pre>
<blockquote>
  <div>
    <p>Response</p>
  </div>
  <div class="env-selector">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight json tab-json"><code><span class="p">{</span><span class="w">
</span><span class="s2">"Data"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
  </span><span class="s2">"FundsAvailableResult"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
    </span><span class="s2">"FundsAvailableDateTime"</span><span class="p">:</span><span class="w"> </span><span class="s2">"2019-07-06T16:16:22+00:00"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"FundsAvailable"</span><span class="p">:</span><span class="w"> </span><span class="kc">true</span><span class="w">
  </span><span class="p">}</span><span class="w">
</span><span class="p">},</span><span class="w">
</span><span class="s2">"Links"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
  </span><span class="s2">"Self"</span><span class="p">:</span><span class="w"> </span><span class="s2">"https://oba.revolut.com/international-payment-consents/a0f338f1-1827-49a3-82f7-eaa3783cadb6/funds-confirmation"</span><span class="w">
</span><span class="p">},</span><span class="w">
</span><span class="s2">"Meta"</span><span class="p">:</span><span class="w"> </span><span class="p">{}</span><span class="w">
</span><span class="p">}</span><span class="w">
</span></code></pre>
<p>This endpoint allows checking funds for the given consent. This endpoint returns successfully only if user has authorized the related consent.</p>

<div class="feedback">
  <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Relative-sc-16pg6n7-3 gZdeAQ">
    <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Absolute-sc-16pg6n7-2 frnnzh">
      <div class="Box-sc-1xuamqz-0 Text__TextBox-ezbrsv-1 dvMOMu" style="opacity: 1;">Was this section helpful?<button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">Yes</button><button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">No</button></div>
    </div>
  </div>
</div>
<h3 id="open-banking-api-domestic-payments-create-international-payment"><a href="#open-banking-api-domestic-payments-create-international-payment" class="header-link"><i></i></a>Create International Payment</h3>
<blockquote>
  <div>
    <p>Create International Payment</p>
  </div>
  <div class="env-selector" style="display: block;">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight shell tab-shell--sandbox" style="display: none;"><code>curl -X POST https://sandbox-oba.revolut.com/international-payments
</code></pre>
<pre class="highlight shell tab-shell--production" style="display: block;"><code>curl -X POST https://oba.revolut.com/international-payments
</code></pre>
<blockquote>
  <div>
    <p>Request:</p>
  </div>
  <div class="env-selector">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight json tab-json"><code><span class="p">{</span><span class="w">
</span><span class="s2">"Data"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
  </span><span class="s2">"ConsentId"</span><span class="p">:</span><span class="w"> </span><span class="s2">"14083aa6-3f22-495d-a9e7-75abd118d8e9"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"Initiation"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
    </span><span class="s2">"InstructionIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"ACME412"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"EndToEndIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"FRESCO.21302.GFX.20"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"InstructionPriority"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Normal"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"CurrencyOfTransfer"</span><span class="p">:</span><span class="w"> </span><span class="s2">"USD"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"InstructedAmount"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"Amount"</span><span class="p">:</span><span class="w"> </span><span class="s2">"165.88"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Currency"</span><span class="p">:</span><span class="w"> </span><span class="s2">"CAD"</span><span class="w">
    </span><span class="p">},</span><span class="w">
    </span><span class="s2">"CreditorAccount"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"SchemeName"</span><span class="p">:</span><span class="w"> </span><span class="s2">"UK.OBIE.IBAN"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Identification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"DE89370400440532013000"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Name"</span><span class="p">:</span><span class="w"> </span><span class="s2">"ACME Inc"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"SecondaryIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"0002"</span><span class="w">
    </span><span class="p">},</span><span class="w">
    </span><span class="s2">"DebtorAccount"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"SchemeName"</span><span class="p">:</span><span class="w"> </span><span class="s2">"UK.OBIE.IBAN"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Identification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"GB38REVO00996907112483"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Name"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Revolut LTD"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"SecondaryIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"0002"</span><span class="w">
    </span><span class="p">},</span><span class="w">
    </span><span class="s2">"RemittanceInformation"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"Reference"</span><span class="p">:</span><span class="w"> </span><span class="s2">"FRESCO-101"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Unstructured"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Internal ops code 5120101"</span><span class="w">
    </span><span class="p">},</span><span class="w">
    </span><span class="s2">"ExchangeRateInformation"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"UnitCurrency"</span><span class="p">:</span><span class="w"> </span><span class="s2">"CAD"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"RateType"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Indicative"</span><span class="w">
    </span><span class="p">}</span><span class="w">
  </span><span class="p">}</span><span class="w">
</span><span class="p">},</span><span class="w">
</span><span class="s2">"Risk"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
  </span><span class="s2">"PaymentContextCode"</span><span class="p">:</span><span class="w"> </span><span class="s2">"EcommerceGoods"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"MerchantCategoryCode"</span><span class="p">:</span><span class="w"> </span><span class="s2">"5967"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"MerchantCustomerIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"1238808123123"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"DeliveryAddress"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
    </span><span class="s2">"AddressLine"</span><span class="p">:</span><span class="w"> </span><span class="p">[</span><span class="s2">"7"</span><span class="p">],</span><span class="w">
    </span><span class="s2">"StreetName"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Apple Street"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"BuildingNumber"</span><span class="p">:</span><span class="w"> </span><span class="s2">"1"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"PostCode"</span><span class="p">:</span><span class="w"> </span><span class="s2">"E2 7AA"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"TownName"</span><span class="p">:</span><span class="w"> </span><span class="s2">"London"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"Country"</span><span class="p">:</span><span class="w"> </span><span class="s2">"UK"</span><span class="w">
  </span><span class="p">}</span><span class="w">
</span><span class="p">}</span><span class="w">
</span><span class="p">}</span><span class="w">
</span></code></pre>
<p>As it is defined in the Open Banking Specifications, <code>/Data/Initiation</code> and <code>/Data/Risk</code> sections of the request must be exact match for the related consent passed in <code>ConsentId</code>.</p>

<div class="feedback">
  <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Relative-sc-16pg6n7-3 gZdeAQ">
    <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Absolute-sc-16pg6n7-2 frnnzh">
      <div class="Box-sc-1xuamqz-0 Text__TextBox-ezbrsv-1 dvMOMu" style="opacity: 1;">Was this section helpful?<button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">Yes</button><button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">No</button></div>
    </div>
  </div>
</div>
<h3 id="open-banking-api-domestic-payments-get-international-payment"><a href="#open-banking-api-domestic-payments-get-international-payment" class="header-link"><i></i></a>Get International Payment</h3>
<blockquote>
  <div>
    <p>Get International Payment details</p>
  </div>
  <div class="env-selector" style="display: block;">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight shell tab-shell--sandbox" style="display: none;"><code>curl -X GET https://sandbox-oba.revolut.com/international-payments/<span class="o">{</span>payment_id<span class="o">}</span>
</code></pre>
<pre class="highlight shell tab-shell--production" style="display: block;"><code>curl -X GET https://oba.revolut.com/international-payments/<span class="o">{</span>payment_id<span class="o">}</span>
</code></pre>
<blockquote>
  <div>
    <p>Response</p>
  </div>
  <div class="env-selector">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight json tab-json"><code><span class="p">{</span><span class="w">
</span><span class="s2">"Data"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
  </span><span class="s2">"InternationalPaymentId"</span><span class="p">:</span><span class="w"> </span><span class="s2">"a51bafa2-1b9f-466e-ae30-168672f0661c"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"Status"</span><span class="p">:</span><span class="w"> </span><span class="s2">"AcceptedSettlementInProcess"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"CreationDateTime"</span><span class="p">:</span><span class="w"> </span><span class="s2">"2017-06-05T15:15:22+00:00"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"StatusUpdateDateTime"</span><span class="p">:</span><span class="w"> </span><span class="s2">"2017-06-05T15:15:13+00:00"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"ConsentId"</span><span class="p">:</span><span class="w"> </span><span class="s2">"14083aa6-3f22-495d-a9e7-75abd118d8e9"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"Initiation"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
    </span><span class="s2">"InstructionIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"ACME412"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"EndToEndIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"FRESCO.21302.GFX.20"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"InstructionPriority"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Normal"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"CurrencyOfTransfer"</span><span class="p">:</span><span class="w"> </span><span class="s2">"USD"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"InstructedAmount"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"Amount"</span><span class="p">:</span><span class="w"> </span><span class="s2">"165.88"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Currency"</span><span class="p">:</span><span class="w"> </span><span class="s2">"CAD"</span><span class="w">
    </span><span class="p">},</span><span class="w">
    </span><span class="s2">"CreditorAccount"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"SchemeName"</span><span class="p">:</span><span class="w"> </span><span class="s2">"UK.OBIE.IBAN"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Identification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"DE89370400440532013000"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Name"</span><span class="p">:</span><span class="w"> </span><span class="s2">"ACME Inc"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"SecondaryIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"0002"</span><span class="w">
    </span><span class="p">},</span><span class="w">
    </span><span class="s2">"DebtorAccount"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"SchemeName"</span><span class="p">:</span><span class="w"> </span><span class="s2">"UK.OBIE.IBAN"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Identification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"GB38REVO00996907112483"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Name"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Revolut LTD"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"SecondaryIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"0002"</span><span class="w">
    </span><span class="p">},</span><span class="w">
    </span><span class="s2">"RemittanceInformation"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"Reference"</span><span class="p">:</span><span class="w"> </span><span class="s2">"FRESCO-101"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"Unstructured"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Internal ops code 5120101"</span><span class="w">
    </span><span class="p">},</span><span class="w">
    </span><span class="s2">"ExchangeRateInformation"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
      </span><span class="s2">"UnitCurrency"</span><span class="p">:</span><span class="w"> </span><span class="s2">"CAD"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"RateType"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Indicative"</span><span class="w">
    </span><span class="p">}</span><span class="w">
  </span><span class="p">}</span><span class="w">
</span><span class="p">},</span><span class="w">
</span><span class="s2">"Risk"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
  </span><span class="s2">"PaymentContextCode"</span><span class="p">:</span><span class="w"> </span><span class="s2">"EcommerceGoods"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"MerchantCategoryCode"</span><span class="p">:</span><span class="w"> </span><span class="s2">"5967"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"MerchantCustomerIdentification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"1238808123123"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"DeliveryAddress"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
    </span><span class="s2">"AddressLine"</span><span class="p">:</span><span class="w"> </span><span class="p">[</span><span class="s2">"7"</span><span class="p">],</span><span class="w">
    </span><span class="s2">"StreetName"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Apple Street"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"BuildingNumber"</span><span class="p">:</span><span class="w"> </span><span class="s2">"1"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"PostCode"</span><span class="p">:</span><span class="w"> </span><span class="s2">"E2 7AA"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"TownName"</span><span class="p">:</span><span class="w"> </span><span class="s2">"London"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"Country"</span><span class="p">:</span><span class="w"> </span><span class="s2">"UK"</span><span class="w">
  </span><span class="p">}</span><span class="w">
</span><span class="p">}</span><span class="w">
</span><span class="p">}</span><span class="w">
</span></code></pre>
<p>This endpoint returns a state of a payment</p>

<div class="feedback">
  <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Relative-sc-16pg6n7-3 gZdeAQ">
    <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Absolute-sc-16pg6n7-2 frnnzh">
      <div class="Box-sc-1xuamqz-0 Text__TextBox-ezbrsv-1 dvMOMu" style="opacity: 1;">Was this section helpful?<button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">Yes</button><button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">No</button></div>
    </div>
  </div>
</div>
<h2 id="open-banking-api-confirmation-of-funds"><a href="#open-banking-api-confirmation-of-funds" class="header-link"><i></i></a>Confirmation of funds</h2>
<p>Open Banking <code>Funds Confirmation API</code> is implemented as specified in the official <a href="https://openbanking.atlassian.net/wiki/spaces/DZ/pages/937951380/Confirmation+of+Funds+API+Specification+-+v3.1">documentation</a></p>
<h3 id="open-banking-api-confirmation-of-funds-funds-confirmation-consent"><a href="#open-banking-api-confirmation-of-funds-funds-confirmation-consent" class="header-link"><i></i></a>Funds Confirmation Consent</h3>
<blockquote>
  <div>
    <p>Create Funds Confirmation Consent</p>
  </div>
  <div class="env-selector" style="display: block;">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight shell tab-shell--sandbox" style="display: none;"><code>curl -X POST https://sandbox-oba.revolut.com/funds-confirmation-consents
</code></pre>
<pre class="highlight shell tab-shell--production" style="display: block;"><code>curl -X POST https://oba.revolut.com/funds-confirmation-consents
</code></pre>
<blockquote>
  <div>
    <p>Request:</p>
  </div>
  <div class="env-selector">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight json tab-json"><code><span class="p">{</span><span class="w">
</span><span class="s2">"Data"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
  </span><span class="s2">"DebtorAccount"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
    </span><span class="s2">"SchemeName"</span><span class="p">:</span><span class="w"> </span><span class="s2">"UK.OBIE.SortCodeAccountNumber"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"Identification"</span><span class="p">:</span><span class="w"> </span><span class="s2">"00000069994595"</span><span class="w">
  </span><span class="p">}</span><span class="w">
</span><span class="p">}</span><span class="w">
</span><span class="p">}</span><span class="w">
</span></code></pre>
<p>This API allows the CBPII to create a new funds-confirmation-consent resource, implemented as described in Open Banking API documentation.</p>

<div class="feedback">
  <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Relative-sc-16pg6n7-3 gZdeAQ">
    <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Absolute-sc-16pg6n7-2 frnnzh">
      <div class="Box-sc-1xuamqz-0 Text__TextBox-ezbrsv-1 dvMOMu" style="opacity: 1;">Was this section helpful?<button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">Yes</button><button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">No</button></div>
    </div>
  </div>
</div>
<h3 id="open-banking-api-confirmation-of-funds-create-funds-confirmations"><a href="#open-banking-api-confirmation-of-funds-create-funds-confirmations" class="header-link"><i></i></a>Create Funds Confirmations</h3>
<blockquote>
  <div>
    <p>Create Funds Confirmation</p>
  </div>
  <div class="env-selector" style="display: block;">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight shell tab-shell--sandbox" style="display: none;"><code>curl -X POST https://sandbox-oba.revolut.com/funds-confirmations
</code></pre>
<pre class="highlight shell tab-shell--production" style="display: block;"><code>curl -X POST https://oba.revolut.com/funds-confirmations
</code></pre>
<blockquote>
  <div>
    <p>Request:</p>
  </div>
  <div class="env-selector">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight json tab-json"><code><span class="p">{</span><span class="w">
</span><span class="s2">"Data"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
  </span><span class="s2">"ConsentId"</span><span class="p">:</span><span class="w"> </span><span class="s2">"123456789"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"Reference"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Purchase"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"InstructedAmount"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
    </span><span class="s2">"Amount"</span><span class="p">:</span><span class="w"> </span><span class="s2">"20.00"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"Currency"</span><span class="p">:</span><span class="w"> </span><span class="s2">"CAD"</span><span class="w">
  </span><span class="p">}</span><span class="w">
</span><span class="p">}</span><span class="w">
</span><span class="p">}</span><span class="w">
</span></code></pre>
<p>This API allows the CBPII to create a new funds-confirmation resource, and check the funds available flag in the response.</p>

<div class="feedback">
  <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Relative-sc-16pg6n7-3 gZdeAQ">
    <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Absolute-sc-16pg6n7-2 frnnzh">
      <div class="Box-sc-1xuamqz-0 Text__TextBox-ezbrsv-1 dvMOMu" style="opacity: 1;">Was this section helpful?<button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">Yes</button><button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">No</button></div>
    </div>
  </div>
</div>
<h3 id="open-banking-api-confirmation-of-funds-get-funds-confirmation"><a href="#open-banking-api-confirmation-of-funds-get-funds-confirmation" class="header-link"><i></i></a>Get Funds Confirmation</h3>
<blockquote>
  <div>
    <p>Get Funds Confirmation details</p>
  </div>
  <div class="env-selector" style="display: block;">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight shell tab-shell--sandbox" style="display: none;"><code>curl https://sandbox-oba.revolut.com/funds-confirmations/<span class="o">{</span>funds-confirmation-id<span class="o">}</span>
</code></pre>
<pre class="highlight shell tab-shell--production" style="display: block;"><code>curl https://oba.revolut.com/funds-confirmations/<span class="o">{</span>funds-confirmation-id<span class="o">}</span>
</code></pre>
<blockquote>
  <div>
    <p>Response</p>
  </div>
  <div class="env-selector">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight json tab-json"><code><span class="p">{</span><span class="w">
</span><span class="s2">"Data"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
  </span><span class="s2">"FundsConfirmationId"</span><span class="p">:</span><span class="w"> </span><span class="s2">"14083aa6-3f22-495d-a9e7-75abd118d8e9"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"ConsentId"</span><span class="p">:</span><span class="w"> </span><span class="s2">"123456789"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"CreationDateTime"</span><span class="p">:</span><span class="w"> </span><span class="s2">"2017-06-05T15:15:22+00:00"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"FundsAvailable"</span><span class="p">:</span><span class="w"> </span><span class="kc">true</span><span class="p">,</span><span class="w">
  </span><span class="s2">"Reference"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Purchase01"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"InstructedAmount"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
    </span><span class="s2">"Amount"</span><span class="p">:</span><span class="w"> </span><span class="s2">"20.00"</span><span class="p">,</span><span class="w">
    </span><span class="s2">"Currency"</span><span class="p">:</span><span class="w"> </span><span class="s2">"CAD"</span><span class="w">
  </span><span class="p">}</span><span class="w">
</span><span class="p">},</span><span class="w">
</span><span class="s2">"Links"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
  </span><span class="s2">"Self"</span><span class="p">:</span><span class="w"> </span><span class="s2">"https://atbfinancial-nonprod-dev.openatb.com/open-banking/v3.1/14083aa6-3f22-495d-a9e7-75abd118d8e9"</span><span class="w">
</span><span class="p">},</span><span class="w">
</span><span class="s2">"Meta"</span><span class="p">:</span><span class="w"> </span><span class="p">{}</span><span class="w">
</span><span class="p">}</span><span class="w">
</span></code></pre>
<p>This API fetches the already created funds confirmation resource which indicates the availability of the funds</p>

<div class="feedback">
  <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Relative-sc-16pg6n7-3 gZdeAQ">
    <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Absolute-sc-16pg6n7-2 frnnzh">
      <div class="Box-sc-1xuamqz-0 Text__TextBox-ezbrsv-1 dvMOMu" style="opacity: 1;">Was this section helpful?<button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">Yes</button><button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">No</button></div>
    </div>
  </div>
</div>
`;

const webHooks = `
<h2 id="open-banking-api-web-hooks"><a href="#open-banking-api-web-hooks" class="header-link"><i></i></a>Web-hooks</h2>
<p><a href="https://raw.githubusercontent.com/revolutdev/open-banking-api-spec/master/webhooks.yaml">OpenAPI Specification</a></p>

<p>Web-hook is a mechanism that allows you to receive updates about your account to an HTTPS endpoint provided by you.
  When a supported event occurs, a notification will be posted, via HTTP POST method, to the specified endpoint.
  If the receiver returns an HTTP error response, there will be two more consequent attempts for delivery.</p>

<p>Currently the following events are supported:</p>

<ul>
  <li>Transaction Creation (TransactionCreated)</li>
  <li>Transaction State Change (TransactionStateChanged)</li>
</ul>
<h3 id="open-banking-api-web-hooks-setting-up-a-web-hook"><a href="#open-banking-api-web-hooks-setting-up-a-web-hook" class="header-link"><i></i></a>Setting up a web-hook</h3>
<blockquote>
  <div>
    <p>Set up a webhook</p>
  </div>
  <div class="env-selector" style="display: block;">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight shell tab-shell--sandbox" style="display: none;"><code>curl -X POST https://sandbox-b2b.revolut.com/api/1.0/webhook <span class="se">\</span>
-H <span class="s2">"Authorization: Bearer &lt;your access token&gt;"</span> <span class="se">\</span>
--data @- <span class="sh">&lt;&lt; EOF

{
"url": "https://example.com/example/path"
}

EOF
</span></code></pre>
<pre class="highlight shell tab-shell--production" style="display: block;"><code>curl -X POST https://b2b.revolut.com/api/1.0/webhook <span class="se">\</span>
-H <span class="s2">"Authorization: Bearer &lt;your access token&gt;"</span> <span class="se">\</span>
--data @- <span class="sh">&lt;&lt; EOF

{
"url": "https://example.com/example/path"
}

EOF
</span></code></pre>
<h4 id="open-banking-api-web-hooks-open-banking-api-web-hooks-setting-up-a-web-hook-request"><a href="#open-banking-api-web-hooks-open-banking-api-web-hooks-setting-up-a-web-hook-request" class="header-link"><i></i></a>Request</h4>
<p>Use this API to create your web-hook endpoint. Only HTTPS web-hooks are supported.</p>

<table>
  <thead>
    <tr>
      <th>Field</th>
      <th>Description</th>
      <th>Format</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>url</td>
      <td>call back endpoint of the client system, <code>https</code> is the supported protocol</td>
      <td>URL</td>
    </tr>
  </tbody>
</table>

<div class="feedback">
  <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Relative-sc-16pg6n7-3 gZdeAQ">
    <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Absolute-sc-16pg6n7-2 frnnzh">
      <div class="Box-sc-1xuamqz-0 Text__TextBox-ezbrsv-1 dvMOMu" style="opacity: 1;">Was this section helpful?<button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">Yes</button><button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">No</button></div>
    </div>
  </div>
</div>
<h3 id="open-banking-api-web-hooks-deleting-a-web-hook"><a href="#open-banking-api-web-hooks-deleting-a-web-hook" class="header-link"><i></i></a>Deleting a web-hook</h3>
<blockquote>
  <div>
    <p>Delete a web-hook</p>
  </div>
  <div class="env-selector" style="display: block;">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight shell tab-shell--sandbox" style="display: none;"><code>curl -X DELETE https://sandbox-b2b.revolut.com/api/1.0/webhook <span class="se">\</span>
-H <span class="s2">"Authorization: Bearer &lt;your access token&gt;"</span>
</code></pre>
<pre class="highlight shell tab-shell--production" style="display: block;"><code>curl -X DELETE https://b2b.revolut.com/api/1.0/webhook <span class="se">\</span>
-H <span class="s2">"Authorization: Bearer &lt;your access token&gt;"</span>
</code></pre>
<p>Use this API request to delete a web-hook</p>

<div class="feedback">
  <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Relative-sc-16pg6n7-3 gZdeAQ">
    <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Absolute-sc-16pg6n7-2 frnnzh">
      <div class="Box-sc-1xuamqz-0 Text__TextBox-ezbrsv-1 dvMOMu" style="opacity: 1;">Was this section helpful?<button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">Yes</button><button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">No</button></div>
    </div>
  </div>
</div>
<h3 id="open-banking-api-web-hooks-transaction-created-event"><a href="#open-banking-api-web-hooks-transaction-created-event" class="header-link"><i></i></a>Transaction Created event</h3>
<blockquote>
  <div>
    <p>Payload example</p>
  </div>
  <div class="env-selector">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight json tab-json"><code><span class="p">{</span><span class="w">
</span><span class="s2">"event"</span><span class="p">:</span><span class="w"> </span><span class="s2">"TransactionCreated"</span><span class="p">,</span><span class="w">
</span><span class="s2">"timestamp"</span><span class="p">:</span><span class="w"> </span><span class="s2">"2017-12-06T12:21:49.865Z"</span><span class="p">,</span><span class="w">
</span><span class="s2">"data"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
  </span><span class="s2">"id"</span><span class="p">:</span><span class="w"> </span><span class="s2">"20ed4029-1af8-405d-b515-0ee5e020a850"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"type"</span><span class="p">:</span><span class="w"> </span><span class="s2">"transfer"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"request_id"</span><span class="p">:</span><span class="w"> </span><span class="s2">"e0cbf84637264ee082a848b"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"state"</span><span class="p">:</span><span class="w"> </span><span class="s2">"completed"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"created_at"</span><span class="p">:</span><span class="w"> </span><span class="s2">"2017-12-06T12:21:48.865Z"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"updated_at"</span><span class="p">:</span><span class="w"> </span><span class="s2">"2017-12-06T12:21:48.865Z"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"completed_at"</span><span class="p">:</span><span class="w"> </span><span class="s2">"2017-12-06T12:21:48.865Z"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"reference"</span><span class="p">:</span><span class="w"> </span><span class="s2">"Payment for Blows &amp; Wistles Co."</span><span class="p">,</span><span class="w">
  </span><span class="s2">"legs"</span><span class="p">:</span><span class="w"> </span><span class="p">[</span><span class="w">
    </span><span class="p">{</span><span class="w">
      </span><span class="s2">"leg_id"</span><span class="p">:</span><span class="w"> </span><span class="s2">"e2fdd0e2-3881-4804-8a8c-6573a97488fa"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"account_id"</span><span class="p">:</span><span class="w"> </span><span class="s2">"87b51b40-a6bc-4f87-a3e6-c05ea203a762"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"counterparty"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
        </span><span class="s2">"type"</span><span class="p">:</span><span class="w"> </span><span class="s2">"revolut"</span><span class="p">,</span><span class="w">
        </span><span class="s2">"account_id"</span><span class="p">:</span><span class="w"> </span><span class="s2">"54c0150f-64de-4653-93e5-af89d21385a0"</span><span class="w">
      </span><span class="p">},</span><span class="w">
      </span><span class="s2">"amount"</span><span class="p">:</span><span class="w"> </span><span class="mf">-120.0</span><span class="p">,</span><span class="w">
      </span><span class="s2">"currency"</span><span class="p">:</span><span class="w"> </span><span class="s2">"CAD"</span><span class="p">,</span><span class="w">
      </span><span class="s2">"balance"</span><span class="p">:</span><span class="w"> </span><span class="mi">102</span><span class="w">
    </span><span class="p">}</span><span class="w">
  </span><span class="p">]</span><span class="w">
</span><span class="p">}</span><span class="w">
</span><span class="p">}</span><span class="w">
</span></code></pre>
<h4 id="open-banking-api-web-hooks-open-banking-api-web-hooks-transaction-created-event-response"><a href="#open-banking-api-web-hooks-open-banking-api-web-hooks-transaction-created-event-response" class="header-link"><i></i></a>Response</h4>
<table>
  <thead>
    <tr>
      <th>Field</th>
      <th>Description</th>
      <th>Format</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>event</td>
      <td>the event name</td>
      <td>Text</td>
    </tr>
    <tr>
      <td>timestamp</td>
      <td>the event time</td>
      <td>ISO date/time</td>
    </tr>
    <tr>
      <td>data.id</td>
      <td>the ID of transaction</td>
      <td>UUID</td>
    </tr>
    <tr>
      <td>data.request_id</td>
      <td>the client provided request ID</td>
      <td>Text</td>
    </tr>
    <tr>
      <td>data.state</td>
      <td>the transction state: <code>pending</code>, <code>completed</code>, <code>declined</code> or <code>failed</code></td>
      <td>Text</td>
    </tr>
    <tr>
      <td>data.reason_code</td>
      <td>an optional reason code for <code>declined</code> or <code>failed</code> transaction state</td>
      <td>Text</td>
    </tr>
    <tr>
      <td>data.created_at</td>
      <td>the instant when the transaction was created</td>
      <td>ISO date/time</td>
    </tr>
    <tr>
      <td>data.updated_at</td>
      <td>the instant when the transaction was last updated</td>
      <td>ISO date/time</td>
    </tr>
    <tr>
      <td>data.completed_at</td>
      <td>the instant when the transaction was completed, mandatory for <code>completed</code> state only</td>
      <td>ISO date/time</td>
    </tr>
    <tr>
      <td>data.scheduled_for</td>
      <td>an optional date when the transaction was scheduled for</td>
      <td>ISO date</td>
    </tr>
    <tr>
      <td>data.reference</td>
      <td>a user provided payment reference</td>
      <td>Text</td>
    </tr>
    <tr>
      <td>data.legs</td>
      <td>the legs of transaction, there'll be 2 legs between your Revolut accounts and 1 leg in other cases</td>
      <td>Array</td>
    </tr>
    <tr>
      <td>data.legs[n].leg_id</td>
      <td>the ID of the leg</td>
      <td>UUID</td>
    </tr>
    <tr>
      <td>data.legs[n].amount</td>
      <td>the transaction amount</td>
      <td>Decimal</td>
    </tr>
    <tr>
      <td>data.legs[n].fee</td>
      <td>an optional transaction fee amount</td>
      <td>Decimal</td>
    </tr>
    <tr>
      <td>data.legs[n].currency</td>
      <td>the transaction currency</td>
      <td>3-letter ISO currency code</td>
    </tr>
    <tr>
      <td>data.legs[n].bill_amount</td>
      <td>the billing amount for cross-currency payments</td>
      <td>Decimal</td>
    </tr>
    <tr>
      <td>data.legs[n].bill_currency</td>
      <td>the billing currency for cross-currency payments</td>
      <td>3-letter ISO currency code</td>
    </tr>
    <tr>
      <td>data.legs[n].account_id</td>
      <td>the ID of the account the transaction is associated with</td>
      <td>UUID</td>
    </tr>
    <tr>
      <td>data.legs[n].counterparty.id</td>
      <td>the counterparty ID</td>
      <td>UUID</td>
    </tr>
    <tr>
      <td>data.legs[n].counterparty.account_id</td>
      <td>the counterparty account ID</td>
      <td>UUID</td>
    </tr>
    <tr>
      <td>data.legs[n].counterparty.account_type</td>
      <td>the type of counterparty account: <code>self</code>, <code>revolut</code>, <code>external</code></td>
      <td>Text</td>
    </tr>
    <tr>
      <td>data.legs[n].description</td>
      <td>the transaction leg purpose</td>
      <td>Text</td>
    </tr>
    <tr>
      <td>data.legs[n].balance</td>
      <td>a total balance of the account the transaction is associated with (optional)</td>
      <td>Decimal</td>
    </tr>
  </tbody>
</table>

<div class="feedback">
  <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Relative-sc-16pg6n7-3 gZdeAQ">
    <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Absolute-sc-16pg6n7-2 frnnzh">
      <div class="Box-sc-1xuamqz-0 Text__TextBox-ezbrsv-1 dvMOMu" style="opacity: 1;">Was this section helpful?<button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">Yes</button><button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">No</button></div>
    </div>
  </div>
</div>
<h3 id="open-banking-api-web-hooks-transaction-state-changed-event"><a href="#open-banking-api-web-hooks-transaction-state-changed-event" class="header-link"><i></i></a>Transaction State Changed event</h3>
<blockquote>
  <div>
    <p>Payload example</p>
  </div>
  <div class="env-selector">
    <select>
      <option value="sandbox">Sandbox</option>
      <option value="production">Production</option>
    </select>
  </div>
</blockquote>
<pre class="highlight json tab-json"><code><span class="p">{</span><span class="w">
</span><span class="s2">"event"</span><span class="p">:</span><span class="w"> </span><span class="s2">"TransactionStateChanged"</span><span class="p">,</span><span class="w">
</span><span class="s2">"timestamp"</span><span class="p">:</span><span class="w"> </span><span class="s2">"2017-12-06T12:21:49.865Z"</span><span class="p">,</span><span class="w">
</span><span class="s2">"data"</span><span class="p">:</span><span class="w"> </span><span class="p">{</span><span class="w">
  </span><span class="s2">"id"</span><span class="p">:</span><span class="w"> </span><span class="s2">"9a6434d8-3581-4faa-988b-48875e785be7"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"old_state"</span><span class="p">:</span><span class="w"> </span><span class="s2">"pending"</span><span class="p">,</span><span class="w">
  </span><span class="s2">"new_state"</span><span class="p">:</span><span class="w"> </span><span class="s2">"reverted"</span><span class="w">
</span><span class="p">}</span><span class="w">
</span><span class="p">}</span><span class="w">
</span></code></pre>
<table>
  <thead>
    <tr>
      <th>Field</th>
      <th>Description</th>
      <th>Format</th>
    </tr>
  </thead>
  <tbody>
    <tr>
      <td>event</td>
      <td>the event name</td>
      <td>Text</td>
    </tr>
    <tr>
      <td>timestamp</td>
      <td>the event time</td>
      <td>ISO date/time</td>
    </tr>
    <tr>
      <td>data.id</td>
      <td>the ID of the transaction</td>
      <td>UUID</td>
    </tr>
    <tr>
      <td>data.old_state</td>
      <td>previous state of the transaction</td>
      <td>Text</td>
    </tr>
    <tr>
      <td>data.new_state</td>
      <td>new state of the transaction</td>
      <td>Text</td>
    </tr>
  </tbody>
</table>

<div class="feedback">
  <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Relative-sc-16pg6n7-3 gZdeAQ">
    <div class="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Absolute-sc-16pg6n7-2 frnnzh">
      <div class="Box-sc-1xuamqz-0 Text__TextBox-ezbrsv-1 dvMOMu" style="opacity: 1;">Was this section helpful?<button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">Yes</button><button type="button" class="ButtonBase-sc-1r7h6bg-0 TextButton-ezx7iu-0 sc-AxjAm bZekbL">No</button></div>
    </div>
  </div>
</div>
`;
