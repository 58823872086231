const config = {
  auth0: {
    domain: process.env.REACT_APP_AUTH0_DOMAIN!,
    clientId: process.env.REACT_APP_AUTH0_CLIENTID!,
    redirectUri: process.env.REACT_APP_AUTH0_REDIRECT_URI!,
    audience: process.env.REACT_APP_AUTH0_AUDIENCE!,
    scope: process.env.REACT_APP_AUTH0_SCOPE!,
  },
  portal: {
    apiUrl: process.env.REACT_APP_PORTAL_API_URL!,
  },
  cms: {
    url: process.env.REACT_APP_CMS_URL!,
  },
  splunk: {
    sendLogs: process.env.REACT_APP_SEND_LOGS!,
    url: process.env.REACT_APP_SPLUNK_URL!,
    collector: process.env.REACT_APP_SPLUNK_LEAP_COLLECTOR!,
  },
};

export { config };
