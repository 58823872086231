import { connect } from 'react-redux';
import { Dispatch, bindActionCreators } from 'redux';
import { fetchToken } from '../redux/userSlice';
import { RootState } from '../App';
import { GettingStarted } from '../components/screens/GettingStarted';

function mapStateToProps(state: RootState) {
  return {
    userName: state.user.userName,
    nickName: state.user.nickName,
    userEmail: state.user.userEmail,
    imageUrl: state.user.userImageUrl,
    institutions: state.user.institutions,
  };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      fetchToken,
    },
    dispatch,
  );
}

export const GettingStartedContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(GettingStarted);
