import React from 'react';
import { SubNavBar, SubNavBarSection } from '../SubNavBar';
import {
  ApiNavSectionsView,
  ApiNavSectionsIndexed,
} from '../../redux/docsSlice';
import { Row, Col } from 'antd';
import { Institutions } from '../types/types';
import { StyledContainer } from '../StyleUtility';
import OpenBankingAPI from '../../specs/open-banking-api';

export interface ReduxProps {
  apiNav: ApiNavSectionsView;
  institutions: Institutions[];
  orderedApiDocs: ApiNavSectionsIndexed[];
  fetchDocs: (standard: string) => void;
  renderDocs: (psd2: boolean) => void;
}

export function ApiDocs({
  fetchDocs,
  renderDocs,
  apiNav,
  institutions,
  orderedApiDocs,
}: ReduxProps) {
  if (typeof institutions === 'undefined') {
    institutions = [
      {
        type: 'acme',
        bank_id: 'ACME_BANK_ID',
        consumer_key: 'CONSUMER_KEY',
        consumer_secret: 'CONSUMER_SECRET',
        username: 'USERNAME',
        password: 'PASSWORD',
        redirect_url: '',
      },
    ];
  }

  // const listRef = useRef(null);
  // const [bankId] = useState('');
  // const [isPSD2] = useState('');
  // const [standard, setStandard] = useState(documentTypes[0]);

  // useEffect(() => {
  //   fetchDocs(standard);
  // }, [standard, fetchDocs]);

  // useEffect(() => {
  //   if (isPSD2 === 'psd2') {
  //     renderDocs(true);
  //   } else if (isPSD2 === 'acme') {
  //     renderDocs(false);
  //   }
  // }, [isPSD2, fetchDocs, renderDocs]);

  // const sections = Object.keys(apiNav).map(key => ({
  //   title: key,
  //   items: apiNav[key].map(navSection => ({
  //     title: navSection.section.title,
  //     onClick: () => {
  //       // @ts-ignore
  //       listRef.current.scrollToRow(navSection.index);
  //       setTimeout(() => {
  //         // @ts-ignore
  //         listRef.current.scrollToRow(navSection.index);
  //       }, 0);
  //     },
  //   })),
  // }));

  const sections: SubNavBarSection[] = [
    {
      title: 'Getting started',
      items: [
        { title: 'Introduction' },
        { title: 'Usage and Limits' },
        { title: 'Demo Account' },
      ],
    },
    // {
    //   title: 'Authentication',
    //   items: [
    //     {
    //       title: 'Setting Up Your Account',
    //     },
    //   ],
    // },
    {
      title: 'OAuth',
      items: [
        { title: 'Get Authorization Code' },
        { title: 'Exchange Authorization Code' },
        { title: 'Refresh Access Token' },
      ],
    },
    {
      title: 'ATMs and Branches',
      items: [{ title: 'Get ATMs' }, { title: 'Get Branches' }],
    },
    {
      title: 'Counterparties',
      items: [
        { title: 'Create Counterparty' },
        { title: 'Create Counterparty Transaction' },
        { title: 'Get Counterparty' },
        { title: 'Get Counterparties' },
      ],
    },
    {
      title: 'Exchanges',
      items: [{ title: 'Get Exchange Rates' }, { title: 'Exchange Currency' }],
    },
    // {
    //   title: 'Web-hooks',
    //   items: [
    //     { title: 'Setting up a web-hook', onClick: () => {} },
    //     { title: 'Deleting a web-hook', onClick: () => {} },
    //     { title: 'Transaction Created event', onClick: () => {} },
    //     { title: 'Transaction State Changed event', onClick: () => {} },
    //   ],
    // },
    {
      title: 'Accounts and Transactions',
      items: [
        { title: 'Account Access Consent' },
        { title: 'Create Account Transaction' },
        { title: 'Get Accounts' },
        { title: 'Get Account Details' },
        { title: 'Get Account Balances' },
        { title: 'Get Account Transactions' },
        { title: 'Get Account Beneficiaries' },
        { title: 'Get Account Direct Debits' },
      ],
    },
    {
      title: 'Domestic Payments',
      items: [
        { title: 'Create Domestic Payment Consent' },
        { title: 'Get Domestic Payment Consent' },
        { title: 'Get Funds Confirmation' },
        { title: 'Create Domestic Payment' },
        { title: 'Get Domestic Payment' },
        // { title: 'Create International Payment Consent', onClick: () => {} },
        // { title: 'Get International Payment Consent', onClick: () => {} },
        // {
        //   title: 'Get International Payment Consent Funds Confirmation',
        //   onClick: () => {},
        // },
        // { title: 'Create International Payment', onClick: () => {} },
        // { title: 'Get International Payment', onClick: () => {} },
      ],
    },
    // { title: 'Errors', items: [] },
  ];
  sections.map((title) => ({
    title: title,
    items: [],
  }));

  // const bankIDSelection = (
  //   <React.Fragment>
  //     <SelectContainer>
  //       <div>
  //         <div>
  //           <Text strong>Type: </Text>
  //         </div>
  //         <Select
  //           onChange={(value: string) => setStandard(value)}
  //           style={{ width: '100%' }}
  //           defaultValue={standard}
  //         >
  //           {documentTypes.map(standard => (
  //             <Select.Option key={standard} value={standard}>
  //               {`${standard}`}
  //             </Select.Option>
  //           ))}
  //         </Select>
  //         <Divider />
  //       </div>
  //     </SelectContainer>
  //   </React.Fragment>
  // );

  return (
    <StyledContainer>
      <Row justify="space-between" align="top">
        {/* {window.outerWidth > 768 && ( */}
        <Col md={6} sm={24}>
          <SubNavBar
            icon={'bank'}
            selectedKey={'Some title'}
            sections={sections}
            menuKey={''}
            hrefPrefix={'open-banking-api-'}
          />
        </Col>
        {/* )} */}
        <Col md={18} sm={24}>
          {/* {orderedApiDocs.map((apiDoc, index) => (
            <ApiDocSection
              bankId={bankId}
              key={(apiDoc.section.title + index).replace(/[\W_]+/g, '')}
              id={(apiDoc.section.title + index).replace(/[\W_]+/g, '')}
              {...apiDoc.section}
            />
          ))} */}
          <OpenBankingAPI />
        </Col>
      </Row>
    </StyledContainer>
  );
}
