import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import { Modal, Button, Card, Divider } from 'antd';
import { Application, Credential } from '../../../api/types';

interface ApplicationCredentialsProps {
  application: Application;
  addNewKeyPairs: any;
  removeKeyPair: any;
}

const CredentialContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 15px;
`;

const CredentialCard = styled(Card)`
  margin-bottom: 5px;
  padding: 10px;
`;

const AddKeyButton = styled(Button)`
  background-color: lightGrey;
`;

const CredentialLinkButton = styled.a`
  vertical-align: text-bottom;
`;

const ApplicationCredentials = (props: ApplicationCredentialsProps) => {
  const [credentialSection, setCredentialSection] = useState(<></>);
  const [credentialToggle, setCredentialToggle] = useState(false);
  const [keyToDelete, setKeyToDelete] = useState('');

  const initShowKeys = () => {
    const keyDict: { [key: string]: boolean } = {};
    props.application.credentials.forEach((element) => {
      keyDict[element.appKey] = false;
    });
    return keyDict;
  };

  const [showKeys] = useState(initShowKeys());

  useEffect(() => {
    const generateKeysOnClick = async () => {
      await props.addNewKeyPairs();
    };

    const getToggleKeyButton = (element: Credential) => {
      return (
        <CredentialLinkButton
          onClick={() => {
            showKeys[element.appKey] = !showKeys[element.appKey];
            const toggle = !credentialToggle;
            setCredentialToggle(toggle);
          }}
        >
          {showKeys[element.appKey] ? 'Hide' : 'Show'}
        </CredentialLinkButton>
      );
    };

    const getDeleteKeyButton = (element: Credential) => {
      return (
        <CredentialLinkButton
          onClick={() => {
            setKeyToDelete(element.appKey);
          }}
        >
          Delete
        </CredentialLinkButton>
      );
    };

    const getKeyStr = (element: Credential) => {
      return showKeys[element.appKey] ? element.appKey : '******';
    };

    const getSecretStr = (element: Credential) => {
      return showKeys[element.appKey] ? element.appSecret : '******';
    };

    const getMobileCredentialRow = (
      element: Credential,
      isOnlyElement: boolean,
    ) => {
      return (
        <React.Fragment key={element.appKey}>
          <CredentialCard bodyStyle={{ padding: '0' }} bordered={false}>
            <p>{getKeyStr(element)}</p>
            <p>{getSecretStr(element)}</p>
            {getToggleKeyButton(element)}
            {!isOnlyElement && (
              <>
                <Divider type="vertical" /> {getDeleteKeyButton(element)}
              </>
            )}
          </CredentialCard>
        </React.Fragment>
      );
    };

    const createCredentialRow = (
      element: Credential,
      isOnlyElement: boolean,
    ) => {
      if (window.outerWidth < 768) {
        return getMobileCredentialRow(element, isOnlyElement);
      }
      return getMobileCredentialRow(element, isOnlyElement);
    };

    const createCredentialSection = () => {
      const rows: JSX.Element[] = [];
      const isOnlyElement: boolean = props.application.credentials.length < 2;
      props.application.credentials.forEach((element) => {
        rows.push(createCredentialRow(element, isOnlyElement));
      });
      rows.push(
        <AddKeyButton
          onClick={() => generateKeysOnClick()}
          key={'new-credential-button'}
        >
          Add key pair
        </AddKeyButton>,
      );
      rows.push(
        <Modal
          title="Confirmation"
          centered={true}
          visible={keyToDelete !== ''}
          onOk={() => {
            props.removeKeyPair(props.application.appName, keyToDelete);
            setKeyToDelete('');
          }}
          onCancel={() => setKeyToDelete('')}
          key={'credential-modal'}
        >
          Delete key pair?
        </Modal>,
      );
      setCredentialSection(<CredentialContainer>{rows}</CredentialContainer>);
    };
    createCredentialSection();
  }, [credentialToggle, props.application, props, keyToDelete, showKeys]);

  return credentialSection;
};

export default ApplicationCredentials;
