import React, { useState } from 'react';
import { Card, Table, message } from 'antd';
import { DownloadOutlined } from '@ant-design/icons';
import Modal from 'antd/lib/modal/Modal';
import styled from 'styled-components';
import filesize from 'filesize';

import { DatasetFile, downloadDataset } from '../api/DataWorld';
import {
  getErrorMessage,
  updateDatasetApplications,
  retrieveDatasetApplications,
} from '../api/Application';
import { Application } from '../api/types';

const DownloadButton = styled(DownloadOutlined)`
  font-size: 1.5rem;
`;

const getFileSize = (files: DatasetFile[]) => {
  let size = 0;

  files.forEach((file) => {
    size += file.sizeInBytes;
  });

  return filesize(size);
};

const getFiles = (files: DatasetFile[]) => {
  return files.map((file) => {
    return <p key={file.name}>{file.name}</p>;
  });
};

export interface DatasetCardProps {
  name: string;
  files: DatasetFile[];
  owner: string;
  id: string;
  jwt: string;
  applications: Application[];
}

export const DatasetCard = ({
  name,
  files,
  owner,
  id,
  jwt,
  applications,
}: DatasetCardProps) => {
  const download = async () => {
    downloadDataset(jwt, owner, id);
  };
  const [modalVisible, setModalVisible] = useState(false);
  const [selectedRowKeys, setSelectedRowKeys] = useState([] as string[]);

  const columns = [
    {
      title: 'Name',
      dataIndex: 'displayName',
      key: 'appName',
    },
  ];

  const rowSelection = {
    selectedRowKeys,
    onChange: (values: any) => {
      setSelectedRowKeys(values); //TODO: Direkt assign etmeyi dene cagirmak yerine
    },
    getCheckboxProps: (application: Application) => ({
      name: application.appName,
    }),
  };

  const appTable = (
    <Table
      rowSelection={{
        type: 'checkbox',
        ...rowSelection,
      }}
      columns={columns}
      dataSource={applications}
      pagination={false}
      rowKey="appName"
    />
  );

  return (
    <React.Fragment>
      <Card
        title={
          <h6>
            <span>{name}</span>
          </h6>
        }
        extra={
          <div>
            {getFileSize(files)}{' '}
            <DownloadButton
              onClick={async () => {
                const result = await retrieveDatasetApplications(jwt, id);
                setSelectedRowKeys(result);
                setModalVisible(true);
              }}
            />
          </div>
        }
      >
        {getFiles(files)}
      </Card>

      <Modal
        title={`Download dataset`}
        centered={true}
        visible={modalVisible}
        onOk={async () => {
          try {
            await updateDatasetApplications(jwt, id, selectedRowKeys);
            setModalVisible(false);
            message.success('Applications updated successfully');
          } catch (error) {
            message.error(getErrorMessage(error));
          }
        }}
        onCancel={() => setModalVisible(false)}
      >
        <p>
          <button
            type="button"
            className="link-button highlighted-link"
            onClick={() => {
              setModalVisible(false);
              download();
            }}
          >
            Download
          </button>{' '}
          the dataset locally or manage the apps using it
        </p>
        {appTable}
      </Modal>
    </React.Fragment>
  );
};
