import React, { useState } from 'react';
import { History } from 'history';
import { useAuth } from 'use-auth0-hooks';
import { message } from 'antd';

import { config } from '../../../config';
import NewApplicationForm from './NewApplicationForm';
import { createApplication, getErrorMessage } from '../../../api/Application';
import { Application } from '../../../api/types';
import {
  SectionTitle,
  StyledContentContainer,
  configureMessageContainer,
} from '../../StyleUtility';
import { getCompressedBase64 } from './ApplicationStyleUtils';
import { Content } from '../../Content';

export interface NewAppProps {
  history: History;
}

const { auth0 } = config;

export function NewApplication(props: NewAppProps) {
  const { accessToken } = useAuth({
    audience: auth0.audience,
    scope: auth0.scope,
  });
  const [formLoading, setFormLoading] = useState(false);
  const [appLogo, setAppLogo] = useState('');
  const [logoLoading, setLogoLoading] = useState(false);

  configureMessageContainer();

  const addApplication = async (application: Application) => {
    setFormLoading(true);
    try {
      application.appLogo = appLogo;
      const result = await createApplication(accessToken!, application);
      setFormLoading(false);
      message.success('Application added successfully');
      props.history.push({
        pathname: '/home/edit-application/' + result.appName,
      });
    } catch (error) {
      setFormLoading(false);
      message.error(getErrorMessage(error));
    }
  };

  const handleLogoChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setLogoLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getCompressedBase64(info.file.originFileObj, (imageUrl: any) => {
        setLogoLoading(false);
        setAppLogo(imageUrl);
        return;
      });
    }
  };

  return (
    <StyledContentContainer>
      <Content>
        <SectionTitle>New application</SectionTitle>
        <NewApplicationForm
          addApplication={addApplication}
          handleLogoChange={handleLogoChange}
          appLogo={appLogo}
          loading={formLoading}
          logoLoading={logoLoading}
        ></NewApplicationForm>
      </Content>
    </StyledContentContainer>
  );
}
