import axios from 'axios';
import { config } from '../config';
import { logEvent } from '../components/utils/analytics';

const { portal } = config;

let datasetUrl = portal.apiUrl + '/dataset';
let datasetsUrl = portal.apiUrl + '/datasets';

export interface DatasetFile {
  name: string;
  sizeInBytes: number;
  created: string;
  updated: string;
}

export interface Dataset {
  owner: string;
  id: string;
  title: string;
  description: string;
  summary: string;
  version: string;
  tags: any[];
  visibility: string;
  files: DatasetFile[];
  status: string;
  created: string;
  updated: string;
  accessLevel: string;
  versionDois: any[];
  isProject: false;
}

export const getDatasets = async (jwt: string): Promise<Dataset[]> => {
  try {
    const response = await axios.get(datasetsUrl, {
      headers: { Authorization: 'Bearer ' + jwt },
    });
    return response.data;
  } catch (error) {
    throw error;
  }
};

export const createDataset = async (
  jwt: string,
  owner: string,
  name: string,
  description: string,
  files: any,
): Promise<string> => {
  try {
    const response = await axios.post(
      `${datasetUrl}`,
      {
        owner,
        name,
        description,
      },
      {
        headers: {
          Authorization: 'Bearer ' + jwt,
          'Content-Type': 'application/json',
        },
      },
    );

    var formData = new FormData();

    files.forEach((file: any) => {
      formData.append('file', file);
    });

    await axios.post(
      `${datasetUrl}/${owner}/${response.data.datasetId}/files`,
      formData,
      {
        headers: {
          Authorization: 'Bearer ' + jwt,
        },
      },
    );

    return 'Success';
  } catch (error) {
    throw error;
  }
};

export const downloadDataset = async (
  jwt: string,
  owner: string,
  datasetId: string,
): Promise<any> => {
  try {
    axios
      .get(`${datasetUrl}/${owner}/${datasetId}/download`, {
        headers: {
          Authorization: 'Bearer ' + jwt,
          Accept: 'application/zip',
        },
        responseType: 'arraybuffer',
      })
      .then((response) => {
        // Generates hidden <a> that starts download
        const url = window.URL.createObjectURL(
          new Blob([response.data], { type: 'application/zip' }),
        );
        const link = document.createElement('a');
        link.href = url;
        link.setAttribute('download', `${datasetId}.zip`);
        document.body.appendChild(link);
        link.click();
        // Send the download event
        logEvent({
          category: 'Data Catalogue',
          action: `Download - ${datasetId}`,
          label: datasetId,
          value: 1,
        });
      });
  } catch (error) {
    throw error;
  }
};
