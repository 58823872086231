import React from 'react';
import DocSection from './DocSection';

const SANDBOX_API_BASE_URL =
  process.env.REACT_APP_SANDBOX_API_BASE_URL ||
  'https://nonprod-preprod.openatb.com';

const PROD_API_BASE_URL =
  process.env.REACT_APP_PROD_API_BASE_URL || 'https://prod.openatb.com';

const SANDBOX_UK_BASE_URL = SANDBOX_API_BASE_URL + '/open-banking/v3.1';

const SANDBOX_BERLIN_BASE_URL = SANDBOX_API_BASE_URL + '/obp/v4.0.0';

const PROD_UK_BASE_URL = process.env.REACT_APP_PROD_UK_BASE_URL || 'TODO';

const PROD_BERLIN_BASE_URL =
  process.env.REACT_APP_PROD_BERLIN_BASE_URL || 'TODO';

// Definition of each section
const getATMs = {
  parentTitle: 'ATMs and Branches',
  title: 'Get ATMs',
  envSelector: true,
  isCollapsible: true,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl {SANDBOX_API_BASE_URL}/open-banking/v2.3/atms{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
    production: (
      <code>
        curl {PROD_API_BASE_URL}/open-banking/v2.3/atms{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
  },
  response: {
    Brand: [
      {
        BrandName: 'ATB Financial',
        ATM: [
          {
            Identification: '0000002348',
            SupportedLanguages: ['ENG'],
            ATMServices: ['Services'],
            Accessibility: 'String',
            SupportedCurrencies: ['CAD'],
            Branch: {
              Identification: '0000002348',
            },
            Location: {
              PostalAddress: {
                AddressLine: ['AddressLine1', 'AddressLine2', 'AddressLine3'],
                BuildingNumber: '2322',
                StreetName: 'String',
                TownName: 'String',
                CountrySubDivision: 'String',
                Country: 'CA',
                PostCode: 'String',
                GeoLocation: {
                  GeographicCoordinates: {
                    Latitude: 56.72542,
                    Longitude: -114.073148,
                  },
                },
              },
            },
          },
        ],
      },
    ],
  },
  body: <p>Returns list of all ATMs.</p>,
};

const getBranches = {
  parentTitle: 'ATMs and Branches',
  title: 'Get Branches',
  envSelector: true,
  isCollapsible: true,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl {SANDBOX_API_BASE_URL}/open-banking/v2.3/branches{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
    production: (
      <code>
        curl {PROD_API_BASE_URL}/open-banking/v2.3/branches{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
  },
  response: {
    Brand: [
      {
        BrandName: 'ATB Financial',
        Branch: [
          {
            Identification: '0000002348',
            SequenceNumber: 1,
            Name: 'Calgary Chinook Branch',
            Type: 'Branch & ABM',
            Photo: 'url',
            CustomerSegment: 'String',
            ServiceAndFacility: 'String',
            Accessibility: 'String',
            OtherServiceAndFacility: [
              {
                Name: 'String',
                Description: 'String',
              },
            ],
            Availability: {
              StandardAvailability: {
                Day: [
                  {
                    Name: 'Monday',
                    OpeningHours: [
                      {
                        OpeningTime: '09:00',
                        ClosingTime: '17:00',
                      },
                    ],
                  },
                ],
              },
            },
            PostalAddress: {
              AddressLine: ['AddressLine1', 'AddressLine2', 'AddressLine3'],
              BuildingNumber: '2322',
              StreetName: 'String',
              TownName: 'String',
              CountrySubDivision: 'String',
              Country: 'CA',
              PostCode: 'String',
              GeoLocation: {
                GeographicCoordinates: {
                  Latitude: 56.72542,
                  Longitude: -114.073148,
                },
              },
            },
          },
        ],
      },
    ],
  },
  body: <p>Returns list of all branches.</p>,
};

const createCounterparty = {
  parentTitle: 'Counterparties',
  title: 'Create Counterparty',
  envSelector: false,
  isCollapsible: true,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl -X POST {SANDBOX_BERLIN_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span>/owner/counterparties{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>{' '}
        <span className="se">\</span>
        {'\n'}-H <span className="s2">"Content-Type: application/json"</span>{' '}
        <span className="se">\</span>
        {'\n'}--data @-{' '}
        <span className="sh">
          &lt;&lt; EOF{'\n'}
          {'\n'}
          {'{'}
          {'\n'}
          {'  '}"name": "CounterpartyName",{'\n'}
          {'  '}"description": "My landlord",{'\n'}
          {'  '}"other_account_routing_scheme": "accountNumber",{'\n'}
          {'  '}"other_account_routing_address": "7987987-2348987-234234",
          {'\n'}
          {'  '}"other_account_secondary_routing_scheme": "IBAN",{'\n'}
          {'  '}"other_account_secondary_routing_address":
          "DE89370400440532013000",{'\n'}
          {'  '}"other_bank_routing_scheme": "bankCode",{'\n'}
          {'  '}"other_bank_routing_address": "10",{'\n'}
          {'  '}"other_branch_routing_scheme": "branchNumber",{'\n'}
          {'  '}"other_branch_routing_address": "10010",{'\n'}
          {'  '}"is_beneficiary": true,{'\n'}
          {'  '}"bespoke": [{'\n'}
          {'    '}
          {'{'}
          {'\n'}
          {'      '}"key": "englishName",{'\n'}
          {'      '}"value": "english Name"{'\n'}
          {'    '}
          {'}'}
          {'\n'}
          {'  '}]{'\n'}
          {'}'}
          {'\n'}
          {'\n'}EOF{'\n'}
        </span>
      </code>
    ),
    production: (
      <code>
        curl -X POST {PROD_BERLIN_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span>/owner/counterparties{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>{' '}
        <span className="se">\</span>
        {'\n'}--data @-{' '}
        <span className="sh">
          &lt;&lt; EOF{'\n'}
          {'\n'}
          {'{'}
          {'\n'}
          {'  '}"name": "CounterpartyName",{'\n'}
          {'  '}"description": "My landlord",{'\n'}
          {'  '}"other_account_routing_scheme": "accountNumber",{'\n'}
          {'  '}"other_account_routing_address": "7987987-2348987-234234",
          {'\n'}
          {'  '}"other_account_secondary_routing_scheme": "IBAN",{'\n'}
          {'  '}"other_account_secondary_routing_address":
          "DE89370400440532013000",{'\n'}
          {'  '}"other_bank_routing_scheme": "bankCode",{'\n'}
          {'  '}"other_bank_routing_address": "10",{'\n'}
          {'  '}"other_branch_routing_scheme": "branchNumber",{'\n'}
          {'  '}"other_branch_routing_address": "10010",{'\n'}
          {'  '}"is_beneficiary": true,{'\n'}
          {'  '}"bespoke": [{'\n'}
          {'    '}
          {'{'}
          {'\n'}
          {'      '}"key": "englishName",{'\n'}
          {'      '}"value": "english Name"{'\n'}
          {'    '}
          {'}'}
          {'\n'}
          {'  '}]{'\n'}
          {'}'}
          {'\n'}
          {'\n'}EOF{'\n'}
        </span>
      </code>
    ),
  },
  response: {
    name: 'CounterpartyName',
    description: 'My landlord',
    created_by_user_id: '9ca9a7e4-6d02-40e3-a129-0b2bf89de9b1',
    this_bank_id: 'GENODEM1GLS',
    this_account_id: '8ca8a7e4-6d02-40e3-a129-0b2bf89de9f0',
    this_view_id: 'owner',
    counterparty_id: '1d65db7c-a7b2-4839-af41-958276ab7790',
    other_bank_routing_scheme: 'bankCode',
    other_bank_routing_address: '10',
    other_branch_routing_scheme: 'branchNumber',
    other_branch_routing_address: '10010',
    other_account_routing_scheme: 'accountNumber',
    other_account_routing_address: '7987987-2348987-234234',
    other_account_secondary_routing_scheme: 'accountId',
    other_account_secondary_routing_address:
      '8ca8a7e4-6d02-48e3-a029-0b2bf89de9f0',
    is_beneficiary: true,
    bespoke: [
      {
        key: 'englishName',
        value: 'english Name',
      },
    ],
    metadata: {
      public_alias: 'String',
      more_info: 'String',
      url: 'String',
      image_url: 'String',
      open_corporates_url: 'String',
      corporate_location: {
        latitude: 11.32,
        longitude: 12.3,
        date: '2019-09-19T00:00:00Z',
        user: {
          id: '123',
          provider: 'http://127.0.0.1:8080',
          username: 'uname',
        },
      },
    },
    physical_location: {
      latitude: 11.32,
      longitude: 12.3,
      date: '2019-09-19T00:00:00Z',
      user: {
        id: '123',
        provider: 'http://127.0.0.1:8080',
        username: 'uname',
      },
    },
    private_alias: 'String',
  },
  body: (
    <React.Fragment>
      <p>Create a counterparty within your application for your account.</p>
      <h6 id="open-banking-api-counterparties-create-counterparty-common-request-fields">
        <a
          href="#open-banking-api-counterparties-create-counterparty-common-request-fields"
          className="header-link"
        >
          <i />
        </a>
        Common request fields
      </h6>
      <table>
        <thead>
          <tr>
            <th>Field</th>
            <th>Description</th>
            <th>Format</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>name</td>
            <td>Human readable counterparty name</td>
            <td>Text</td>
          </tr>
          <tr>
            <td>description</td>
            <td>Counterparty description</td>
            <td>Text</td>
          </tr>
          <tr>
            <td>other_account_routing_scheme</td>
            <td>eg: AccountId or AccountNumber or any other strings</td>
            <td>Text</td>
          </tr>
          <tr>
            <td>other_account_routing_address</td>
            <td>Must be a valid AccountId</td>
            <td>Text</td>
          </tr>
          <tr>
            <td>other_account_secondary_routing_scheme</td>
            <td>eg: IBan or any other strings</td>
            <td>Text</td>
          </tr>
          <tr>
            <td>other_account_secondary_routing_address</td>
            <td>If it is IBan, it should be unique for each counterparty</td>
            <td>Text</td>
          </tr>
          <tr>
            <td>other_bank_routing_scheme</td>
            <td>eg: bankId or bankCode or any other strings</td>
            <td>Text</td>
          </tr>
          <tr>
            <td>other_bank_routing_address</td>
            <td> eg: gh.29.uk, must be a valid sandbox BankId</td>
            <td>Text</td>
          </tr>
          <tr>
            <td>other_branch_routing_scheme</td>
            <td>
              eg: branchId or any other strings or you can leave it empty, not
              useful in sandbox mode.
            </td>
            <td>Text</td>
          </tr>
          <tr>
            <td>other_branch_routing_address</td>
            <td>
              eg: branch-id-123 or you can leave it empty, not useful in sandbox
              mode.
            </td>
            <td>Text</td>
          </tr>
          <tr>
            <td>is_beneficiary</td>
            <td>
              Must be set to true in order to send payments to this counterparty
            </td>
            <td>Boolean</td>
          </tr>
          <tr>
            <td>bespoke</td>
            <td>
              A list of key/value pairs that you can add to the counterparty
            </td>
            <td>Array</td>
          </tr>
          <tr>
            <td>bespoke.key</td>
            <td>Any information key you want to add to this counterparty</td>
            <td>Text</td>
          </tr>
          <tr>
            <td>bespoke.value</td>
            <td>Any information value you want to add to this counterparty</td>
            <td>Text</td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  ),
};

const createCounterpartyTransaction = {
  parentTitle: 'Counterparties',
  title: 'Create Counterparty Transaction',
  envSelector: false,
  isCollapsible: true,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl -X POST {SANDBOX_BERLIN_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span>
        /owner/transaction-request-types/COUNTERPARTY/transaction-requests{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>{' '}
        <span className="se">\</span>
        {'\n'}-H <span className="s2">"Content-Type: application/json"</span>{' '}
        <span className="se">\</span>
        {'\n'}--data @-{' '}
        <span className="sh">
          &lt;&lt; EOF{'\n'}
          {'\n'}
          {'{'}
          {'\n'}
          {'  '}"to": {'{'}
          {'\n'}
          {'    '}"counterparty_id": "123456"{'\n'}
          {'  '}
          {'}'},{'\n'}
          {'  '}"value": {'{'}
          {'\n'}
          {'    '}"currency": "CAD",{'\n'}
          {'    '}"amount": "10"{'\n'}
          {'  '}
          {'}'},{'\n'}
          {'  '}"description": "A description for the transaction to the
          counterparty",{'\n'}
          {'  '}"charge_policy": "SHARED",{'\n'}
          {'  '}"future_date": "20200223"{'\n'}
          {'}'}
          {'\n'}
          {'\n'}EOF{'\n'}
        </span>
      </code>
    ),
    production: (
      <code>
        curl -X POST {PROD_BERLIN_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span>
        /owner/transaction-request-types/COUNTERPARTY/transaction-requests{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>{' '}
        <span className="se">\</span>
        {'\n'}--data @-{' '}
        <span className="sh">
          &lt;&lt; EOF{'\n'}
          {'\n'}
          {'{'}
          {'\n'}
          {'  '}"to": {'{'}
          {'\n'}
          {'    '}"counterparty_id": "123456"{'\n'}
          {'  '}
          {'}'},{'\n'}
          {'  '}"value": {'{'}
          {'\n'}
          {'    '}"currency": "CAD",{'\n'}
          {'    '}"amount": "10"{'\n'}
          {'  '}
          {'}'},{'\n'}
          {'  '}"description": "A description for the transaction to the
          counterparty",{'\n'}
          {'  '}"charge_policy": "SHARED",{'\n'}
          {'  '}"future_date": "20200223"{'\n'}
          {'}'}
          {'\n'}
          {'\n'}EOF{'\n'}
        </span>
      </code>
    ),
  },
  response: {
    id: '4050046c-63b3-4868-8a22-14b4181d33a6',
    type: 'COUNTERPARTY',
    from: {
      bank_id: 'GENODEM1GLS',
      account_id: '8ca8a7e4-6d02-40e3-a129-0b2bf89de9f0',
    },
    details: {
      to_counterparty: {
        counterparty_id: '123456',
      },
      value: {
        currency: 'CAD',
        amount: '100',
      },
      description: 'Description',
    },
    transaction_ids: ['902ba3bb-dedd-45e7-9319-2fd3f2cd98a1'],
    status: 'COMPLETED',
    start_date: '2020-02-19T00:00:00Z',
    end_date: '2020-02-23T00:00:00Z',
    challenge: {
      id: 'be1a183d-b301-4b83-b855-5eeffdd3526f',
      allowed_attempts: 3,
      challenge_type: 'COUNTERPARTY',
    },
    charge: {
      summary: 'Rent the flat',
      value: {
        currency: 'CAD',
        amount: '2',
      },
    },
  },
  body: <p>You can create money transaction request to a counterparty.</p>,
};

const getCounterparty = {
  parentTitle: 'Counterparties',
  title: 'Get Counterparty',
  envSelector: false,
  isCollapsible: true,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl {SANDBOX_BERLIN_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span>/owner/counterparties/
        <span className="o">{'{'}</span>counterparty-id
        <span className="o">{'}'}</span> <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
        {'\n'}
      </code>
    ),
    production: (
      <code>
        curl {PROD_BERLIN_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span>/owner/counterparties/
        <span className="o">{'{'}</span>counterparty-id
        <span className="o">{'}'}</span> <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
        {'\n'}
      </code>
    ),
  },
  response: {
    name: 'CounterpartyName',
    description: 'My landlord',
    created_by_user_id: '9ca9a7e4-6d02-40e3-a129-0b2bf89de9b1',
    this_bank_id: 'GENODEM1GLS',
    this_account_id: '8ca8a7e4-6d02-40e3-a129-0b2bf89de9f0',
    this_view_id: 'owner',
    counterparty_id: '1d65db7c-a7b2-4839-af41-958276ab7790',
    other_bank_routing_scheme: 'bankCode',
    other_bank_routing_address: '10',
    other_branch_routing_scheme: 'branchNumber',
    other_branch_routing_address: '10010',
    other_account_routing_scheme: 'accountNumber',
    other_account_routing_address: '7987987-2348987-234234',
    other_account_secondary_routing_scheme: 'accountId',
    other_account_secondary_routing_address:
      '8ca8a7e4-6d02-48e3-a029-0b2bf89de9f0',
    is_beneficiary: true,
    bespoke: [
      {
        key: 'englishName',
        value: 'english Name',
      },
    ],
    metadata: {
      public_alias: 'String',
      more_info: 'String',
      url: 'String',
      image_url: 'String',
      open_corporates_url: 'String',
      corporate_location: {
        latitude: 11.32,
        longitude: 12.3,
        date: '2019-09-19T00:00:00Z',
        user: {
          id: '123',
          provider: 'http://127.0.0.1:8080',
          username: 'uname',
        },
      },
    },
    physical_location: {
      latitude: 11.32,
      longitude: 12.3,
      date: '2019-09-19T00:00:00Z',
      user: {
        id: '123',
        provider: 'http://127.0.0.1:8080',
        username: 'uname',
      },
    },
    private_alias: 'String',
  },
  body: (
    <p>
      This API endpoint retrieves a counterparty by <code>counterparty-id</code>
      .
    </p>
  ),
};

const getCounterparties = {
  parentTitle: 'Counterparties',
  title: 'Get Counterparties',
  envSelector: false,
  isCollapsible: true,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl {SANDBOX_BERLIN_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span>/owner/counterparties{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
        {'\n'}
      </code>
    ),
    production: (
      <code>
        curl {PROD_BERLIN_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span>/owner/counterparties{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
        {'\n'}
      </code>
    ),
  },
  response: {
    counterparties: [
      {
        name: 'CounterpartyName',
        description: 'My landlord',
        created_by_user_id: '9ca9a7e4-6d02-40e3-a129-0b2bf89de9b1',
        this_bank_id: 'GENODEM1GLS',
        this_account_id: '8ca8a7e4-6d02-40e3-a129-0b2bf89de9f0',
        this_view_id: 'owner',
        counterparty_id: '1d65db7c-a7b2-4839-af41-958276ab7790',
        other_bank_routing_scheme: 'bankCode',
        other_bank_routing_address: '10',
        other_branch_routing_scheme: 'branchNumber',
        other_branch_routing_address: '10010',
        other_account_routing_scheme: 'accountNumber',
        other_account_routing_address: '7987987-2348987-234234',
        other_account_secondary_routing_scheme: 'accountId',
        other_account_secondary_routing_address:
          '8ca8a7e4-6d02-48e3-a029-0b2bf89de9f0',
        is_beneficiary: true,
        bespoke: [
          {
            key: 'englishName',
            value: 'english Name',
          },
        ],
      },
    ],
  },
  body: <p>This endpoint retrieves all of your counterparies.</p>,
};

const getExchangeRates = {
  parentTitle: 'Exchanges',
  title: 'Get Exchange Rates',
  envSelector: false,
  isCollapsible: false,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl {SANDBOX_BERLIN_BASE_URL}/fx/
        <span className="o">{'{'}</span>
        from-currency-code<span className="o">{'}'}</span>/
        <span className="o">{'{'}</span>to-currency-code
        <span className="o">{'}'}</span> <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
        {'\n'}
      </code>
    ),
    production: (
      <code>
        curl {PROD_BERLIN_BASE_URL}/fx/<span className="o">{'{'}</span>
        from-currency-code<span className="o">{'}'}</span>/
        <span className="o">{'{'}</span>to-currency-code
        <span className="o">{'}'}</span> <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
        {'\n'}
      </code>
    ),
  },
  response: {
    bank_id: 'GENODEM1GLS',
    from_currency_code: 'CAD',
    to_currency_code: 'USD',
    conversion_value: 0.73,
    inverse_conversion_value: 1.37,
    effective_date: '2019-09-19T00:00:00Z',
  },
  body: (
    <p>
      Returns the exchange rate of 3-letter ISO currency codes{' '}
      <code>from-currency-code</code> and <code>to-currency-code</code>.
    </p>
  ),
};

const exchangeCurrency = {
  parentTitle: 'Exchanges',
  title: 'Exchange Currency',
  envSelector: false,
  isCollapsible: false,
  isFeedbackRequired: true,
  body: (
    <React.Fragment>
      <p>
        You can transfer money to an account or a counterparty with a different
        currency. The currency unit in the body must match the sending account.
      </p>
      <p>
        To check the exchange rate and fees for the operation, please use the{' '}
        <code>/rate</code> endpoint.
      </p>
    </React.Fragment>
  ),
};

const cancelRevokeConsent = {
  parentTitle: 'Accounts and Transactions',
  title: 'Cancel/Revoke Consent',
  envSelector: false,
  isCollapsible: false,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl -X DELETE {SANDBOX_UK_BASE_URL}/account-access-consents/
        <span className="o">{'{'}</span>consent-id
        <span className="o">{'}'}</span> <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
    production: (
      <code>
        curl -X DELETE {PROD_UK_BASE_URL}/account-access-consents/
        <span className="o">{'{'}</span>consent-id
        <span className="o">{'}'}</span> <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
  },
  body: (
    <React.Fragment>
      <p>This endpoint:</p>
      <ul>
        <li>
          <p>
            Rejects consent if a consent in <code>AwaitingAuthorization</code>{' '}
            state
          </p>
        </li>
        <li>
          <p>
            Revokes consent if consent in <code>Authorized</code> state
          </p>
        </li>
      </ul>
    </React.Fragment>
  ),
};

const getConsent = {
  parentTitle: 'Accounts and Transactions',
  title: 'Get Consent',
  envSelector: false,
  isCollapsible: true,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl {SANDBOX_UK_BASE_URL}/account-access-consents/
        <span className="o">{'{'}</span>consent-id
        <span className="o">{'}'}</span> <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
    production: (
      <code>
        curl {PROD_UK_BASE_URL}/account-access-consents/
        <span className="o">{'{'}</span>consent-id
        <span className="o">{'}'}</span> <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
  },
  response: {
    Meta: {
      FirstAvailableDateTime: {},
      TotalPages: 0,
    },
    Risk: {},
    Links: {
      Last: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Prev: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Next: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Self: 'https://api.openatb.com/open-banking/v3.1/accounts',
      First: 'https://api.openatb.com/open-banking/v3.1/accounts',
    },
    Data: {
      Status: {},
      StatusUpdateDateTime: '2000-01-23T04:56:07.000+00:00',
      CreationDateTime: '2000-01-23T04:56:07.000+00:00',
      TransactionToDateTime: '2000-01-23T04:56:07.000+00:00',
      ExpirationDateTime: '2000-01-23T04:56:07.000+00:00',
      Permissions: [{}, {}],
      ConsentId: 'ConsentId',
      TransactionFromDateTime: '2000-01-23T04:56:07.000+00:00',
    },
  },
  body: (
    <p>
      This endpoint returns consent details as it is described in the official
      documentation.
    </p>
  ),
};

const createConsent = {
  parentTitle: 'Accounts and Transactions',
  title: 'Create Consent',
  envSelector: false,
  isCollapsible: true,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl -X POST {SANDBOX_UK_BASE_URL}/account-access-consents{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>{' '}
        <span className="se">\</span>
        {'\n'}-H <span className="s2">"Content-Type: application/json"</span>{' '}
        <span className="se">\</span>
        {'\n'}--data @-{' '}
        <span className="sh">
          &lt;&lt; EOF{'\n'}
          {'\n'}
          {'{'}
          {'\n'}
          {'  '}"Data": {'{'}
          {'\n'}
          {'    '}"Permissions": {'['}
          {'\n'}
          {'      '}"ReadAccountsDetail",{'\n'}
          {'      '}"ReadBalances",{'\n'}
          {'      '}"ReadBeneficiariesDetail",{'\n'}
          {'      '}"ReadDirectDebits",{'\n'}
          {'      '}"ReadProducts",{'\n'}
          {'      '}"ReadStandingOrdersDetail",{'\n'}
          {'      '}"ReadTransactionsCredits",{'\n'}
          {'      '}"ReadTransactionsDebits",{'\n'}
          {'      '}"ReadTransactionsDetail",{'\n'}
          {'      '}"ReadOffers",{'\n'}
          {'      '}"ReadPAN",{'\n'}
          {'      '}"ReadParty",{'\n'}
          {'      '}"ReadPartyPSU",{'\n'}
          {'      '}"ReadScheduledPaymentsDetail",{'\n'}
          {'      '}"ReadStatementsDetail"{'\n'}
          {'     ]'},{'\n'}
          {'    '}"ExpirationDateTime": "2017-05-02T00:00:00+00:00",{'\n'}
          {'    '}"TransactionFromDateTime": "2017-05-03T00:00:00+00:00",
          {'\n'}
          {'    '}"TransactionToDateTime": "2017-12-03T00:00:00+00:00"{'\n'}
          {'  '}
          {'}'},{'\n'}
          {'  '}"Risk": {'{}'}
          {'\n'}
          {'}'}
          {'\n'}
          {'\n'}EOF{'\n'}
        </span>
      </code>
    ),
    production: (
      <code>
        curl -X POST {PROD_UK_BASE_URL}/account-access-consents{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>{' '}
        <span className="se">\</span>
        {'\n'}-H <span className="s2">"Content-Type: application/json"</span>{' '}
        <span className="se">\</span>
        {'\n'}--data @-{' '}
        <span className="sh">
          &lt;&lt; EOF{'\n'}
          {'\n'}
          {'{'}
          {'\n'}
          {'  '}"Data": {'{'}
          {'\n'}
          {'    '}"Permissions": {'['}
          {'\n'}
          {'      '}"ReadAccountsDetail",{'\n'}
          {'      '}"ReadBalances",{'\n'}
          {'      '}"ReadBeneficiariesDetail",{'\n'}
          {'      '}"ReadDirectDebits",{'\n'}
          {'      '}"ReadProducts",{'\n'}
          {'      '}"ReadStandingOrdersDetail",{'\n'}
          {'      '}"ReadTransactionsCredits",{'\n'}
          {'      '}"ReadTransactionsDebits",{'\n'}
          {'      '}"ReadTransactionsDetail",{'\n'}
          {'      '}"ReadOffers",{'\n'}
          {'      '}"ReadPAN",{'\n'}
          {'      '}"ReadParty",{'\n'}
          {'      '}"ReadPartyPSU",{'\n'}
          {'      '}"ReadScheduledPaymentsDetail",{'\n'}
          {'      '}"ReadStatementsDetail"{'\n'}
          {'     ]'},{'\n'}
          {'    '}"ExpirationDateTime": "2017-05-02T00:00:00+00:00",{'\n'}
          {'    '}"TransactionFromDateTime": "2017-05-03T00:00:00+00:00",
          {'\n'}
          {'    '}"TransactionToDateTime": "2017-12-03T00:00:00+00:00"{'\n'}
          {'  '}
          {'}'},{'\n'}
          {'  '}"Risk": {'{}'}
          {'\n'}
          {'}'}
          {'\n'}
          {'\n'}EOF{'\n'}
        </span>
      </code>
    ),
  },
  response: {
    Meta: {
      FirstAvailableDateTime: {},
      TotalPages: 0,
    },
    Risk: {},
    Links: {
      Last: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Prev: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Next: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Self: 'https://api.openatb.com/open-banking/v3.1/accounts',
      First: 'https://api.openatb.com/open-banking/v3.1/accounts',
    },
    Data: {
      Status: {},
      StatusUpdateDateTime: '2000-01-23T04:56:07.000+00:00',
      CreationDateTime: '2000-01-23T04:56:07.000+00:00',
      TransactionToDateTime: '2000-01-23T04:56:07.000+00:00',
      ExpirationDateTime: '2000-01-23T04:56:07.000+00:00',
      Permissions: [{}, {}],
      ConsentId: 'ConsentId',
      TransactionFromDateTime: '2000-01-23T04:56:07.000+00:00',
    },
  },
  body: <p>This endpoint creates a consent to be authorized by the user.</p>,
};

const createAccountTransaction = {
  parentTitle: 'Accounts and Transactions',
  title: 'Create Account Transaction',
  envSelector: false,
  isCollapsible: true,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl -X POST {SANDBOX_BERLIN_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span>
        /owner/transaction-request-types/ACCOUNT/transaction-requests{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>{' '}
        <span className="se">\</span>
        {'\n'}-H <span className="s2">"Content-Type: application/json"</span>{' '}
        <span className="se">\</span>
        {'\n'}--data @-{' '}
        <span className="sh">
          &lt;&lt; EOF{'\n'}
          {'\n'}
          {'{'}
          {'\n'}
          {'  '}"to": {'{'}
          {'\n'}
          {'    '}"account_id": "8ca8a7e4-6d02-40e3-a129-0b2bf89de9f0"{'\n'}
          {'  '}
          {'}'},{'\n'}
          {'  '}"value": {'{'}
          {'\n'}
          {'    '}"currency": "EUR",{'\n'}
          {'    '}"amount": "10"{'\n'}
          {'  '}
          {'}'},{'\n'}
          {'  '}"description": "this is for work"{'\n'}
          {'}'}
          {'\n'}
          {'\n'}EOF{'\n'}
        </span>
      </code>
    ),
    production: (
      <code>
        curl -X POST {PROD_BERLIN_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span>
        /owner/transaction-request-types/ACCOUNT/transaction-requests{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>{' '}
        <span className="se">\</span>
        {'\n'}--data @-{' '}
        <span className="sh">
          &lt;&lt; EOF{'\n'}
          {'\n'}
          {'{'}
          {'\n'}
          {'  '}"to": {'{'}
          {'\n'}
          {'    '}"account_id": "8ca8a7e4-6d02-40e3-a129-0b2bf89de9f0"{'\n'}
          {'  '}
          {'}'},{'\n'}
          {'  '}"value": {'{'}
          {'\n'}
          {'    '}"currency": "EUR",{'\n'}
          {'    '}"amount": "10"{'\n'}
          {'  '}
          {'}'},{'\n'}
          {'  '}"description": "this is for work"{'\n'}
          {'}'}
          {'\n'}
          {'\n'}EOF{'\n'}
        </span>
      </code>
    ),
  },
  response: {
    id: '4050046c-63b3-4868-8a22-14b4181d33a6',
    type: 'ACCOUNT',
    from: {
      bank_id: 'GENODEM1GLS',
      account_id: '8ca8a7e4-6d02-40e3-a129-0b2bf89de9f0',
    },
    details: {
      to_transfer_to_account: {
        value: {
          currency: 'CAD',
          amount: '100',
        },
        description: 'Description',
        transfer_type: 'TransferType',
        future_date: '20200301',
        to: {
          name: 'String',
          bank_code: 'String',
          branch_number: 'String',
          account: {
            number: 'String',
            iban: 'String',
          },
        },
      },
      value: {
        currency: 'CAD',
        amount: '100',
      },
      description: 'Description',
    },
    transaction_ids: ['902ba3bb-dedd-45e7-9319-2fd3f2cd98a1'],
    status: 'COMPLETED',
    start_date: '2020-02-19T00:00:00Z',
    end_date: '2020-02-23T00:00:00Z',
    challenge: {
      id: 'be1a183d-b301-4b83-b855-5eeffdd3526f',
      allowed_attempts: 3,
      challenge_type: 'ACCOUNT',
    },
    charge: {
      summary: 'Rent the flat',
      value: {
        currency: 'CAD',
        amount: '2',
      },
    },
  },
  body: <p>You can create money transaction request to an existing account.</p>,
};

const getAccounts = {
  parentTitle: 'Accounts and Transactions',
  title: 'Get Accounts',
  envSelector: false,
  isCollapsible: true,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl {SANDBOX_UK_BASE_URL}/accounts <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
    production: (
      <code>
        curl {PROD_UK_BASE_URL}/accounts <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
  },
  response: {
    Data: {
      Account: [
        {
          Account: [
            {
              SchemeName: ['OBP'],
              Identification: '0e2be7b31bb049cdb70c92153f6372f5',
              Name: 'Wealth Private Chequing Account',
              SecondaryIdentification: '00021',
            },
          ],
          Servicer: {
            SchemeName: ['OBP'],
            Identification: 'UK123456',
          },
          AccountId: '4052016652670-db84f655-996',
          Currency: 'CAD',
          AccountType: 'OBP09425',
          AccountSubType: 'OBP09425',
          Nickname: 'Wealth Private Chequing Account',
        },
      ],
    },
    Links: {
      Last: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Prev: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Next: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Self: 'https://api.openatb.com/open-banking/v3.1/accounts',
      First: 'https://api.openatb.com/open-banking/v3.1/accounts',
    },
    Meta: {
      TotalPages: 1,
      FirstAvailableDateTime: '2020-03-31T21:11:49Z',
      LastAvailableDateTime: '2020-03-31T21:11:49Z',
    },
  },
  body: <p>You can create money transaction request to an existing account.</p>,
};

const getAccountDetails = {
  parentTitle: 'Accounts and Transactions',
  title: 'Get Account Details',
  envSelector: false,
  isCollapsible: true,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl {SANDBOX_UK_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span> <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
    production: (
      <code>
        curl {SANDBOX_UK_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span> <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
  },
  response: {
    Data: {
      Account: [
        {
          Account: [
            {
              SchemeName: ['OBP'],
              Identification: '0e2be7b31bb049cdb70c92153f6372f5',
              Name: 'Wealth Private Chequing Account',
              SecondaryIdentification: '00021',
            },
          ],
          Servicer: {
            SchemeName: ['OBP'],
            Identification: 'UK123456',
          },
          AccountId: '4052016652670-db84f655-996',
          Currency: 'CAD',
          AccountType: 'OBP09425',
          AccountSubType: 'OBP09425',
          Nickname: 'Wealth Private Chequing Account',
        },
      ],
    },
    Links: {
      Last:
        'https://api.openatb.com/open-banking/v3.1/accounts/5532190048216-10c54ba8-985',
      Prev:
        'https://api.openatb.com/open-banking/v3.1/accounts/5532190048216-10c54ba8-985',
      Next:
        'https://api.openatb.com/open-banking/v3.1/accounts/5532190048216-10c54ba8-985',
      Self:
        'https://api.openatb.com/open-banking/v3.1/accounts/5532190048216-10c54ba8-985',
      First:
        'https://api.openatb.com/open-banking/v3.1/accounts/5532190048216-10c54ba8-985',
    },
    Meta: {
      TotalPages: 1,
      FirstAvailableDateTime: '2020-03-31T21:11:49Z',
      LastAvailableDateTime: '2020-03-31T21:11:49Z',
    },
  },
  body: <p>This endpoint retrieves individual account details.</p>,
};

const getAccountBalances = {
  parentTitle: 'Accounts and Transactions',
  title: 'Get Account Balances',
  envSelector: false,
  isCollapsible: true,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl {SANDBOX_UK_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span>/balances <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
    production: (
      <code>
        curl {SANDBOX_UK_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span>/balances <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
  },
  response: {
    Data: {
      Balance: [
        {
          AccountId: '4052016652670-db84f655-996',
          Amount: {
            Amount: '815.00',
            Currency: 'CAD',
          },
          CreditLine: [
            {
              Included: false,
              Type: 'Available',
              Amount: {
                Amount: '182.21',
                Currency: 'CAD',
              },
            },
          ],
          CreditDebitIndicator: 'Debit',
          Type: 'InterimAvailable',
          DateTime: '2020-03-30T21:53:47Z',
        },
      ],
    },
    Links: {
      Last: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Prev: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Next: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Self: 'https://api.openatb.com/open-banking/v3.1/accounts',
      First: 'https://api.openatb.com/open-banking/v3.1/accounts',
    },
    Meta: {
      TotalPages: 1,
      FirstAvailableDateTime: '2020-03-30T21:53:47Z',
      LastAvailableDateTime: '2020-03-30T21:53:47Z',
    },
  },
  body: <p>This endpoint retrieves balances for an account.</p>,
};

const getAccountTransactions = {
  parentTitle: 'Accounts and Transactions',
  title: 'Get Account Transactions',
  envSelector: false,
  isCollapsible: true,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl {SANDBOX_UK_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span>/transactions{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
    production: (
      <code>
        curl {PROD_UK_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span>/transactions{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
  },
  response: {
    Meta: {
      TotalPages: 1,
      FirstAvailableDateTime: '2020-03-30T21:53:47Z',
      LastAvailableDateTime: '2020-03-30T21:53:47Z',
    },
    Links: {
      Last: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Prev: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Next: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Self: 'https://api.openatb.com/open-banking/v3.1/accounts',
      First: 'https://api.openatb.com/open-banking/v3.1/accounts',
    },
    Data: {
      Transaction: [
        {
          Status: 'Booked',
          SupplementaryData: {
            UserComments: 'Transaction',
          },
          CreditorAgent: {
            PostalAddress: {
              StreetName: 'StreetName',
              CountrySubDivision: 'CountrySubDivision',
              Department: 'Department',
              AddressLine: ['AddressLine 1', 'AddressLine 2', 'AddressLine 3'],
              BuildingNumber: 'BuildingNumber',
              TownName: 'TownName',
              Country: 'Country',
              SubDepartment: 'SubDepartment',
              AddressType: 'AddressType',
              PostCode: 'PostCode',
            },
            SchemeName: ['UK.OBIE.BICFI'],
            Identification: 'Identification',
            Name: 'Name',
          },
          DebtorAccount: {
            SecondaryIdentification: 'SecondaryIdentification',
            SchemeName: ['UK.OBIE.BBAN', 'UK.OBIE.IBAN', 'UK.OBIE.Paym'],
            Identification: 'Identification',
            Name: 'Name',
          },
          AccountId: '4052016652670-db84f655-996',
          TransactionReference: 'TransactionReference',
          ProprietaryBankTransactionCode: {
            Issuer: 'Issuer',
            Code: 'Code',
          },
          AddressLine: 'AddressLine',
          Amount: {
            Amount: '32.00',
            Currency: 'CAD',
          },
          CreditorAccount: {
            SecondaryIdentification: 'SecondaryIdentification',
            SchemeName: ['UK.OBIE.BBAN', 'UK.OBIE.IBAN', 'UK.OBIE.Paym'],
            Identification: 'Identification',
            Name: 'Name',
          },
          CreditDebitIndicator: 'Debit',
          CurrencyExchange: {
            SourceCurrency: 'SourceCurrency',
            ExchangeRate: 0.71,
            QuotationDate: '2020-01-23T04:56:07.000+00:00',
            UnitCurrency: 'UnitCurrency',
            ContractIdentification: 'ContractIdentification',
            InstructedAmount: {
              Amount: '32.00',
              Currency: 'CAD',
            },
            TargetCurrency: 'USD',
          },
          StatementReference: ['StatementReference 1', 'StatementReference 2'],
          ChargeAmount: {
            Amount: '2.00',
            Currency: 'CAD',
          },
          TransactionId: 'acd157de-f877-44f1-9190-58051bec3815',
          TransactionInformation: 'description',
          BookingDateTime: '2019-01-12T00:00:00+00:00',
          BankTransactionCode: {
            SubCode: 'SubCode',
            Code: 'Code',
          },
          MerchantDetails: {
            MerchantName: 'MerchantName',
            MerchantCategoryCode: 'MerchantCategoryCode',
          },
          CardInstrument: {
            AuthorizationType: 'string',
            Identification: 'Identification',
            CardSchemeName: 'string',
            Name: 'Name',
          },
          ValueDateTime: '2019-01-12T00:00:00+00:00',
          DebtorAgent: {
            PostalAddress: {
              StreetName: 'StreetName',
              CountrySubDivision: 'CountrySubDivision',
              Department: 'Department',
              AddressLine: ['AddressLine 1', 'AddressLine 2', 'AddressLine 3'],
              BuildingNumber: 'BuildingNumber',
              TownName: 'TownName',
              Country: 'Country',
              SubDepartment: 'SubDepartment',
              AddressType: 'AddressType',
              PostCode: 'PostCode',
            },
            SchemeName: ['UK.OBIE.BICFI'],
            Identification: 'Identification',
            Name: 'Name',
          },
          Balance: {
            Type: 'InterimBooked',
            Amount: {
              Amount: '21.00',
              Currency: 'CAD',
            },
            CreditDebitIndicator: 'Debit',
          },
        },
      ],
    },
  },
  body: <p>This endpoint retrieves the recent transactions for an account.</p>,
};

const getAccountBeneficiaries = {
  parentTitle: 'Accounts and Transactions',
  title: 'Get Account Beneficiaries',
  envSelector: false,
  isCollapsible: true,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl {SANDBOX_UK_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span>/beneficiaries{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
    production: (
      <code>
        curl {PROD_UK_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span>/beneficiaries{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
  },
  response: {
    Meta: {
      TotalPages: 1,
      FirstAvailableDateTime: '2020-03-30T21:53:47Z',
      LastAvailableDateTime: '2020-03-30T21:53:47Z',
    },
    Links: {
      Last: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Prev: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Next: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Self: 'https://api.openatb.com/open-banking/v3.1/accounts',
      First: 'https://api.openatb.com/open-banking/v3.1/accounts',
    },
    Data: {
      Beneficiary: [
        {
          CreditorAgent: {
            PostalAddress: {
              StreetName: 'StreetName',
              CountrySubDivision: 'CountrySubDivision',
              Department: 'Department',
              AddressLine: ['AddressLine 1', 'AddressLine 2', 'AddressLine 3'],
              BuildingNumber: 'BuildingNumber',
              TownName: 'TownName',
              Country: 'Country',
              SubDepartment: 'SubDepartment',
              AddressType: 'AddressType',
              PostCode: 'PostCode',
            },
            SchemeName: ['UK.OBIE.BICFI'],
            Identification: 'Identification',
            Name: 'Name',
          },
          AccountId: '4052016652670-db84f655-996',
          Reference: 'Reference',
          CreditorAccount: {
            SecondaryIdentification: 'SecondaryIdentification',
            SchemeName: ['UK.OBIE.BICFI'],
            Identification: 'Identification',
            Name: 'testFirstName testLastName',
          },
          BeneficiaryId: 'BeneficiaryId',
        },
      ],
    },
  },
  body: (
    <p>
      This endpoint returns the list of beneficiaries for a specific user
      account.
    </p>
  ),
};

const getAccountDirectDebits = {
  parentTitle: 'Accounts and Transactions',
  title: 'Get Account Direct Debits',
  envSelector: false,
  isCollapsible: true,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl {SANDBOX_UK_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span>/direct-debits{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
    production: (
      <code>
        curl {PROD_UK_BASE_URL}/accounts/
        <span className="o">{'{'}</span>account-id
        <span className="o">{'}'}</span>/direct-debits{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
  },
  response: {
    Meta: {
      TotalPages: 1,
      FirstAvailableDateTime: '2020-03-30T21:53:47Z',
      LastAvailableDateTime: '2020-03-30T21:53:47Z',
    },
    Links: {
      Last: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Prev: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Next: 'https://api.openatb.com/open-banking/v3.1/accounts',
      Self: 'https://api.openatb.com/open-banking/v3.1/accounts',
      First: 'https://api.openatb.com/open-banking/v3.1/accounts',
    },
    Data: {
      DirectDebit: [
        {
          PreviousPaymentDateTime: '2020-01-23T04:56:07.000+00:00',
          AccountId: '4052016652670-db84f655-996',
          MandateIdentification: 'MandateIdentification',
          DirectDebitStatusCode: 'Active',
          DirectDebitId: 'DirectDebitId',
          Name: 'testName',
          PreviousPaymentAmount: {
            Amount: '28.00',
            Currency: 'CAD',
          },
        },
      ],
    },
  },
  body: (
    <p>
      This endpoint returns the list of direct debits for a specific user
      account.
    </p>
  ),
};

const createDomesticPaymentConsent = {
  parentTitle: 'Domestic Payments',
  title: 'Create Domestic Payment Consent',
  envSelector: false,
  isCollapsible: true,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl -X POST {SANDBOX_API_BASE_URL}
        /open-banking/v3.1/payments/domestic-payment-consents{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>{' '}
        <span className="se">\</span>
        {'\n'}-H <span className="s2">"Content-Type: application/json"</span>{' '}
        <span className="se">\</span>
        {'\n'}--data @-{' '}
        <span className="sh">
          &lt;&lt; EOF{'\n'}
          {'\n'}
          {'{'}
          {'\n'}
          "Meta": {'{'}
          {'\n'}
          {'  '}"FirstAvailableDateTime": "2020-02-05T10:11:22+00:00",{'\n'}
          {'  '}"TotalPages": 0{'\n'}
          {'}'},{'\n'}
          "Risk": {'{'}
          {'\n'}
          {'  '}"PaymentContextCode": "EcommerceGoods",{'\n'}
          {'  '}"DeliveryAddress": {'{'}
          {'\n'}
          {'    '}"AddressLine": ["7"],{'\n'}
          {'    '}"StreetName": "Apple Street",{'\n'}
          {'    '}"BuildingNumber": "1",{'\n'}
          {'    '}"PostCode": "E2 7AA",{'\n'}
          {'    '}"TownName": "London",{'\n'}
          {'    '}"Country": "UK"{'\n'}
          {'  '}
          {'}'},{'\n'}
          {'  '}"MerchantCategoryCode": "5967",{'\n'}
          {'  '}"MerchantCustomerIdentification": "1238808123123"{'\n'}
          {'}'},{'\n'}
          "Data": {'{'}
          {'\n'}
          {'  '}"Charges": [{'{'}
          {'\n'}
          {'    '}"Type": ["UK.OBIE.CHAPSOut"],{'\n'}
          {'    '}"Amount": {'{'}
          {'\n'}
          {'      '}"Amount": "2",{'\n'}
          {'      '}"Currency": "CAD"{'\n'}
          {'  '}
          {'  }'},{'\n'}
          {'    '}"ChargeBearer": "2"{'\n'}
          {'  }'}], {'\n'}
          {'  '}"Initiation": {'{'}
          {'\n'}
          {'    '}"SupplementaryData": {'{},'}
          {'\n'}
          {'    '}"LocalInstrument": ["UK.OBIE.BACS"],{'\n'}
          {'    '}"DebtorAccount": {'{'}
          {'\n'}
          {'      '}"SchemeName": "UK.OBIE.IBAN",{'\n'}
          {'      '}"Identification": "GB38REVO00996907112483",{'\n'}
          {'      '}"Name": "Name",{'\n'}
          {'      '}"SecondaryIdentification": "0002"{'\n'}
          {'    }'},{'\n'}
          {'    '}"EndToEndIdentification": "E2E123",{'\n'}
          {'    '}"InstructionIdentification": "ID412",{'\n'}
          {'    '}"CreditorAccount": {'{'}
          {'\n'}
          {'      '}"SchemeName": "UK.OBIE.SortCodeAccountNumber",{'\n'}
          {'      '}"Identification": "11223321325698",{'\n'}
          {'      '}"Name": "Receiver Co."{'\n'}
          {'    }'},{'\n'}
          {'    '}"InstructedAmount": {'{'}
          {'\n'}
          {'      '}"Amount": "55.00",{'\n'}
          {'      '}"Currency": "CAD"{'\n'}
          {'    }'},{'\n'}
          {'    '}"CreditorPostalAddress": {'{'}
          {'\n'}
          {'      '}"StreetName": "StreetName",{'\n'}
          {'      '}"CountrySubDivision": "CountrySubDivision",{'\n'}
          {'      '}"Department": "Department",{'\n'}
          {'      '}"AddressLine": ["AddressLine 1","AddressLine 2","AddressLine
          3"],
          {'\n'}
          {'      '}"BuildingNumber": "BuildingNumber",{'\n'}
          {'      '}"TownName": "TownName",{'\n'}
          {'      '}"Country": "Country",{'\n'}
          {'      '}"SubDepartment": "SubDepartment",{'\n'}
          {'      '}"AddressType": "AddressType",{'\n'}
          {'      '}"PostCode": "PostCode"{'\n'}
          {'    }'},{'\n'}
          {'    '}"RemittanceInformation": {'{'}
          {'\n'}
          {'      '}"Reference": "ReceiverRef",{'\n'}
          {'      '}"Unstructured": "Shipment fee"{'\n'}
          {'    }'}
          {'\n'}
          {'  }'}
          {'\n'}
          {'}'}
          {'\n'}
          {'}'}
          {'\n'}
          {'\n'}EOF{'\n'}
        </span>
      </code>
    ),
    production: (
      <code>
        curl -X POST {PROD_API_BASE_URL}
        /open-banking/v3.1/payments/domestic-payment-consents{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>{' '}
        <span className="se">\</span>
        {'\n'}-H <span className="s2">"Content-Type: application/json"</span>{' '}
        <span className="se">\</span>
        {'\n'}--data @-{' '}
        <span className="sh">
          &lt;&lt; EOF{'\n'}
          {'\n'}
          {'{'}
          {'\n'}
          "Meta": {'{'}
          {'\n'}
          {'  '}"FirstAvailableDateTime": "2020-02-05T10:11:22+00:00",{'\n'}
          {'  '}"TotalPages": 0{'\n'}
          {'}'},{'\n'}
          "Risk": {'{'}
          {'\n'}
          {'  '}"PaymentContextCode": "EcommerceGoods",{'\n'}
          {'  '}"DeliveryAddress": {'{'}
          {'\n'}
          {'    '}"AddressLine": ["7"],{'\n'}
          {'    '}"StreetName": "Apple Street",{'\n'}
          {'    '}"BuildingNumber": "1",{'\n'}
          {'    '}"PostCode": "E2 7AA",{'\n'}
          {'    '}"TownName": "London",{'\n'}
          {'    '}"Country": "UK"{'\n'}
          {'  '}
          {'}'},{'\n'}
          {'  '}"MerchantCategoryCode": "5967",{'\n'}
          {'  '}"MerchantCustomerIdentification": "1238808123123"{'\n'}
          {'}'},{'\n'}
          "Data": {'{'}
          {'\n'}
          {'  '}"Charges": [{'{'}
          {'\n'}
          {'    '}"Type": ["UK.OBIE.CHAPSOut"],{'\n'}
          {'    '}"Amount": {'{'}
          {'\n'}
          {'      '}"Amount": "2",{'\n'}
          {'      '}"Currency": "CAD"{'\n'}
          {'  '}
          {'  }'},{'\n'}
          {'    '}"ChargeBearer": "2"{'\n'}
          {'  }'}], {'\n'}
          {'  '}"Initiation": {'{'}
          {'\n'}
          {'    '}"SupplementaryData": {'{},'}
          {'\n'}
          {'    '}"LocalInstrument": ["UK.OBIE.BACS"],{'\n'}
          {'    '}"DebtorAccount": {'{'}
          {'\n'}
          {'      '}"SchemeName": "UK.OBIE.IBAN",{'\n'}
          {'      '}"Identification": "GB38REVO00996907112483",{'\n'}
          {'      '}"Name": "Name",{'\n'}
          {'      '}"SecondaryIdentification": "0002"{'\n'}
          {'    }'},{'\n'}
          {'    '}"EndToEndIdentification": "E2E123",{'\n'}
          {'    '}"InstructionIdentification": "ID412",{'\n'}
          {'    '}"CreditorAccount": {'{'}
          {'\n'}
          {'      '}"SchemeName": "UK.OBIE.SortCodeAccountNumber",{'\n'}
          {'      '}"Identification": "11223321325698",{'\n'}
          {'      '}"Name": "Receiver Co."{'\n'}
          {'    }'},{'\n'}
          {'    '}"InstructedAmount": {'{'}
          {'\n'}
          {'      '}"Amount": "55.00",{'\n'}
          {'      '}"Currency": "CAD"{'\n'}
          {'    }'},{'\n'}
          {'    '}"CreditorPostalAddress": {'{'}
          {'\n'}
          {'      '}"StreetName": "StreetName",{'\n'}
          {'      '}"CountrySubDivision": "CountrySubDivision",{'\n'}
          {'      '}"Department": "Department",{'\n'}
          {'      '}"AddressLine": ["AddressLine 1","AddressLine 2","AddressLine
          3"],
          {'\n'}
          {'      '}"BuildingNumber": "BuildingNumber",{'\n'}
          {'      '}"TownName": "TownName",{'\n'}
          {'      '}"Country": "Country",{'\n'}
          {'      '}"SubDepartment": "SubDepartment",{'\n'}
          {'      '}"AddressType": "AddressType",{'\n'}
          {'      '}"PostCode": "PostCode"{'\n'}
          {'    }'},{'\n'}
          {'    '}"RemittanceInformation": {'{'}
          {'\n'}
          {'      '}"Reference": "ReceiverRef",{'\n'}
          {'      '}"Unstructured": "Shipment fee"{'\n'}
          {'    }'}
          {'\n'}
          {'  }'}
          {'\n'}
          {'}'}
          {'\n'}
          {'}'}
          {'\n'}
          {'\n'}EOF{'\n'}
        </span>
      </code>
    ),
  },
  body: (
    <React.Fragment>
      <p>
        Domestic Payments are used for local payments in the local currency of
        the user.
      </p>
      <p>
        In order to simulate different types of consent status updates, first
        decimal digit of <code>Amount</code> field is used. The digits and
        corresponding consent status values are:
      </p>
      <table>
        <thead>
          <tr>
            <th>Digit</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>0</td>
            <td>Pending</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Rejected</td>
          </tr>
          <tr>
            <td>2</td>
            <td>Authorized</td>
          </tr>
          <tr>
            <td>3</td>
            <td>Consumed</td>
          </tr>
          <tr>
            <td>Other</td>
            <td>Authorized</td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  ),
};

const getDomesticPaymentConsent = {
  parentTitle: 'Domestic Payments',
  title: 'Get Domestic Payment Consent',
  envSelector: false,
  isCollapsible: true,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl {SANDBOX_API_BASE_URL}
        /open-banking/v3.1/payments/domestic-payment-consents/
        <span className="o">{'{'}</span>consent_id
        <span className="o">{'}'}</span> <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
    production: (
      <code>
        curl {PROD_API_BASE_URL}
        /open-banking/v3.1/payments/domestic-payment-consents/
        <span className="o">{'{'}</span>consent_id
        <span className="o">{'}'}</span> <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
  },
  response: {
    Data: {
      ConsentId: '14083aa6-3f22-495d-a9e7-75abd118d8e9',
      Initiation: {
        InstructionIdentification: 'ID412',
        EndToEndIdentification: 'E2E123',
        InstructedAmount: {
          Amount: '55.00',
          Currency: 'CAD',
        },
        CreditorAccount: {
          SchemeName: 'UK.OBIE.SortCodeAccountNumber',
          Identification: '11223321325698',
          Name: 'Receiver Co.',
        },
        RemittanceInformation: {
          Reference: 'ReceiverRef',
          Unstructured: 'Shipment fee',
        },
      },
    },
    Risk: {
      PaymentContextCode: 'EcommerceGoods',
      MerchantCategoryCode: '5967',
      MerchantCustomerIdentification: '1238808123123',
      DeliveryAddress: {
        AddressLine: ['7'],
        StreetName: 'Apple Street',
        BuildingNumber: '1',
        PostCode: 'E2 7AA',
        TownName: 'London',
        Country: 'UK',
      },
    },
  },
  body: (
    <p>
      This endpoint returns the consent details associated with the given{' '}
      <code>consent_id</code>.
    </p>
  ),
};

const getFundsConfirmation = {
  parentTitle: 'Domestic Payments',
  title: 'Get Funds Confirmation',
  envSelector: false,
  isCollapsible: false,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl {SANDBOX_API_BASE_URL}
        /open-banking/v3.1/payments/domestic-payment-consents/
        <span className="o">{'{'}</span>consent_id
        <span className="o">{'}'}</span>/funds-confirmation{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
    production: (
      <code>
        curl {PROD_API_BASE_URL}
        /open-banking/v3.1/payments/domestic-payment-consents/
        <span className="o">{'{'}</span>consent_id
        <span className="o">{'}'}</span>/funds-confirmation{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
  },
  response: {
    Data: {
      FundsAvailableResult: {
        FundsAvailableDateTime: '2017-06-05T15:15:23+00:00',
        FundsAvailable: true,
      },
    },
    Links: {
      Self:
        'https://nonprod-preprod.openatb.com/open-banking/v3.1/payments/domestic-payment-consents/14083aa6-3f22-495d-a9e7-75abd118d8e9/funds-confirmation',
    },
    Meta: {},
  },
  body: (
    <p>
      This endpoint lets checking funds for the given consent. This endpoint
      returns successful only if the user has authorized the related consent.
      Otherwise <code>statusCode: 400</code> is returned.
    </p>
  ),
};

const createDomesticPayment = {
  parentTitle: 'Domestic Payments',
  title: 'Create Domestic Payment',
  envSelector: false,
  isCollapsible: true,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl -X POST {SANDBOX_API_BASE_URL}
        /open-banking/v3.1/payments/domestic-payments{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>{' '}
        <span className="se">\</span>
        {'\n'}-H <span className="s2">"Content-Type: application/json"</span>{' '}
        <span className="se">\</span>
        {'\n'}--data @-{' '}
        <span className="sh">
          &lt;&lt; EOF{'\n'}
          {'\n'}
          {'{'}
          {'\n'}
          "Meta": {'{'}
          {'\n'}
          {'  '}"FirstAvailableDateTime": "2020-02-05T10:11:22+00:00",{'\n'}
          {'  '}"TotalPages": 0{'\n'}
          {'}'},{'\n'}
          "Risk": {'{'}
          {'\n'}
          {'  '}"PaymentContextCode": "EcommerceGoods",{'\n'}
          {'  '}"DeliveryAddress": {'{'}
          {'\n'}
          {'    '}"AddressLine": ["7"],{'\n'}
          {'    '}"StreetName": "Apple Street",{'\n'}
          {'    '}"BuildingNumber": "1",{'\n'}
          {'    '}"PostCode": "E2 7AA",{'\n'}
          {'    '}"TownName": "London",{'\n'}
          {'    '}"Country": "UK"{'\n'}
          {'  '}
          {'}'},{'\n'}
          {'  '}"MerchantCategoryCode": "5967",{'\n'}
          {'  '}"MerchantCustomerIdentification": "1238808123123"{'\n'}
          {'}'},{'\n'}
          "Data": {'{'}
          {'\n'}
          {'  '}"ConsentId": "14083aa6-3f22-495d-a9e7-75abd118d8e9",{'\n'}
          {'  '}"Initiation": {'{'}
          {'\n'}
          {'    '}"LocalInstrument": ["UK.OBIE.BACS"],{'\n'}
          {'    '}"DebtorAccount": {'{'}
          {'\n'}
          {'      '}"SchemeName": "UK.OBIE.IBAN",{'\n'}
          {'      '}"Identification": "GB38REVO00996907112483",{'\n'}
          {'      '}"Name": "Name",{'\n'}
          {'      '}"SecondaryIdentification": "0002"{'\n'}
          {'    }'},{'\n'}
          {'    '}"EndToEndIdentification": "E2E123",{'\n'}
          {'    '}"InstructionIdentification": "ID412",{'\n'}
          {'    '}"CreditorAccount": {'{'}
          {'\n'}
          {'      '}"SchemeName": "UK.OBIE.SortCodeAccountNumber",{'\n'}
          {'      '}"Identification": "11223321325698",{'\n'}
          {'      '}"Name": "Receiver Co."{'\n'}
          {'    }'},{'\n'}
          {'    '}"InstructedAmount": {'{'}
          {'\n'}
          {'      '}"Amount": "55.00",{'\n'}
          {'      '}"Currency": "CAD"{'\n'}
          {'    }'},{'\n'}
          {'    '}"RemittanceInformation": {'{'}
          {'\n'}
          {'      '}"Reference": "ReceiverRef",{'\n'}
          {'      '}"Unstructured": "Shipment fee"{'\n'}
          {'    }'}
          {'\n'}
          {'  }'}
          {'\n'}
          {'}'}
          {'\n'}
          {'}'}
          {'\n'}
          {'\n'}EOF{'\n'}
        </span>
      </code>
    ),
    production: (
      <code>
        curl -X POST {PROD_API_BASE_URL}
        /open-banking/v3.1/payments/domestic-payments{' '}
        <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>{' '}
        <span className="se">\</span>
        {'\n'}-H <span className="s2">"Content-Type: application/json"</span>{' '}
        <span className="se">\</span>
        {'\n'}--data @-{' '}
        <span className="sh">
          &lt;&lt; EOF{'\n'}
          {'\n'}
          {'{'}
          {'\n'}
          "Meta": {'{'}
          {'\n'}
          {'  '}"FirstAvailableDateTime": "2020-02-05T10:11:22+00:00",{'\n'}
          {'  '}"TotalPages": 0{'\n'}
          {'}'},{'\n'}
          "Risk": {'{'}
          {'\n'}
          {'  '}"PaymentContextCode": "EcommerceGoods",{'\n'}
          {'  '}"DeliveryAddress": {'{'}
          {'\n'}
          {'    '}"AddressLine": ["7"],{'\n'}
          {'    '}"StreetName": "Apple Street",{'\n'}
          {'    '}"BuildingNumber": "1",{'\n'}
          {'    '}"PostCode": "E2 7AA",{'\n'}
          {'    '}"TownName": "London",{'\n'}
          {'    '}"Country": "UK"{'\n'}
          {'  '}
          {'}'},{'\n'}
          {'  '}"MerchantCategoryCode": "5967",{'\n'}
          {'  '}"MerchantCustomerIdentification": "1238808123123"{'\n'}
          {'}'},{'\n'}
          "Data": {'{'}
          {'\n'}
          {'  '}"ConsentId": "14083aa6-3f22-495d-a9e7-75abd118d8e9",{'\n'}
          {'  '}"Initiation": {'{'}
          {'\n'}
          {'    '}"LocalInstrument": ["UK.OBIE.BACS"],{'\n'}
          {'    '}"DebtorAccount": {'{'}
          {'\n'}
          {'      '}"SchemeName": "UK.OBIE.IBAN",{'\n'}
          {'      '}"Identification": "GB38REVO00996907112483",{'\n'}
          {'      '}"Name": "Name",{'\n'}
          {'      '}"SecondaryIdentification": "0002"{'\n'}
          {'    }'},{'\n'}
          {'    '}"EndToEndIdentification": "E2E123",{'\n'}
          {'    '}"InstructionIdentification": "ID412",{'\n'}
          {'    '}"CreditorAccount": {'{'}
          {'\n'}
          {'      '}"SchemeName": "UK.OBIE.SortCodeAccountNumber",{'\n'}
          {'      '}"Identification": "11223321325698",{'\n'}
          {'      '}"Name": "Receiver Co."{'\n'}
          {'    }'},{'\n'}
          {'    '}"InstructedAmount": {'{'}
          {'\n'}
          {'      '}"Amount": "55.00",{'\n'}
          {'      '}"Currency": "CAD"{'\n'}
          {'    }'},{'\n'}
          {'    '}"RemittanceInformation": {'{'}
          {'\n'}
          {'      '}"Reference": "ReceiverRef",{'\n'}
          {'      '}"Unstructured": "Shipment fee"{'\n'}
          {'    }'}
          {'\n'}
          {'  }'}
          {'\n'}
          {'}'}
          {'\n'}
          {'}'}
          {'\n'}
          {'\n'}EOF{'\n'}
        </span>
      </code>
    ),
  },
  body: (
    <React.Fragment>
      <p>
        <code>/Data/Initiation</code> and <code>/Data/Risk</code> sections of
        the request must be exact match for the related consent passed in{' '}
        <code>ConsentId</code>.
      </p>
      <p>
        In order to simulate different types of payment status updates, second
        decimal digit of <code>Amount</code> field is used. The digits and
        corresponding payment status values are:
      </p>
      <table>
        <thead>
          <tr>
            <th>Digit</th>
            <th>Status</th>
          </tr>
        </thead>
        <tbody>
          <tr>
            <td>0</td>
            <td>Pending</td>
          </tr>
          <tr>
            <td>1</td>
            <td>Rejected</td>
          </tr>
          <tr>
            <td>2</td>
            <td>AcceptedSettlementInProcess</td>
          </tr>
          <tr>
            <td>3</td>
            <td>AcceptedSettlementCompleted</td>
          </tr>
          <tr>
            <td>Other</td>
            <td>AcceptedSettlementCompleted</td>
          </tr>
        </tbody>
      </table>
    </React.Fragment>
  ),
};

const getDomesticPayment = {
  parentTitle: 'Domestic Payments',
  title: 'Get Domestic Payment',
  envSelector: false,
  isCollapsible: true,
  isFeedbackRequired: true,
  request: {
    sandbox: (
      <code>
        curl {SANDBOX_API_BASE_URL}
        /open-banking/v3.1/payments/domestic-payments/
        <span className="o">{'{'}</span>payment_id
        <span className="o">{'}'}</span> <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
    production: (
      <code>
        curl {PROD_API_BASE_URL}
        /open-banking/v3.1/payments/domestic-payments/
        <span className="o">{'{'}</span>payment_id
        <span className="o">{'}'}</span> <span className="se">\</span>
        {'\n'}-H{' '}
        <span className="s2">
          "Authorization: Bearer &lt;your access token&gt;"
        </span>
      </code>
    ),
  },
  response: {
    Data: {
      DomesticPaymentId: 'a51bafa2-1b9f-466e-ae30-168672f0661c',
      Status: 'AcceptedSettlementInProcess',
      CreationDateTime: '2017-06-05T15:15:22+00:00',
      StatusUpdateDateTime: '2017-06-05T15:15:13+00:00',
      ConsentId: '14083aa6-3f22-495d-a9e7-75abd118d8e9',
      Initiation: {
        InstructionIdentification: 'ID412',
        EndToEndIdentification: 'E2E123',
        InstructedAmount: {
          Amount: '55.00',
          Currency: 'CAD',
        },
        CreditorAccount: {
          SchemeName: 'UK.OBIE.SortCodeAccountNumber',
          Identification: '11223321325698',
          Name: 'Receiver Co.',
        },
        RemittanceInformation: {
          Reference: 'ReceiverRef',
          Unstructured: 'Shipment fee',
        },
      },
    },
    Risk: {
      PaymentContextCode: 'EcommerceGoods',
      MerchantCategoryCode: '5967',
      MerchantCustomerIdentification: '1238808123123',
      DeliveryAddress: {
        AddressLine: ['7'],
        StreetName: 'Apple Street',
        BuildingNumber: '1',
        PostCode: 'E2 7AA',
        TownName: 'London',
        Country: 'UK',
      },
    },
  },
  body: <p>This endpoint returns the state of a payment.</p>,
};

const buildGetATMsSection = (environment: string, changeEnvironment: any) => {
  return (
    <DocSection
      data={getATMs}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildGetBranchesSection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={getBranches}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildCreateCounterpartySection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={createCounterparty}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildCreateCounterpartyTransactionSection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={createCounterpartyTransaction}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildGetCounterpartySection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={getCounterparty}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildGetCounterpartiesSection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={getCounterparties}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildGetExchangeRatesSection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={getExchangeRates}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildExchangeCurrenySection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={exchangeCurrency}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildCancelRevokeConsentSection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={cancelRevokeConsent}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildGetConsentSection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={getConsent}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildCreateConsentSection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={createConsent}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildCreateAccountTransactionSection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={createAccountTransaction}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildGetAccountsSection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={getAccounts}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildGetAccountDetailsSection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={getAccountDetails}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildGetAccountBalancesSection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={getAccountBalances}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildGetAccountTransactionsSection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={getAccountTransactions}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildGetAccountBeneficiariesSection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={getAccountBeneficiaries}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildGetAccountDirectDebitsSection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={getAccountDirectDebits}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildCreateDomesticPaymentConsentSection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={createDomesticPaymentConsent}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildGetDomesticPaymentConsentSection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={getDomesticPaymentConsent}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildGetFundsConfirmationSection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={getFundsConfirmation}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildCreateDomesticPaymentSection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={createDomesticPayment}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

const buildGetDomesticPaymentSection = (
  environment: string,
  changeEnvironment: any,
) => {
  return (
    <DocSection
      data={getDomesticPayment}
      environment={environment}
      changeEnvironment={changeEnvironment}
    />
  );
};

export {
  buildGetATMsSection,
  buildGetConsentSection,
  buildGetBranchesSection,
  buildGetAccountsSection,
  buildCreateConsentSection,
  buildExchangeCurrenySection,
  buildGetCounterpartySection,
  buildGetExchangeRatesSection,
  buildGetAccountDetailsSection,
  buildGetCounterpartiesSection,
  buildGetDomesticPaymentSection,
  buildCreateCounterpartySection,
  buildGetAccountBalancesSection,
  buildCancelRevokeConsentSection,
  buildGetFundsConfirmationSection,
  buildCreateDomesticPaymentSection,
  buildGetAccountDirectDebitsSection,
  buildGetAccountTransactionsSection,
  buildGetAccountBeneficiariesSection,
  buildCreateAccountTransactionSection,
  buildGetDomesticPaymentConsentSection,
  buildCreateDomesticPaymentConsentSection,
  buildCreateCounterpartyTransactionSection,
};
