import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { userActions } from '../redux/userSlice';
import { NewApplication } from '../components/screens/Application/NewApplication';

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setUserData: userActions.setData,
    },
    dispatch,
  );
}

export const NewApplicationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(NewApplication);
