import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { userActions } from '../redux/userSlice';
import { EditApplication } from '../components/screens/Application/EditApplication';

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setUserData: userActions.setData,
    },
    dispatch,
  );
}

export const EditApplicationContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(EditApplication);
