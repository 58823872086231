import React, { useState } from 'react';
import { FormProps } from 'antd/lib/form';
import { Form, Input, Button, Select, Modal, Spin } from 'antd';
import Text from 'antd/lib/typography/Text';
import styled from 'styled-components';

import { Application } from '../../../api/types';
import ApplicationLogo from './ApplicationLogo';
import ApplicationCredentials from './ApplicationCredentials';
import {
  OkButton,
  getCompressedBase64,
  formItemLayout,
  tailFormItemLayout,
} from './ApplicationStyleUtils';

const { TextArea } = Input;

const CancelButton = styled(Button)`
  float: right;
  background-color: lightGrey;
  margin-right: 1rem;
`;

const EditForm = styled(Form)`
  width: 70%;
`;

const DeleteButton = styled(Button)`
  float: left;
  background-color: lightGrey;
`;

const URL_MESSAGE =
  'This field must be a valid url starting with http:// or https://';

export interface EditApplicationProps extends FormProps {
  currentApplication: Application;
  editApplication: any;
  removeApplication: any;
  addNewKeyPairs: any;
  removeKeyPair: any;
  loading: boolean;
}

const EditApplicationForm = (props: EditApplicationProps) => {
  const [form] = Form.useForm();
  const [modalVisible, setModalVisible] = useState(false);
  const [logoLoading, setLogoLoading] = useState(false);
  const [appLogo, setAppLogo] = useState(props.currentApplication.appLogo);

  const handleLogoChange = (info: any) => {
    if (info.file.status === 'uploading') {
      setLogoLoading(true);
      return;
    }
    if (info.file.status === 'done') {
      getCompressedBase64(info.file.originFileObj, (imageUrl: any) => {
        setLogoLoading(false);
        setAppLogo(imageUrl);
        return;
      });
    }
  };

  const resetEditableFields = () => {
    // The img tag in the app logo requires additional step
    setAppLogo(props.currentApplication.appLogo);
    form.resetFields();
  };

  const handleUpdate = async (values: any) => {
    try {
      values.appName = props.currentApplication.appName;
      // App logo coming from 'values' contains the actual file object, not base64
      values.appLogo = appLogo;
      await props.editApplication(values);
    } catch (error) {
      resetEditableFields();
    }
  };

  return (
    <Spin spinning={props.loading}>
      <EditForm
        form={form}
        {...formItemLayout}
        onFinish={handleUpdate}
        initialValues={{
          appType: props.currentApplication.appType,
          redirectUrl: props.currentApplication.redirectUrl,
          appDescription: props.currentApplication.appDescription,
          appLogo: props.currentApplication.appLogo,
          homepageLink: props.currentApplication.homepageLink,
          privacyPolicyLink: props.currentApplication.privacyPolicyLink,
          termsOfServiceLink: props.currentApplication.termsOfServiceLink,
        }}
        labelAlign="left"
      >
        <Form.Item label="Application Name">
          <Text>{props.currentApplication.displayName}</Text>
        </Form.Item>
        <Form.Item label="E-mail">
          <Text>{props.currentApplication.devEmail}</Text>
        </Form.Item>
        <Form.Item label="Environment">
          <Text>{props.currentApplication.environment}</Text>
        </Form.Item>
        <Form.Item label="Sandbox username 1">
          <Text>{props.currentApplication.sandbox1username}</Text>
        </Form.Item>
        <Form.Item label="Sandbox password 1">
          <Text>{props.currentApplication.sandbox1password}</Text>
        </Form.Item>
        <Form.Item label="Sandbox username 2">
          <Text>{props.currentApplication.sandbox2username}</Text>
        </Form.Item>
        <Form.Item label="Sandbox password 2">
          <Text>{props.currentApplication.sandbox2password}</Text>
        </Form.Item>
        <Form.Item label="App Key/Secret">
          <ApplicationCredentials
            application={props.currentApplication}
            addNewKeyPairs={props.addNewKeyPairs}
            removeKeyPair={props.removeKeyPair}
          />
        </Form.Item>
        {/* {editableSection} */}
        <Form.Item label="Application Type" name="appType">
          <Select>
            <Select.Option value="Web">Web</Select.Option>
            <Select.Option value="Mobile">Mobile</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item
          label="Redirect URL"
          name="redirectUrl"
          rules={[
            {
              type: 'url',
              message: URL_MESSAGE,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Homepage Link"
          name="homepageLink"
          rules={[
            {
              type: 'url',
              message: URL_MESSAGE,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Privacy Policy Link"
          name="privacyPolicyLink"
          rules={[
            {
              type: 'url',
              message: URL_MESSAGE,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Terms of Service Link"
          name="termsOfServiceLink"
          rules={[
            {
              type: 'url',
              message: URL_MESSAGE,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Description" name="appDescription">
          <TextArea rows={3} maxLength={140} />
        </Form.Item>
        <ApplicationLogo
          appLogo={appLogo}
          logoLoading={logoLoading}
          handleLogoChange={handleLogoChange}
        />

        <Form.Item {...tailFormItemLayout}>
          <div>
            <DeleteButton onClick={() => setModalVisible(true)}>
              Delete
            </DeleteButton>
            <OkButton type="primary" htmlType="submit">
              OK
            </OkButton>
            <CancelButton
              onClick={() => {
                resetEditableFields();
              }}
            >
              Cancel
            </CancelButton>
          </div>
          <Modal
            title="Confirmation"
            centered={true}
            visible={modalVisible}
            onOk={() => {
              setModalVisible(false);
              props.removeApplication(props.currentApplication.appName);
            }}
            onCancel={() => setModalVisible(false)}
          >
            Delete application?
          </Modal>
        </Form.Item>
      </EditForm>
    </Spin>
  );
};

export default EditApplicationForm;
