import React from 'react';
import { Card, Spin } from 'antd';
import styled from 'styled-components';
import { ApplicationProps } from './ApplicationList';

const AppCard = styled(Card)`
  margin-bottom: 10px;
`;

const ContentAppCardGrid = styled(Card.Grid)`
  width: 60%;
  box-shadow: none;
`;

const ImageAppCardGrid = styled(Card.Grid)`
  width: 40%;
  box-shadow: none;
`;

const AppImage = styled.img`
  width: 100%;
  object-fit: cover;
`;

const MobileApplicationTable = (props: ApplicationProps) => {
  const applicationCards: any = [];
  props.applications.forEach((application) => {
    const card = (
      <AppCard bodyStyle={{ padding: '0' }} key={application.appName}>
        <ContentAppCardGrid hoverable={false}>
          <p>
            <strong>Name: </strong>
            {application.displayName}
          </p>
          <p>
            <strong>Type: </strong>
            {application.appType}
          </p>
          <p>
            <strong>URL: </strong>
            {application.redirectUrl}
          </p>
          <p>
            <strong>Description: </strong>
            {application.appDescription}
          </p>
          <p>
            <button
              type="button"
              className="link-button highlighted-link"
              onClick={() => props.onRowClick(application)}
            >
              Edit
            </button>
          </p>
        </ContentAppCardGrid>
        {application.appLogo && (
          <ImageAppCardGrid hoverable={false}>
            <AppImage src={application.appLogo} />
          </ImageAppCardGrid>
        )}
      </AppCard>
    );
    applicationCards.push(card);
  });
  return (
    <Spin spinning={props.loading}>
      <div>{applicationCards}</div>
    </Spin>
  );
};

export default MobileApplicationTable;
