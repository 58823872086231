import React from 'react';
import { Button, Col, Form, Input, Row } from 'antd';
import styled from 'styled-components';
import axios from 'axios';
import TextArea from 'antd/lib/input/TextArea';

const NewsletterContainer = styled.div``;

const RoundButton = styled(Button)`
  color: white;
  font-size: 1em;
  font-family: 'Open Sans';
  border: 2px solid white;
  border-radius: 50px;
  margin-top: 0;
  margin-bottom: 3rem;
  background-color: transparent;
  font-display: swap;
  float: right;

  &:hover {
    color: #dcd028;
    border: 2px solid #dcd028;
    background-color: transparent;
  }
`;

const CheckboxLabel = styled.label`
  color: white;
  margin-left: 5px;
`;

const HiddenFormItem = styled(Form.Item)`
  display: none;
`;

export function Newsletter() {
  const getHeader = () => <h1 className="footer-pagetitle">Stay up to Date</h1>;
  const [form] = Form.useForm();

  const subscribe = (event: any) => {
    axios.post(
      'https://atbventures.us4.list-manage.com/subscribe/post',
      event,
      {
        headers: {
          'Content-Type': 'application/json',
        },
      },
    );
    form.resetFields();
  };

  return (
    <NewsletterContainer>
      {getHeader()}
      <Form
        form={form}
        size={'middle'}
        labelAlign="left"
        onFinish={subscribe}
        initialValues={{
          u: 'ba96a1c6f9fa269660f4104b4',
          id: '24add14d5c',
          b_comment: '',
          b_email: '',
          b_name: '',
        }}
      >
        <Form.Item name="name">
          <Input
            className="newsletter-field form-control mailchimp-footer-input"
            type="text"
            placeholder="Your Name *"
            required
          />
        </Form.Item>
        <Form.Item name="email">
          <Input
            className="newsletter-field form-control mailchimp-footer-input"
            type="email"
            placeholder="Email *"
            required
          />
        </Form.Item>
        <Row>
          <Col span={18}>
            <Form.Item>
              <Input
                type="checkbox"
                className="newsletter-checkbox form-control"
                id="terms-agree"
                required
              />
              <CheckboxLabel>
                I agree to the&nbsp;
                <a
                  href={`/dev/privacypolicy`}
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  privacy policy
                </a>
                .
              </CheckboxLabel>
            </Form.Item>
          </Col>
          <Col>
            <RoundButton htmlType="submit" name="subscribe">
              Sign up
            </RoundButton>
          </Col>
        </Row>
        <HiddenFormItem name="u">
          <Input type="hidden" />
        </HiddenFormItem>
        <HiddenFormItem name="id">
          <Input type="hidden" />
        </HiddenFormItem>
        {/* Anti-spam fields for MailChimp */}
        <div
          style={{ position: 'absolute', top: '-555000px' }}
          aria-hidden="true"
          aria-label="Please leave the following three fields empty"
        >
          <Form.Item name="b_name" label="Name">
            <Input
              type="text"
              placeholder="Frank"
              id="b_name"
              onChange={(e) => {}}
            />
          </Form.Item>

          <Form.Item label="Email" name="b_email">
            <Input
              type="email"
              value=""
              placeholder="email@gmail.com"
              id="b_email"
              onChange={(e) => {}}
            />
          </Form.Item>
          <Form.Item label="Comment" name="b_comment">
            <TextArea placeholder="Comment" id="b_comment" />
          </Form.Item>
        </div>
      </Form>
    </NewsletterContainer>
  );
}
