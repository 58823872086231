import React from 'react';
import { normalizeString } from '../components/SubNavBar';
import ResponseSection from './ResponseSection';
import RequestSection from './RequestSection';
import { logEvent } from '../components/utils/analytics';
import styled from 'styled-components';

interface DocSectionProps {
  data: any;
  environment: string;
  changeEnvironment: any;
}

const FeedbackButton = styled.button`
  margin-left: 10px;
`;

const DocSection = ({
  data,
  environment,
  changeEnvironment,
}: DocSectionProps) => {
  const feedbackRef: any = React.createRef();
  const buildSectionTitle = () => {
    const headerId = `open-banking-api-${normalizeString(
      data.parentTitle,
    )}-${normalizeString(data.title)}`;
    return (
      <h3 id={headerId}>
        <a href={`#${headerId}`} className="header-link">
          <i />
        </a>
        {data.title}
      </h3>
    );
  };

  const buildRequest = () => {
    if (!data.request) return;
    return (
      <RequestSection
        data={data}
        environment={environment}
        changeEnvironment={changeEnvironment}
      />
    );
  };

  const buildResponse = () => {
    if (!data.response) return;
    return (
      <ResponseSection
        response={data.response}
        isCollapsible={data.isCollapsible}
      />
    );
  };

  const buildFeedbackSection = () => {
    if (!data.isFeedbackRequired) return;
    return (
      <div className="feedback">
        <div className="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Relative-sc-16pg6n7-3 gZdeAQ">
          <div className="Box-sc-1xuamqz-0 Position-sc-16pg6n7-0 Position__Absolute-sc-16pg6n7-2 frnnzh">
            <div
              ref={feedbackRef}
              className="Box-sc-1xuamqz-0 Text__TextBox-ezbrsv-1 dvMOMu"
            >
              Was this section helpful?
              <FeedbackButton
                type="button"
                className="link-button highlighted-link"
                onClick={(e) => {
                  logEvent({
                    prefix: 'sandbox',
                    category: data.parentTitle as string,
                    action: 'sandbox-doc-feedback-click',
                    label: data.title,
                    value: 1,
                  });
                  feedbackRef.current.innerHTML = 'Thanks for your feedback!';
                }}
              >
                Yes
              </FeedbackButton>
              <FeedbackButton
                type="button"
                className="link-button highlighted-link"
                onClick={(e) => {
                  logEvent({
                    prefix: 'sandbox',
                    category: data.parentTitle as string,
                    action: 'sandbox-doc-feedback-click',
                    label: data.title,
                    value: 0,
                  });
                  feedbackRef.current.innerHTML = 'Thanks for your feedback!';
                }}
              >
                No
              </FeedbackButton>
            </div>
          </div>
        </div>
      </div>
    );
  };

  return (
    <React.Fragment>
      {buildSectionTitle()}
      {buildRequest()}
      {buildResponse()}
      {data.body}
      {buildFeedbackSection()}
    </React.Fragment>
  );
};

export default DocSection;
