import React from 'react';
import { Layout, Menu, Dropdown } from 'antd';
import styled from 'styled-components';
import device from '../components/utils/devices';
import { NavLink, Link } from 'react-router-dom';
import { CaretDownFilled } from '@ant-design/icons';
import { config } from '../config';

const { cms } = config;

const StyledHeader = styled.div`
  background-color: #002761;
  position: fixed;
  width: 100%;
  z-index: 49;
  display: flex;
  align-items: center;
`;

const StyledLogo = styled.div`
  background-size: contain;
  padding: 24px 0 24px 32px;

  img {
    width: 180px;
  }

  &:hover {
    color: #fff;
  }

  @media ${device.tablet} {
    padding: 24px 0 24px 64px;
  }
`;

const StyledMenu = styled.div`
  margin-right: 4rem;
  width: 0;
  @media ${device.tablet} {
    flex-grow: 1;
    display: flex;
    justify-content: center;
  }
`;

const StyledProfile = styled.div`
  margin-top: 1rem;
  margin-right: 4rem;
  display: flex;
  justify-content: right;

  p {
    display: block;
    color: #fff;
    padding: 0.5rem 1rem;
    font-size: 1em;
    font-family: 'Open Sans';
    border: 2px solid white;
    border-radius: 50px;
    white-space: nowrap;

    &:hover {
      color: #dcd028;
      border-color: #dcd028;
    }
    &.active {
      color: #dcd028;
      border-color: #dcd028;
    }
  }
`;

const StyledMenuItem = styled(Menu.Item)`
  font-size: 1rem;
  border: 0;

  &.ant-menu-item-selected {
    color: #dcd028 !important;
    background-color: transparent !important;
  }

  &.ant-menu-item-active {
    color: #dcd028 !important;
    border-color: #dcd028 !important;
  }

  @media ${device.tablet} {
  }
`;

const StyledProfileMenu = styled(Menu)`
  margin-top: 10px;
  font-size: 1rem;
  font-family: Open Sans;
  background-color: #white;
  border: none;
  box-shadow: none;
`;

const ProfileMenuItem = styled(Menu.Item)`
  text-align: right;
  margin-right: 0;
  width: 100%;
  &:hover {
    background-color: #002761;
  }
  & > button, a {
    color: #002761;
    font-size: 0.8rem;
  }

  &>button: hover, a: hover {
    color: white;
  }
`;

const EmailMenuItem = styled(Menu.Item)`
  text-align: left;
  font-style: italic;
  font-size: 0.8rem;
  &:hover {
    background-color: transparent;
    cursor: text;
  }
  color: #002761;
`;

export interface NavBarItem {
  key: string;
  name: string;
  onClick: () => void;
  selected?: boolean;
}

export interface NavBarProps {
  items: NavBarItem[];
  email: string;
  logout: any;
}

export function NavBar({ items, email, logout }: NavBarProps) {
  const menuItems = items.map((item) => (
    <StyledMenuItem
      onClick={item.onClick}
      key={item.key}
      className={'undecorated-menu-item'}
    >
      {item.name}
    </StyledMenuItem>
  ));

  const selectedItems = items
    .map((item) => {
      return item.selected && item.key;
    })
    .filter(Boolean) as string[];

  const profileMenu = (
    <StyledProfileMenu>
      <EmailMenuItem>{email}</EmailMenuItem>
      <ProfileMenuItem>
        <NavLink to="/home/profile">My Profile</NavLink>
      </ProfileMenuItem>
      <ProfileMenuItem>
        <a target="_blank" rel="noopener noreferrer" href="https://openatb.com">
          Open ATB
        </a>
      </ProfileMenuItem>
      <ProfileMenuItem>
        <button
          type="button"
          className="link-button"
          onClick={() => logout({ returnTo: cms.url })}
        >
          Sign out
        </button>
      </ProfileMenuItem>
    </StyledProfileMenu>
  );

  const dropDownMenu = (
    <Dropdown overlay={profileMenu} trigger={['click']}>
      <StyledProfile>
        <p>
          Profile <CaretDownFilled />
        </p>
      </StyledProfile>
    </Dropdown>
  );

  return (
    <Layout>
      <StyledHeader>
        <StyledLogo>
          <Link to="/home/getting-started">
            <img src="/logo.svg" alt="Open ATB" />
          </Link>
        </StyledLogo>
        <StyledMenu>
          <Menu theme="dark" mode="horizontal" selectedKeys={selectedItems}>
            {menuItems}
          </Menu>
        </StyledMenu>
        {dropDownMenu}
      </StyledHeader>
    </Layout>
  );
}
