import React from 'react';

interface RequestSectionProps {
  data: any;
  environment: string;
  changeEnvironment: any;
}

const RequestSection = ({
  data,
  environment,
  changeEnvironment,
}: RequestSectionProps) => {
  const buildRequestTitle = () => {
    return (
      <blockquote>
        <div>
          <p>{data.title}</p>
        </div>

        {data.envSelector && (
          <div className="env-selector">
            <select value={environment} onChange={changeEnvironment}>
              <option value="sandbox">Sandbox</option>
              <option value="production">Production</option>
            </select>
          </div>
        )}
        {/* <div>
          <a className="expand-button" onClick={() => {}}>
            <CopyFilled />
          </a>
        </div> */}
      </blockquote>
    );
  };

  const buildRequestBody = () => {
    return (
      <pre className="highlight shell tab-shell--sandbox">
        {data.request[environment]}
      </pre>
    );
  };

  return (
    <React.Fragment>
      {buildRequestTitle()}
      {buildRequestBody()}
    </React.Fragment>
  );
};

export default RequestSection;
