import React, { useState, useEffect } from 'react';
import { Table, Input } from 'antd';
import styled from 'styled-components';

import { Dataset } from '../api/DataWorld';
import { DatasetCard } from './DatasetCard';
import { normalizeString } from './SubNavBar';
import { Application } from '../api/types';

const SearchBar = styled(Input.Search)`
  width: 50%;
`;

interface DatasetTableProps {
  datasets: Dataset[];
  jwt: string;
  setNavigationDatasets: any;
  applications: Application[];
}

const DatasetTable = ({
  datasets,
  jwt,
  setNavigationDatasets,
  applications,
}: DatasetTableProps) => {
  const columns = [
    {
      title: '',
      key: 'id',
      render: (text: string, dataset: Dataset) => (
        <div key={dataset.id}>
          <h2 id={'open-data-catalogue-' + normalizeString(dataset.title)}>
            {dataset.title}
          </h2>
          <p>{dataset.summary}</p>
          <DatasetCard
            name={'Files'}
            files={dataset.files}
            owner={dataset.owner}
            id={dataset.id}
            jwt={jwt}
            applications={applications}
          />
        </div>
      ),
    },
  ];
  const pageSize = 10;

  const [activeList, setActiveList] = useState(datasets);
  const [currentPage, setCurrentPage] = useState(1);

  useEffect(() => {
    setNavigationDatasets(
      activeList.slice((currentPage - 1) * pageSize, currentPage * pageSize),
    );
  }, [currentPage, activeList, setNavigationDatasets]);

  const search = (value: string) => {
    const textToSearch = value.toLowerCase();

    const filterTable = datasets.filter((dataset) => {
      return (
        dataset.title.toLowerCase().includes(textToSearch) ||
        dataset.summary.toLowerCase().includes(textToSearch)
      );
    });
    setActiveList(filterTable);

    var page = currentPage;
    if (Math.ceil(filterTable.length / pageSize) < currentPage) page = 1;
    setCurrentPage(page);
  };

  return (
    <div>
      <SearchBar placeholder="Search by..." enterButton onSearch={search} />
      <Table
        columns={columns}
        dataSource={activeList}
        loading={false}
        pagination={{ pageSize, onChange: setCurrentPage }}
        rowKey="id"
        className="dataset-table"
        style={{ width: '50%' }} //Creating a styled table results in compilation error
      />
    </div>
  );
};

export default DatasetTable;
