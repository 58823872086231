import React from 'react';
import styled from 'styled-components';
import { Form, Upload, message } from 'antd';
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons';

export interface AppLogoProps {
  appLogo: string;
  logoLoading: boolean;
  handleLogoChange: any;
}

// Needed for 'uploading' mechanism of antd
// As we do not directly upload the file somewhere,
// antd needs to be returned success to terminate loading
const dummyRequest = (params: any) => {
  setTimeout(() => {
    params.onSuccess('ok');
  }, 0);
};

const LogoFormItem = styled(Form.Item)`
  margin-bottom: 0px;
`;

const AppImage = styled.img`
  width: 100%;
`;

const fileTypes = ['image/jpeg', 'image/png', 'image/gif'];

const ApplicationLogo = (props: AppLogoProps) => {
  const beforeUpload = (file: any) => {
    const isTypeAcceptable = fileTypes.includes(file.type);
    if (!isTypeAcceptable) {
      message.error('You can only upload JPG/PNG/GIF file!');
    }
    const isLt100kB = file.size / 1024 < 100;
    if (!isLt100kB) {
      message.error('Image must smaller than 100kB!');
    }
    return isTypeAcceptable && isLt100kB;
  };

  return (
    <LogoFormItem
      label="Application Logo"
      name="appLogo"
      labelAlign="left"
      extra="Max. file size: 100 kB"
      valuePropName="appLogo"
    >
      <Upload
        name="appLogo"
        listType="picture-card"
        className="avatar-uploader"
        showUploadList={false}
        beforeUpload={beforeUpload}
        accept=".jpg,.png,.gif"
        customRequest={dummyRequest} //Required for antd
        onChange={props.handleLogoChange}
      >
        {props.appLogo ? (
          <AppImage src={props.appLogo} />
        ) : (
          <div>
            {props.logoLoading ? <LoadingOutlined /> : <PlusOutlined />}
            <div className="ant-upload-text">Upload</div>
          </div>
        )}
      </Upload>
    </LogoFormItem>
  );
};

export default ApplicationLogo;
