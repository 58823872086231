import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { userActions } from '../redux/userSlice';
import { Applications } from '../components/screens/Applications';
import { RootState } from '../App';

function mapStateToProps(state: RootState) {
  return { emailVerified: state.user.emailVerified };
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setUserData: userActions.setData,
    },
    dispatch,
  );
}

export const ApplicationsContainer = connect(
  mapStateToProps,
  mapDispatchToProps,
)(Applications);
