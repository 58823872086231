import styled from 'styled-components';
import device from '../components/utils/devices';
import { message } from 'antd';

const headerStyle = {};

const SectionTitle = styled.h3``;

const StyledContainer = styled.div`
  margin: 0;
  padding: 5rem 1rem 1rem 1rem;

  @media ${device.tablet} {
    padding: 10rem 6rem;
  }
`;

const StyledContentContainer = styled.div`
  margin: 0;
  padding: 5rem 1rem 1rem 1rem;

  @media ${device.tablet} {
    padding: 10rem 25rem 1rem 25rem;
  }
`;

const configureMessageContainer = () => {
  message.config({
    getContainer: () => document.getElementById('content') || document.body,
    top: 120,
    maxCount: 1,
  });
};

export {
  headerStyle,
  SectionTitle,
  StyledContainer,
  StyledContentContainer,
  configureMessageContainer,
};
