import React, { useEffect, useState, useCallback } from 'react';
import { useParams } from 'react-router-dom';
import { useAuth } from 'use-auth0-hooks';
import { config } from '../../../config';
import { message } from 'antd';
import { History } from 'history';

import {
  updateApplication,
  deleteApplication,
  retrieveApplicationDetails,
  getErrorMessage,
  createEmptyApplication,
  generateNewKeyPairs,
  deleteKeyPair,
} from '../../../api/Application';
import EditApplicationForm from './EditApplicationForm';
import { CenteredSpinner } from '../../CenteredSpinner';
import {
  SectionTitle,
  StyledContentContainer,
  configureMessageContainer,
} from '../../StyleUtility';
import { Content } from '../../Content';
import { Application } from '../../../api/types';

export interface EditAppProps {
  history: History;
}

const { auth0 } = config;

export function EditApplication({ history }: EditAppProps) {
  const { accessToken } = useAuth({
    audience: auth0.audience,
    scope: auth0.scope,
  });
  const { appName } = useParams();
  const [application, setApplication] = useState(createEmptyApplication());
  const [loading, setLoading] = useState(true);
  const [formLoading, setFormLoading] = useState(false);

  configureMessageContainer();

  const getApplicationDetails = useCallback(async () => {
    try {
      const response = await retrieveApplicationDetails(
        accessToken!,
        appName || '',
      );
      setApplication(response);
      setLoading(false);
    } catch (error) {
      setLoading(false);
      message.error(getErrorMessage(error));
      throw error;
    }
  }, [appName, accessToken]);

  useEffect(() => {
    getApplicationDetails();
  }, [getApplicationDetails, loading]);

  const editApplication = async (application: Application) => {
    setFormLoading(true);
    try {
      const responseApp = await updateApplication(accessToken!, application);
      setApplication(responseApp);
      setFormLoading(false);
      message.success('Application updated successfully');
    } catch (error) {
      setFormLoading(false);
      message.error(getErrorMessage(error));
      throw error;
    }
  };

  const addNewKeyPairs = async () => {
    setFormLoading(true);
    try {
      const result = await generateNewKeyPairs(
        accessToken!,
        application.appName,
      );
      setApplication(result);
      setFormLoading(false);
      message.success('New key pair added successfully');
    } catch (error) {
      setFormLoading(false);
      message.error(getErrorMessage(error));
      throw error;
    }
  };

  const removeKeyPair = async (appName: string, appKey: string) => {
    setFormLoading(true);
    try {
      await deleteKeyPair(accessToken!, appName, appKey);
      await getApplicationDetails();
      setFormLoading(false);
      message.success('Key pair deleted successfully');
    } catch (error) {
      setFormLoading(false);
      message.error(getErrorMessage(error));
      throw error;
    }
  };

  const removeApplication = async (appName: string) => {
    setFormLoading(true);
    try {
      await deleteApplication(accessToken!, appName);
      setFormLoading(false);
      message.success('Application deleted successfully');
      history.push('/home/applications');
    } catch (error) {
      setFormLoading(false);
      message.error(getErrorMessage(error));
      throw error;
    }
  };

  if (loading) {
    return <CenteredSpinner />;
  }

  return (
    <StyledContentContainer>
      <Content>
        <SectionTitle>Application details</SectionTitle>
        <EditApplicationForm
          currentApplication={application}
          editApplication={editApplication}
          removeApplication={removeApplication}
          loading={formLoading}
          addNewKeyPairs={addNewKeyPairs}
          removeKeyPair={removeKeyPair}
        ></EditApplicationForm>
      </Content>
    </StyledContentContainer>
  );
}
