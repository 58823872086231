import React from 'react'
import { Spin } from 'antd'
import styled from 'styled-components'

const CenteredContainer = styled.div`
  display: flex;
  flex: 1;
  justify-content: center;
  align-items: center;
  position: absolute;
  top: 50%;
  left: 50%;
`

export function CenteredSpinner() {
  return (
    <CenteredContainer>
      <Spin size="large" />
    </CenteredContainer>
  )
}
