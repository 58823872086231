import React, { Component } from 'react';
import { Upload, Button, Form, Input, message } from 'antd';
import styled, { CSSProperties } from 'styled-components';
import { Link } from 'react-router-dom';

import { createDataset } from '../api/DataWorld';
import { getErrorMessage } from '../api/Application';

interface UploadFilesProps {
  jwt: string;
  emailVerified: boolean; // needed for form styling/accessibility
}

interface UploadFilesState {
  fileList: any[];
  uploading: boolean;
}

const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const DataForm = styled(Form)`
  width: 50%;
`;

const ButtonContainer = styled.div`
  width: auto;
`;

const InfoContainer = styled.div`
  display: inline-block;
  margin-left: 20px;
`;

export class UploadFiles extends Component<UploadFilesProps, UploadFilesState> {
  state = {
    fileList: [],
    uploading: false,
  };

  formRef: any = React.createRef();
  formItemStyle: CSSProperties = !this.props.emailVerified
    ? { pointerEvents: 'none', opacity: '0.4' }
    : {};

  handleUpload = async (datasetName: string, description: string) => {
    const { fileList } = this.state;
    const formData = new FormData();
    fileList.forEach((file) => {
      formData.append('files[]', file);
    });

    this.setState({
      uploading: true,
    });

    try {
      await createDataset(
        this.props.jwt,
        'atbventures',
        datasetName,
        description,
        fileList,
      );
      this.setState({
        uploading: false,
        fileList: [],
      });

      message.success('Dataset uploaded successfully!');
      this.formRef.current.resetFields();
    } catch (error) {
      message.error(getErrorMessage(error));
      this.setState({
        uploading: false,
        fileList: fileList,
      });
    }
  };

  render() {
    const { uploading, fileList } = this.state;
    const props = {
      onRemove: (file: any) => {
        this.setState((state: any) => {
          const index = state.fileList.indexOf(file);
          const newFileList = state.fileList.slice();
          newFileList.splice(index, 1);
          return {
            fileList: newFileList,
          };
        });
      },
      beforeUpload: (file: any) => {
        this.setState((state: any) => ({
          fileList: [...state.fileList, file],
        }));
        return false;
      },
      fileList,
    };

    const onFinish = (values: any) => {
      this.handleUpload(values.datasetName, values.description);
    };

    const onFinishFailed = (errorInfo: any) => {
      // User did not complete the form correctly
      console.log('Failed:', errorInfo);
    };

    return (
      <React.Fragment>
        <h2 id="open-data-contribute-upload-data">Contribute Open Data</h2>
        <p>
          In addition to showcasing the Open Data files represented in our Open
          ATB{' '}
          <a
            href="https://openatb.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            visualizations
          </a>
          , our catalogue will also showcase datasets from our community
          partners to serve your product development. Contribute your data,
          insights, and opportunities to create solutions to our repository.
          Ensure your uploads adhere to our platform’s{' '}
          <a
            href="https://openatb.com/dev/privacypolicy"
            target="_blank"
            rel="noopener noreferrer"
          >
            privacy policy
          </a>
          .
        </p>
        <DataForm
          onFinish={onFinish}
          onFinishFailed={onFinishFailed}
          {...formItemLayout}
          labelAlign={'left'}
          ref={this.formRef}
        >
          <Form.Item
            label="Dataset Name"
            name="datasetName"
            rules={[
              {
                required: true,
                message: 'Please input a name for your dataset',
              },
            ]}
            style={this.formItemStyle}
          >
            <Input placeholder="Name" />
          </Form.Item>
          <Form.Item
            label="Description of Data"
            name="description"
            rules={[
              {
                required: true,
                message: 'Please input a description for your dataset',
              },
              {
                min: 10,
                message: 'Description should not be shorter than 10 characters',
              },
            ]}
            style={this.formItemStyle}
          >
            <Input placeholder="Description" />
          </Form.Item>
          <Form.Item label="Files" style={this.formItemStyle}>
            <Upload {...props}>
              <Button>Select File</Button>
            </Upload>
          </Form.Item>
          <Form.Item wrapperCol={{ sm: 24 }}>
            <ButtonContainer>
              <Button
                type="primary"
                disabled={fileList.length === 0}
                loading={uploading}
                htmlType="submit"
              >
                {uploading ? 'Uploading' : 'Start Upload'}
              </Button>
              {!this.props.emailVerified && (
                <InfoContainer>
                  Please verify your email in your{' '}
                  <Link to="/home/profile">profile</Link> to enable uploads.
                </InfoContainer>
              )}
            </ButtonContainer>
          </Form.Item>
        </DataForm>
      </React.Fragment>
    );
  }
}
