import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { ApiDocResponse, ApiDoc } from '../api/types';
import { Dispatch } from 'redux';
import { RootState } from '../App';
import flatten from 'flat';
import shortid from 'shortid';

const NORMALIZE_NESTED_KEYS_REGEX = new RegExp('properties->|->type', 'g');

export type TaggedApiSection = { [tag: string]: ApiDocSectionView[] };

export interface RequestView {
  type: string;
  color: string;
}

export interface BodyView {
  key: string;
  path: string;
  type: string;
}

export interface ErrorView {
  key: string;
  code: string;
  description: string;
}

export interface ApiDocSectionView {
  index: number;
  title: string;
  requestType: RequestView;
  specificUrl: string;
  description: string;
  typedBody: BodyView[];
  possibleErrors: ErrorView[];
  exampleRequestBody: string | undefined;
  exampleResponseBody: string;
  tag: string;
}

export interface ApiNavSectionsIndexed {
  index: number;
  section: ApiDocSectionView;
}

export type ApiNavSectionsView = {
  [key: string]: ApiNavSectionsIndexed[];
};

export interface DocsState {
  loading: boolean;
  error: boolean;
  rawData: ApiDocResponse | undefined;
  apiNavSections: ApiNavSectionsView;
  orderedApiDocs: ApiNavSectionsIndexed[];
}

export const documentTypes = [
  'Account Info & Transactions',
  'Authentication',
  'Open Data',
];

const docsInitialState: DocsState = {
  loading: true,
  error: false,
  rawData: undefined,
  apiNavSections: {},
  orderedApiDocs: [],
};

const docs = createSlice({
  name: 'docs',
  initialState: docsInitialState,
  reducers: {
    setLoading: (state, action: PayloadAction<boolean>) => {
      state.loading = action.payload;
    },
    setError: (state, action: PayloadAction<boolean>) => {
      state.error = action.payload;
    },
    setRawData: (state, action: PayloadAction<ApiDocResponse | undefined>) => {
      state.rawData = action.payload;
    },
    setApiNavSections: (state, action: PayloadAction<ApiNavSectionsView>) => {
      state.apiNavSections = action.payload;
    },
    setOrderedApiDocs: (
      state,
      action: PayloadAction<ApiNavSectionsIndexed[]>,
    ) => {
      state.orderedApiDocs = action.payload;
    },
  },
});

export const docsReducer = docs.reducer;
export const docsActions = docs.actions;

// Thunks
export function fetchDocs(standard: string) {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    dispatch(docsActions.setLoading(true));
    dispatch(docsActions.setError(false));

    // Cancel request if data already exists
    // if (Object.keys(docs.apiNavSections).length > 0) {
    //   dispatch(docsActions.setLoading(false))
    //   return
    // }

    try {
      // TODO uncomment documentation
      // const docsResponse = await fetch(docResourceUrl)
      // const apiDocs = (await docsResponse.json()) as ApiDocResponse

      let apiDocs: ApiDocResponse;
      // if (standard === 'UK') {
      //   apiDocs = require('../testData/docs-uk-v3.1.json') as ApiDocResponse;
      // } else {
      //   apiDocs = require('../testData/docs-berlin-v4.0.json') as ApiDocResponse;
      // }

      if (standard === documentTypes[0]) {
        apiDocs = require('../testData/docs-uk-v3.1.json') as ApiDocResponse;
      } else {
        let selectedSpecs;
        if (standard === documentTypes[1]) {
          selectedSpecs = require('../specs/authentication-api.json');
        } else {
          selectedSpecs = require('../specs/open-data-api.json');
        }
        apiDocs = UKSpecsToApiDocResponse(selectedSpecs);
      }

      dispatch(docsActions.setRawData(apiDocs));

      const apiDocSections = apiDocsToSections(apiDocs);
      const apiNavSections = indexApiDocSections(apiDocSections);

      dispatch(docsActions.setApiNavSections(apiNavSections));
      dispatch(
        docsActions.setOrderedApiDocs(Object.values(apiNavSections).flat()),
      );

      dispatch(docsActions.setLoading(false));
    } catch (e) {
      console.error(e);
      dispatch(docsActions.setError(true));
    }
  };
}

export function renderDocs(psd2: boolean) {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { docs } = getState();

    dispatch(docsActions.setLoading(true));

    const apiDocs = docs.rawData ? docs.rawData.resource_docs : [];

    const filteredApiDocs = {
      resource_docs: psd2 ? apiDocs.filter(doc => doc.is_psd2) : apiDocs,
    };
    const apiDocSections = apiDocsToSections(filteredApiDocs, psd2 ? 1 : 0);
    const apiNavSections = indexApiDocSections(apiDocSections);
    dispatch(docsActions.setApiNavSections(apiNavSections));
    dispatch(
      docsActions.setOrderedApiDocs(Object.values(apiNavSections).flat()),
    );

    dispatch(docsActions.setLoading(false));
  };
}

// helpers
export function indexApiDocSections(apiSection: {
  [tag: string]: ApiDocSectionView[];
}): ApiNavSectionsView {
  let indexCount = 0;
  const ordered = {};

  // ['Consumer', 'API', 'Scope', 'Metric'].forEach((k: any) => delete apiSection[k])

  const unordered = Object.keys(apiSection).reduce<ApiNavSectionsView>(
    (prev, curr) => {
      prev[curr] = apiSection[curr].map(value => ({
        index: 0,
        section: value,
      }));
      return prev;
    },
    {},
  );

  Object.keys(unordered)
    .sort()
    .forEach(key => {
      (ordered as any)[key] = unordered[key].sort((a: any, b: any) =>
        a.section.title.localeCompare(b.section.title),
      );
      (ordered as any)[key].forEach((el: any) => (el.index = indexCount++));
    });

  return ordered;
}

export function apiDocsToSections(
  apiDocResponse: ApiDocResponse,
  tagIndex: number = 0,
): TaggedApiSection {
  const { resource_docs: apiDocs } = apiDocResponse;
  return apiDocs
    .map(apiDoc => apiDocToSection(apiDoc, tagIndex))
    .reduce<TaggedApiSection>((prev, curr) => {
      const { tag } = curr;
      if (prev[tag]) {
        prev[tag] = [...prev[tag], curr];
      } else {
        prev[tag] = [curr];
      }
      return prev;
    }, {});
}

export function apiDocToSection(
  apiDoc: ApiDoc,
  tagIndex: number = 0,
): ApiDocSectionView {
  const {
    summary,
    request_verb,
    description_markdown,
    specified_url,
    typed_request_body,
    error_response_bodies,
    success_response_body,
    example_request_body,
    tags,
  } = apiDoc;

  const isGet = request_verb === 'GET';

  return {
    tag: getTag(tags, tagIndex),
    title: summary,
    index: 0,
    requestType: {
      type: request_verb,
      color: getTagColor(request_verb),
    },
    description: description_markdown,
    specificUrl: specified_url,
    exampleRequestBody: isGet
      ? undefined
      : JSON.stringify(example_request_body, null, 2),
    exampleResponseBody: JSON.stringify(success_response_body, null, 2),
    typedBody: isGet ? [] : getBodyTypes(typed_request_body),
    possibleErrors: getErrors(error_response_bodies),
  };
}

function getTagColor(requestVerb: string): string {
  // switch (requestVerb) {
  //   case 'GET':
  //     return colors.green;
  //   case 'POST':
  //     return colors.blue;
  //   case 'PUT':
  //     return colors.yellow;
  //   case 'DELETE':
  //     return colors.red;
  //   default:
  //     return colors.black;
  // }

  return "#cccccc";
}

function getBodyTypes(typedBody: object) {
  const flattenedTypedBody: { [key: string]: string } = flatten(typedBody, {
    delimiter: '->',
  });

  return Object.keys(flattenedTypedBody).map(key => {
    if (typeof flattenedTypedBody[key] !== 'string') {
      return {
        key: shortid.generate(),
        path: key.replace(NORMALIZE_NESTED_KEYS_REGEX, ''),
        type: typeof flattenedTypedBody[key],
      };
    }
    return {
      key: shortid.generate(),
      path: key.replace(NORMALIZE_NESTED_KEYS_REGEX, ''),
      type: flattenedTypedBody[key],
    };
  });
}

function getErrors(possibleErrors: string[]) {
  return possibleErrors.map(error => {
    const [code, description] = error.split(': ');
    if (!description) {
      return {
        key: shortid.generate(),
        code: '<no code>',
        description: code,
      };
    }

    return {
      key: shortid.generate(),
      code: code,
      description,
    };
  });
}

function getTag(tags: string[] | undefined, tagIndex: number = 0) {
  if (tags && tags[tagIndex]) {
    return tags[tagIndex].replace(/-/g, ' ');
  }
  return 'Other';
}

/*
Without changing the UI elements which already work with OBP standards
build a mapping from the new standards to the existing one
*/
function UKSpecsToApiDocResponse(selectedSpecs: any) {
  let apiDocs: ApiDocResponse = {
    resource_docs: [],
  };

  for (let requestUrl in selectedSpecs.paths) {
    let urlSpecs = selectedSpecs.paths[requestUrl];
    for (let requestVerb in urlSpecs) {
      let ukDoc = urlSpecs[requestVerb];
      let apiDoc: ApiDoc = initApiDocFromUKDoc(ukDoc, requestVerb, requestUrl);

      //Open data endpoints do not have summaries to show on the right pane
      //Use the tags
      if (!apiDoc.summary && apiDoc.tags) {
        apiDoc.summary = apiDoc.tags[0];
      }

      populateApiDocReqParams(ukDoc, apiDoc);
      populateApiDocResponses(ukDoc, apiDoc);
      apiDocs.resource_docs.push(apiDoc);
    }
  }
  return apiDocs;

  function populateApiDocResponses(ukDoc: any, apiDoc: ApiDoc) {
    Object.keys(ukDoc.responses).forEach(responseCode => {
      let responseRefStr: string = ukDoc.responses[responseCode]['$ref'];
      if (responseRefStr === undefined) {
        populateEmbeddedResponses(responseCode);
      } else {
        populateReferencedResponses(responseRefStr, responseCode);
      }
    });

    function populateReferencedResponses(
      responseRefStr: string,
      responseCode: string,
    ) {
      let responseName = getUKReferenceString(responseRefStr);
      if (['200', '201', '202', '204'].includes(responseCode)) {
        //Create a response example from here
        let successResponse = selectedSpecs.responses[responseName];
        if (successResponse.schema) {
          let successSchemaRef = getUKReferenceString(
            successResponse.schema['$ref'],
          );
          let schemaDef = selectedSpecs.definitions[successSchemaRef];
          let response = buildResponseFromSchema(schemaDef);
          apiDoc.success_response_body = response;
        }
      } else {
        let responseDesc: string =
          selectedSpecs.responses[responseName].description;
        apiDoc.error_response_bodies.push(responseCode + ': ' + responseDesc);
      }
    }

    function populateEmbeddedResponses(responseCode: string) {
      if (['200', '201', '202', '204'].includes(responseCode)) {
        let schemaDef = ukDoc.responses[responseCode]['schema'];
        if (schemaDef !== undefined) {
          let response = buildResponseFromSchema(schemaDef);
          apiDoc.success_response_body = response;
        }
      } else {
        let responseDesc: string = ukDoc.responses[responseCode].description;
        apiDoc.error_response_bodies.push(responseCode + ': ' + responseDesc);
      }
    }
  }

  function populateApiDocReqParams(ukDoc: any, apiDoc: ApiDoc) {
    for (let ukParam in ukDoc.parameters) {
      let paramName, paramType;
      let paramRefStr = ukDoc.parameters[ukParam]['$ref'];
      // take the reference to the parameter
      if (paramRefStr !== undefined) {
        paramName = getUKReferenceString(paramRefStr);
        if (selectedSpecs.parameters[paramName]['schema']) {
          paramType = buildResponseFromSchema(
            selectedSpecs.parameters[paramName]['schema'],
          );
        } else {
          paramType = selectedSpecs.parameters[paramName]['type'];
        }
      } else {
        //schema is embedded here
        paramName = ukDoc.parameters[ukParam]['name'];
        paramType = ukDoc.parameters[ukParam]['type'];
      }
      apiDoc.typed_request_body[paramName] = paramType;
    }
  }

  function buildResponseFromSchema(schemaDef: any): any {
    let response: any = {};
    //Referred to another type
    if (schemaDef['$ref'] !== undefined) {
      let schemaRef = getUKReferenceString(schemaDef['$ref']);
      response = buildResponseFromSchema(selectedSpecs.definitions[schemaRef]);
    } else if (schemaDef.type === 'object') {
      Object.keys(schemaDef.properties).forEach((key: string) => {
        response[key] = buildResponseFromSchema(schemaDef.properties[key]);
      });
    } else if (schemaDef.type === 'array') {
      response = [buildResponseFromSchema(schemaDef.items)];
    } else response = schemaDef.type;
    return response;
  }
}

function getUKReferenceString(paramRaw: string) {
  let lastInd: number = paramRaw.lastIndexOf('/');
  return paramRaw.substring(lastInd + 1);
}

function initApiDocFromUKDoc(
  ukDoc: any,
  requestVerb: string,
  requestUrl: string,
) {
  return {
    operation_id: ukDoc.operationId,
    implemented_by: {
      version: 'version',
      function: 'function',
    },
    request_verb: requestVerb.toUpperCase(),
    request_url: requestUrl,
    summary: ukDoc.summary,
    description: ukDoc.description || '',
    description_markdown: ukDoc.description || '',
    example_request_body: {},
    success_response_body: {},
    error_response_bodies: [],
    is_core: true,
    is_psd2: true,
    is_obwg: true,
    tags: ukDoc.tags,
    typed_request_body: {},
    typed_success_response_body: {},
    is_featured: true,
    special_instructions: '',
    specified_url: requestUrl,
  };
}
