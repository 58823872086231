import React, { useState } from 'react';
import { Menu } from 'antd';
import styled from 'styled-components';
import SubMenu from 'antd/lib/menu/SubMenu';
import device from '../components/utils/devices';

const StyledMenu = styled(Menu)`
  border: 0;
  margin-top: 20px;
  @media ${device.tablet} {
    margin-top: 0;
    position: fixed;
    width: 20%;
    overflow-y: scroll;
    z-index: 0;
  }
`;

export interface SubNavBarItems {
  title: string;
  onClick?: () => void;
}

export interface SubNavBarSection {
  title: string;
  items: SubNavBarItems[];
  key?: string;
}

export interface SubNavBarProps {
  icon: string;
  selectedKey: string;
  sections: SubNavBarSection[];
  optionsContainer?: JSX.Element;
  menuKey: string;
  hrefPrefix: string;
}

export const normalizeString = (str: string) => {
  return str.replace(/[\W_]+/g, '-').toLowerCase();
};

const getSectionKey = (section: any) => {
  return section.key ? section.key : normalizeString(section.title);
};

let index = 0;

export function SubNavBar({
  sections,
  selectedKey,
  optionsContainer,
  hrefPrefix,
}: SubNavBarProps) {
  const [rootSubmenuKeys] = useState(
    sections.map((section) => getSectionKey(section)),
  );
  const [openKeys, setOpenKeys] = useState([rootSubmenuKeys[0]]);
  const onOpenChange = (keys: any) => {
    const latestOpenKey: string = keys.find(
      (key: string) => openKeys.indexOf(key) === -1,
    );
    if (rootSubmenuKeys.indexOf(latestOpenKey) === -1) {
      setOpenKeys(keys);
    } else {
      setOpenKeys(latestOpenKey ? [latestOpenKey] : []);
    }
  };

  const subMenus = sections.map((section) => (
    <SubMenu
      key={getSectionKey(section)}
      title={
        <a
          href="# "
          onClick={(e) => {
            e.preventDefault();
          }}
        >
          {section.title}
        </a>
      }
      className="sub-navbar-menu"
    >
      {section.items.map((item) => {
        const menuItem = (
          <Menu.Item
            onClick={
              item.onClick
                ? item.onClick
                : (e) => {
                    scrollToMenuItem(section, item);
                  }
            }
            key={section.key ? section.key : `${normalizeString(item.title)}`}
          >
            <span
              className="nav-text"
              data-key={`${(item.title + index).replace(/[\W_]+/g, '')}`}
            >
              {item.title}
            </span>
          </Menu.Item>
        );
        index++;
        return menuItem;
      })}
    </SubMenu>
  ));

  return (
    <StyledMenu
      key={new Date().toISOString()}
      selectedKeys={[selectedKey]}
      mode={'inline'}
      openKeys={openKeys}
      onOpenChange={onOpenChange}
    >
      {optionsContainer}
      {subMenus}
    </StyledMenu>
  );

  function scrollToMenuItem(section: SubNavBarSection, item: SubNavBarItems) {
    const goTo = document.getElementById(
      `${hrefPrefix}${normalizeString(section.title)}-${normalizeString(
        item.title,
      )}`,
    );
    if (goTo) {
      const y = goTo.getBoundingClientRect().top + window.scrollY;
      window.scroll({
        top: y - 150,
        behavior: 'smooth',
      });
    }
  }
}
