import axios from 'axios';
import { config } from '../config';

const { portal } = config;

const getUserMetadata = async (jwt: string) => {
  const response = await axios.get(`${portal.apiUrl}/user_metadata`, {
    headers: { Authorization: 'Bearer ' + jwt },
  });
  return response.data.user_metadata;
};

const setMarketingEmailSubscription = async (jwt: string, value: boolean) => {
  const response = await axios.post(
    `${portal.apiUrl}/marketing_emails`,
    {
      marketing_emails: value,
    },
    {
      headers: {
        Authorization: 'Bearer ' + jwt,
        'Content-Type': 'application/json',
      },
    },
  );
  return response.data;
};

const sendEmailVerification = async (jwt: string) => {
  const response = await axios.post(
    `${portal.apiUrl}/email_verification`,
    {},
    {
      headers: {
        Authorization: 'Bearer ' + jwt,
      },
    },
  );
  return response.data;
};

export {
  getUserMetadata,
  sendEmailVerification,
  setMarketingEmailSubscription,
};
