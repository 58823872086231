import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { User, Institutions } from '../components/types/types';
import { Dispatch } from 'redux';
import { RootState } from '../App';

export interface UserState {
  institutions: Institutions[];
  userName: string;
  nickName: string;
  userEmail: string;
  userImageUrl: string;
  token: string;
  emailVerified: boolean;
}

const initialUserState: UserState = {
  institutions: [],
  userName: '',
  nickName: '',
  userEmail: '',
  userImageUrl: '',
  token: '',
  emailVerified: false,
};

export function fetchToken() {
  return async (dispatch: Dispatch, getState: () => RootState) => {
    const { user } = getState();
    if (user) {
      const { institutions, userEmail } = user;
      if (institutions && user.institutions.length === 2) {
        try {
          const response = await fetch(
            `https://${
              (institutions.find((f: any) => f.type === 'acme') as any).bank_id
            }.leapos.ca/my/logins/direct`,
            {
              method: 'POST',
              headers: {
                'Content-Type': 'application/json',
                Authorization: `DirectLogin username="${
                  userEmail.split('@')[0]
                }", password="${institutions[0].password}", consumer_key="${
                  institutions[0].consumer_key
                }"`,
              },
            },
          );

          const json = await response.json();
          console.log('Success:', JSON.stringify(json));
        } catch (error) {
          console.error('Error:', error);
        }
      }
    }
    dispatch(userActions.setToken('123'));
  };
}

const userSlice = createSlice({
  name: 'user',
  initialState: initialUserState,
  reducers: {
    setData: (state, { payload }: PayloadAction<User>) => {
      state.institutions = payload['https://atb.com/credentials'];
      state.userName = payload.name;
      state.nickName = payload.nickname;
      state.userEmail = payload.email;
      state.userImageUrl = payload.picture;
      state.emailVerified = payload.email_verified;
    },
    setToken: (state, action: PayloadAction<string>) => {
      state.token = action.payload;
    },
  },
});

export const userReducer = userSlice.reducer;

export const userActions = userSlice.actions;
