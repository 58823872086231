import React from 'react';
import { Link } from 'react-router-dom';
import { Button } from 'antd';
import styled from 'styled-components';

import { Application } from '../../../api/types';
import DesktopApplicationTable from './DesktopApplicationTable';
import MobileApplicationTable from './MobileApplicationTable';

export interface ApplicationProps {
  applications: Application[];
  loading: boolean;
  onRowClick: any;
  emailVerified: boolean;
}

const ButtonContainer = styled.div`
  width: auto;
`;

const InfoContainer = styled.div`
  display: inline-block;
`;

const AddNewButton = styled(Button)`
  background-color: #0092c3;
  margin-right: 20px;
`;

const ApplicationList = (props: ApplicationProps) => {
  const appTable =
    window.outerWidth < 768 ? (
      <MobileApplicationTable {...props} />
    ) : (
      <DesktopApplicationTable {...props} />
    );

  return (
    <div>
      {appTable}
      <ButtonContainer>
        <AddNewButton type="primary" disabled={!props.emailVerified}>
          <Link to="create-application">Add new</Link>
        </AddNewButton>
        {!props.emailVerified && (
          <InfoContainer>
            Please verify your email address to create apps. Visit{' '}
            <Link to="/home/profile">Profile</Link> to manage your account.
          </InfoContainer>
        )}
      </ButtonContainer>
    </div>
  );
};

export default ApplicationList;
