import styled from 'styled-components';
import { Button } from 'antd';

const OkButton = styled(Button)`
  float: right;
  background-color: #0092c3;
`;

// Create and edit forms
const formItemLayout = {
  labelCol: {
    xs: { span: 24 },
    sm: { span: 8 },
  },
  wrapperCol: {
    xs: { span: 24 },
    sm: { span: 16 },
  },
};

const tailFormItemLayout = {
  wrapperCol: {
    xs: {
      span: 24,
      offset: 0,
    },
    sm: {
      span: 16,
      offset: 8,
    },
  },
};

const getCompressedBase64 = (img: any, callback: any) => {
  const reader = new FileReader();
  reader.addEventListener('load', () => {
    const img = new Image();
    img.src = reader.result as string;
    img.onload = () => {
      const elem = document.createElement('canvas');
      const width = 100;
      const scaleFactor = width / img.width;
      elem.width = width;
      elem.height = img.height * scaleFactor;
      const ctx = elem.getContext('2d');
      if (ctx) {
        ctx.drawImage(img, 0, 0, width, img.height * scaleFactor);
        callback(ctx.canvas.toDataURL());
      }
    };
  });
  reader.readAsDataURL(img);
};

export { getCompressedBase64, OkButton, formItemLayout, tailFormItemLayout };
