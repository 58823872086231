import React from 'react';
import { Row, Col, Card, Typography } from 'antd';
import styled from 'styled-components';
import { useAuth } from 'use-auth0-hooks';
import { Redirect, Link } from 'react-router-dom';
import bg from '../../assets/background.jpg';

const { Title, Text } = Typography;

const AbsoluteHeaderContainer = styled.div`
  position: absolute;
  top: 20px;
  right: 40px;
`;

const Container = styled.div`
  background-image: url(${bg});
  background-scale: contain;
  background-position: bottom center;
`;

const LoginInfoContainer = styled.div`
  display: flex;
  flex: 1;
  min-height: 100vh;
  align-items: center;
  justify-content: center;
`;

const TextContainer = styled.div`
  margin-top: 15px;
  margin-bottom: 20px;
`;

const LogoContainer = styled.img`
  width: 50px;
  height: 50px;
  margin-top: 10px;
  margin-bottom: 25px;
`;

export function NotFound() {
  const { isAuthenticated } = useAuth();

  if (isAuthenticated) {
    return <Redirect to={'/home/welcome'} />;
  }

  return (
    <Container>
      <AbsoluteHeaderContainer>
        <Text>powered by Open ATB</Text>
      </AbsoluteHeaderContainer>
      <Row>
        <Col xs={6} sm={6} md={14} span={14}></Col>
        <Col xs={18} sm={18} md={8} span={8}>
          <LoginInfoContainer>
            <Card
              style={{
                margin: '20px',
                padding: '20px',
                boxShadow: '5px 5px 20px rgba(0, 0, 0, 0.15)',
                width: '50vh',
                alignItems: 'center',
                borderRadius: '1vh',
              }}
            >
              <LogoContainer src={require('../../assets/ATB_Logo.jpg')} />
              <Title>404</Title>
              <TextContainer>
                <Text>Page you are looking for was not found.</Text>
                <p>
                  <Link to="/">Return Home</Link>
                </p>
              </TextContainer>
            </Card>
          </LoginInfoContainer>
        </Col>
      </Row>
    </Container>
  );
}
