import React from 'react';
import { StyledContentContainer } from '../StyleUtility';
import { Divider, Card } from 'antd';
import { Link } from 'react-router-dom';

const SANDBOX_API_BASE_URL =
  process.env.REACT_APP_SANDBOX_API_BASE_URL ||
  'https://nonprod-preprod.openatb.com';

const OAuth = () => {
  return (
    <StyledContentContainer>
      <h1>An Introduction to OAuth 2</h1>
      <p>
        OAuth 2.0 is the leading security standard for delegated authorization
        and UK Open Banking has determined it is the right solution for Open
        Banking. OAuth solves the problem of enabling a user to allow a third
        party to act on their behalf. OAuth is commonly used for permitting
        websites to share data with one another, such as allowing Twitter to
        post to Facebook. Instead of sharing usernames and passwords, an OAuth
        access token is issued that the third party can then present to access a
        user's data. To obtain and validate a token you will need to use
        following endpoints.
      </p>
      <Divider />

      <h2>Application Registration</h2>
      <p>
        Before using OAuth with your application, you must register your
        application with the service. This is done through a registration form
        in the <Link to="/home/applications">Applications</Link>, where you will
        provide the following information (and probably details about your
        application):
      </p>
      <ul>
        <li>Application Name</li>
        <li>Application Website</li>
        <li>Redirect URI or Callback URL</li>
      </ul>
      <p>
        The redirect URI is where the service will redirect the user after they
        authorize (or deny) your application, and therefore the part of your
        application that will handle authorization codes or access tokens.
      </p>
      <Divider />

      <h2>Client Credential Flow</h2>
      <p>
        If your application needs to access APIs that are not consumer specific,
        use the Client Credential Flow. Your application cannot access these
        APIs by default:
      </p>
      <p>
        To generate an access token, issue a HTTP POST against accessToken with
        your Client ID, Client Secret values and any audience value.
      </p>

      <div className="code">
        POST /oauth/v2/token HTTP/1.1
        <br />
        Host: {SANDBOX_API_BASE_URL}
        <br />
        Content-Type: application/x-www-form-urlencoded
        <br />
        <br />
        grant_type=client_credentials&client_id=CLIENT_ID_FROM_STEP1&client_secret=CLIENT_SECRET_FROM_STEP1&audience=AUDIENCE
      </div>
      <p>
        A successful access token request returns a JSON object containing the
        following fields:
      </p>
      <ul>
        <li>
          - <i>access_token</i> — The access token for the application. This
          token must be kept secure.
        </li>
        <li>
          - <i>expires_in</i> — The number of seconds remaining until the token
          expires. Access tokens are issued with a 30 minute lifespan. You can
          request a new token once your current token expires.
        </li>
      </ul>

      <p>
        Once you've received an access token, you can make API requests by
        including an Authorization header with your token in the HTTP call to
        Open ATB's API.
      </p>
      <div className="code">
        GET /open-banking/v2.3/atms HTTP/1.1
        <br />
        Host: {SANDBOX_API_BASE_URL}
        <br />
        Connection: Keep-Alive
        <br />
        Authorization: Bearer access_token
        <br />
      </div>
      <p>
        If you make an API call using an invalid token, you'll receive a 401
        Unauthorized response from the server. In this case, the token may need
        to be regenerated because it expired or was revoked. These are not the
        only reasons for an invalid token. Make sure your applications are coded
        to properly handle a 401 Unauthorized error.
      </p>

      <Card>
        <h6>
          <a href="/home/applications">Create an Application</a>
        </h6>
        Create a new application to get credentials which can be exchanged for
        an access token.
      </Card>
      <Divider />

      <h2>Authorization Code Flow</h2>
      <p>
        If your application needs to access APIs that are consumer specific, use
        the Authorization Code Flow.
      </p>

      <p>
        To request an authorization code, you must direct the customers's
        browser to Open ATB's OAuth 2.0 authorization page, where the customer
        either accepts or denies your application's permission request.
      </p>
      <p>Once the request is made, one of the following occurs:</p>
      <ul>
        <li>
          If the customer has not previously accepted the application's
          permission request, or the grant has expired or been manually revoked
          by the customer, the browser is redirected to Open ATB's authorization
          screen. When the customer completes the authorization process, the
          browser is redirected to the URL provided in the redirect_uri query
          parameter.
        </li>
        <li>
          <p>
            If there is a valid existing permission grant from the customer, the
            authorization screen is bypassed and the customer is immediately
            redirected to the URL provided in the redirect_uri query parameter.
          </p>
          <p>
            {' '}
            Note that if you ever change the scope permissions that your
            application requires, your application's users must re-authenticate
            to ensure that they have explicitly granted your application all of
            the permissions that it requests on their behalf.
          </p>
        </li>
      </ul>
      <p>
        <strong>
          Consumer scopes are still in development and will be available
          shortly.
        </strong>
      </p>

      <h4>Obtain Authorization Code</h4>
      <p>
        To generate an access token, issue a HTTP GET request authorize with
        your Client ID.
      </p>

      <div className="code">
        GET /oauth/v2/authorize
        <br />
        HTTP/1.1
        <br />
        Host: {SANDBOX_API_BASE_URL}
        <br />
        <br />
        response_type=code&client_id=CLIENT_ID_FROM_STEP1&nonce=RANDOM_VARIABLE&state=RANDOM_VARIABLE&scope=openid&redirect_uri=CALLBACK_URL_FROM_STEP1
      </div>

      <h4>Exchange Authorization Code for an Access Token</h4>
      <p>
        The next step is to get an access token for your application using the
        authorization code from the previous step. To do this, make the
        following HTTP POST request with a Content-Type header of
        x-www-form-urlencoded:
      </p>
      <div className="code">
        POST /oauth/v2/token HTTP/1.1
        <br />
        Host: {SANDBOX_API_BASE_URL}
        <br />
        Content-Type: application/x-www-form-urlencoded
        <br />
        <br />
        grant_type=authorization_code&code=AUTHORIZATION_CODE_FROM_STEP2_RESPONSE&redirect_uri=CALLBACK_URL_FROM_STEP1&client_id=your_client_id&client_secret=your_client_secret&audience=your_client_id
      </div>

      <h4>Make Authenticated Requests</h4>
      <p>
        Once you've obtained an access token, you can start making authenticated
        API requests on behalf of the customer by including an Authorization
        header in the HTTP call to Open ATB's API.
      </p>
      <div className="code">
        GET /open-banking/v3.1/accounts HTTP/1.1
        <br />
        Host: {SANDBOX_API_BASE_URL}
        <br />
        Connection: Keep-Alive
        <br />
        Authorization: Bearer access_token
        <br />
      </div>

      <h4>Refresh Access Token</h4>
      <p>
        To protect customers' data, Open ATB does not generate long-lived access
        tokens. Make sure your application refreshes access tokens before they
        expire, to avoid unnecessarily sending your application's users through
        the authorization process again.
      </p>
      <p>
        Refreshing an access token is a seamless user experience. To refresh an
        access token, go through the authorization process again to fetch a new
        token. This time however, in the refresh workflow, the authorization
        screen is bypassed and the member is redirected to your callback URL,
        provided the following conditions are met:
      </p>
      <ul>
        <li>The customer is still logged into openatb.com</li>
        <li>The customer's current access token has not expired</li>
      </ul>
      <Card>
        <h6>
          <a href="/home/applications">Create an Application</a>
        </h6>
        Create a new application to get credentials which can be exchanged for
        an access token.
      </Card>
      <br />
      <Card>
        <h6>
          <a
            href="https://demo.openatb.com"
            target="_blank"
            rel="noopener noreferrer"
          >
            View Example
          </a>
        </h6>
        We've created a sample application which exchanges credentials for token
        and accesses account information.
      </Card>
    </StyledContentContainer>
  );
};

export default OAuth;
