import React, { useEffect, useState } from 'react';
import { message, Spin } from 'antd';
import { Content } from '../Content';
import { SubNavBar, SubNavBarSection } from '../SubNavBar';
import { getDatasets, Dataset } from '../../api/DataWorld';
import { useAuth } from 'use-auth0-hooks';
import styled from 'styled-components';

import { config } from '../../config';
import { getErrorMessage, retrieveApplications } from '../../api/Application';
import { Application } from '../../api/types';
import { UploadFiles } from '../UploadFiles';
import { Row, Col } from 'antd';
import { StyledContainer, configureMessageContainer } from '../StyleUtility';
import DatasetTable from '../DatasetTable';
import { ReactGa } from '../ReactGa';

const IntroSectionTitle = 'Introduction';
const ContributeSectionTitle = 'Contribute';
const CatalogueSectionTitle = 'Catalogue';

const SpinnerContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
`;

const { auth0 } = config;

export const OpenData = () => {
  const { accessToken, user } = useAuth({
    audience: auth0.audience,
    scope: auth0.scope,
  });
  const emailVerified = user.email_verified;
  const [datasets, setDatasets] = useState([] as Dataset[]);
  const [applications, setApplications] = useState([] as Application[]);
  const [navigationDatasets, setNavigationDatasets] = useState([] as Dataset[]);
  const [jwt, setJwt] = useState('');
  const [loading, setLoading] = useState(emailVerified);
  configureMessageContainer();

  useEffect(() => {
    const _getDatasets = async () => {
      setLoading(true);
      setJwt(accessToken!);
      const result: Dataset[] = await getDatasets(accessToken!).catch(
        (error) => {
          message.error(getErrorMessage(error));
          setLoading(false);
          return [];
        },
      );
      setDatasets(result);
      setLoading(false);
    };

    const _getApplications = async () => {
      const result: Application[] = await retrieveApplications(
        accessToken!,
      ).catch((error) => {
        message.error(getErrorMessage(error));
        return [];
      });
      setApplications(result);
    };
    if (emailVerified) {
      _getDatasets();
      _getApplications();
    }
  }, [accessToken, emailVerified]);

  const navBarSections: SubNavBarSection[] = [
    {
      title: IntroSectionTitle,
      items: ['Introduction'].map((section) => ({
        title: section,
      })),
    },
    {
      title: ContributeSectionTitle,
      items: ['Upload Data'].map((section) => ({
        title: section,
      })),
    },
    {
      title: CatalogueSectionTitle,
      items: navigationDatasets.map((section) => ({
        title: section.title,
      })),
    },
  ];

  const getDatasetInfo = () => {
    if (loading)
      return (
        <div className="content">
          <div className="content_wrapper">
            <SpinnerContainer>
              <Spin />
            </SpinnerContainer>
          </div>
        </div>
      );

    return (
      <DatasetTable
        datasets={datasets}
        jwt={jwt}
        setNavigationDatasets={setNavigationDatasets}
        applications={applications}
      />
    );
  };

  const getIntroPage = () => {
    return (
      <React.Fragment>
        <h2 id="open-data-introduction-introduction">Introduction</h2>
        <p>
          The power of data resides in its utilization - a utilization we
          believe can significantly enhance both product experiences &amp;
          social progress alike. Open Data, in its purest and most effective
          forms, represents humanity through the communities we belong to. We
          believe this broad representation has the significant power to improve
          our collective understanding and enhance products.
        </p>
      </React.Fragment>
    );
  };

  const getDatasetsPage = () => {
    return (
      <ReactGa>
        <h2>Open Data Catalogue</h2>
        <p>
          Access &amp; download the open data utilized to develop our OpenData
          Usecase Visualizations.
        </p>
        {getDatasetInfo()}
      </ReactGa>
    );
  };

  const getUploadPage = () => {
    return (
      <React.Fragment>
        <UploadFiles jwt={jwt} emailVerified={emailVerified} />
      </React.Fragment>
    );
  };

  const getContent = () => {
    return (
      <Content>
        {getIntroPage()}
        {getUploadPage()}
        {getDatasetsPage()}
      </Content>
    );
  };

  return (
    <StyledContainer>
      <Row>
        <Col md={6} sm={24}>
          <SubNavBar
            menuKey={'opendata'}
            icon={'database'}
            selectedKey={''}
            sections={navBarSections}
            hrefPrefix="open-data-"
          />
        </Col>
        <Col md={18} sm={24}>
          {getContent()}
        </Col>
      </Row>
    </StyledContainer>
  );
};
