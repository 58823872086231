import React, { useState, useEffect } from 'react';
import styled from 'styled-components';
import {
  Typography,
  Button,
  Card,
  Row,
  Col,
  Spin,
  message,
  Switch,
} from 'antd';
import { useAuth } from 'use-auth0-hooks';
import { CloseOutlined, CheckOutlined } from '@ant-design/icons';

import { config } from '../../config';
import { Content } from '../Content';
import { StyledContainer, configureMessageContainer } from '../StyleUtility';
import {
  getUserMetadata,
  setMarketingEmailSubscription,
  sendEmailVerification,
} from '../../api/Auth0Management';
import { getErrorMessage } from '../../api/Application';

const { Text } = Typography;

const { auth0, cms } = config;

const ProfileContainer = styled.div`
  display: flex;
  flex: 1;
  flex-direction: row;
  justify-content: space-between;
  padding: 10;
`;

const ProfileItems = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  justify-content: space-around;
  align-items: flex-start;
  margin-left: 30px;
`;

const InfoContainer = styled.div`
  margin-top: 20px;
`;

const ProfileImage = styled.img`
  width: 150px;
  height: 150px;
  border-radius: 100px;
`;

const SignOutButton = styled(Button)`
  background-color: #0092c3;
  color: white;
  margin: 0;
  position: absolute;
  top: 50%;
  -ms-transform: translateY(-50%);
  transform: translateY(-50%);
  &:hover {
    background-color: #40a9ff;
    color: white;
  }
`;

const VerificationButton = styled(Button)`
  background-color: #0092c3;
  color: white;
  margin-top: 5px;
  &:hover {
    background-color: #40a9ff;
    color: white;
  }
  &:focus {
    background-color: #0092c3;
    color: white;
  }
`;

export interface ProfileProps {
  userName: string;
  nickName: string;
  userEmail: string;
  imageUrl: string;
  emailVerified: boolean;
}

export function Profile({
  userName,
  nickName,
  userEmail,
  imageUrl,
  emailVerified,
}: ProfileProps) {
  const { accessToken, logout } = useAuth({
    audience: auth0.audience,
    scope: auth0.scope,
  });

  configureMessageContainer();

  const [imgSrc, setImgSrc] = useState(imageUrl);
  const handleImageError = () => {
    setImgSrc('/generic_profile.svg');
  };

  const [subscribed, setSubscribed] = useState(false);
  const [subscriptionLoading, setSubscriptionLoading] = useState(true);
  const [emailVerificationLoading, setEmailVerificationLoading] = useState(
    false,
  );

  useEffect(() => {
    const getEmailSubscription = async () => {
      try {
        const result = await getUserMetadata(accessToken!);
        setSubscribed(result.marketing_emails || false);
      } finally {
        setSubscriptionLoading(false);
      }
    };
    if (emailVerified) getEmailSubscription();
  }, [emailVerified, accessToken]);

  const toggleMarketingEmails = async (value: boolean) => {
    setSubscriptionLoading(true);
    try {
      await setMarketingEmailSubscription(accessToken!, value);
      setSubscribed(value);
    } catch (error) {
      message.error('An error occured while updating user settings');
    }
    setSubscriptionLoading(false);
  };

  const sendVerification = async () => {
    setEmailVerificationLoading(true);
    try {
      setEmailVerificationLoading(true);
      await sendEmailVerification(accessToken!);
      message.success('Email will be send shortly');
    } catch (error) {
      message.error(getErrorMessage(error));
    } finally {
      setEmailVerificationLoading(false);
    }
  };

  const getSubscriptionSwitch = () => {
    const emailSwitch = (
      <div>
        <div>
          Receive emails regarding new features and platform updates on Open
          ATB. By opting in to these digital updates, you're agreeing to the
          platform's{' '}
          <a
            href="https://openatb.com/dev/privacypolicy"
            target="_blank"
            rel="noopener noreferrer"
          >
            Privacy Policy.
          </a>
        </div>
        <Switch
          checked={subscribed}
          onChange={toggleMarketingEmails}
          checkedChildren={<CheckOutlined />}
          unCheckedChildren={<CloseOutlined />}
          defaultChecked
        />
      </div>
    );
    if (subscriptionLoading) return <Spin>{emailSwitch}</Spin>;
    return emailSwitch;
  };

  const getEmailVerificationSection = () => {
    return (
      <React.Fragment>
        <p>Your account is not verified.</p>
        <div>
          Please check your inbox for a verification email from Open ATB to
          complete the onboarding process
        </div>
        <div>
          <VerificationButton
            loading={emailVerificationLoading}
            onClick={sendVerification}
          >
            Send new code
          </VerificationButton>
        </div>
      </React.Fragment>
    );
  };

  return (
    <StyledContainer>
      <Row>
        <Col md={6} sm={24}></Col>
        <Col md={12} sm={24}>
          <Content>
            <Row>
              <Col sm={20} xs={16}>
                <h2>Profile</h2>
              </Col>
              <Col sm={4} xs={8}>
                <SignOutButton onClick={() => logout({ returnTo: cms.url })}>
                  Sign out
                </SignOutButton>
              </Col>
            </Row>
            <Card
              bordered={false}
              style={{ width: window.outerWidth < 600 ? '300px' : '500px' }}
            >
              <ProfileContainer>
                {window.outerWidth > 600 && (
                  <ProfileImage src={imgSrc} onError={handleImageError} />
                )}
                <ProfileItems>
                  <div>
                    <Text>
                      <b>Name: </b>
                      {userName !== userEmail ? userName : ''}
                    </Text>
                  </div>
                  <div>
                    <Text>
                      <b>Username: </b>
                      {nickName}
                    </Text>
                  </div>
                  <div>
                    <Text>
                      <b>Email: </b>
                      {userEmail}
                    </Text>
                  </div>
                  <div>
                    <Text>
                      <b>Status: </b>
                      {emailVerified ? 'Verified' : 'Not verified'}
                    </Text>
                  </div>
                </ProfileItems>
              </ProfileContainer>
            </Card>
            <InfoContainer>
              {emailVerified && <div>{getSubscriptionSwitch()}</div>}
              {!emailVerified && <div>{getEmailVerificationSection()}</div>}
            </InfoContainer>
          </Content>
        </Col>
      </Row>
    </StyledContainer>
  );
}
