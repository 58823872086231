import ReactGA from 'react-ga';

const ANALYTICS_ID = process.env.ANALYTICS_ID || 'UA-159875303-1';

export const initGA = () => {
  // console.log('GA init');
  ReactGA.initialize(ANALYTICS_ID);
};
export const logPageView = () => {
  // console.log(`Logging pageview for ${window.location.pathname}`);
  ReactGA.set({ page: window.location.pathname });
  ReactGA.pageview(window.location.pathname);
};

export const logEvent = ({
  prefix = '',
  category = '',
  action = '',
  label = '',
  value = 0,
}) => {
  if (prefix) {
    category = `${prefix} - ${category}`;
    label = `${prefix} - ${label}`;
  }

  if (category && label) {
    ReactGA.event({ category, action, label, value });
  }
};
export const logException = (description = '', fatal = false) => {
  if (description) {
    ReactGA.exception({ description, fatal });
  }
};
