import { connect } from 'react-redux';
import { bindActionCreators, Dispatch } from 'redux';
import { userActions } from '../redux/userSlice';
import Home from '../components/screens/Home';

function mapStateToProps() {
  return {};
}

function mapDispatchToProps(dispatch: Dispatch) {
  return bindActionCreators(
    {
      setUserData: userActions.setData,
    },
    dispatch,
  );
}

export const HomeContainer = connect(mapStateToProps, mapDispatchToProps)(Home);
