import React from 'react';
import { Link } from 'react-router-dom';
import styled from 'styled-components';
import { FormProps } from 'antd/lib/form';
import { Form, Input, Button, Select, Spin } from 'antd';

import ApplicationLogo from './ApplicationLogo';
import {
  OkButton,
  tailFormItemLayout,
  formItemLayout,
} from './ApplicationStyleUtils';

const { TextArea } = Input;

const AppForm = styled(Form)`
  width: 70%;
`;

const CancelButton = styled(Button)`
  background-color: lightgrey;
  margin-right: 1em;
`;

const ButtonDiv = styled.div`
  float: right;
`;

const URL_MESSAGE =
  'This field must be a valid url starting with http:// or https://';

export interface NewApplicationProps extends FormProps {
  addApplication: any;
  handleLogoChange: any;
  appLogo: string;
  loading: boolean;
  logoLoading: boolean;
}

const NewApplicationForm = (props: NewApplicationProps) => {
  const [form] = Form.useForm();

  const handleSubmit: any = async (values: any) => {
    props.addApplication(values);
  };

  return (
    <Spin spinning={props.loading}>
      <AppForm
        form={form}
        {...formItemLayout}
        onFinish={handleSubmit}
        size={'middle'}
        initialValues={{
          appType: 'Web',
          environment: 'Sandbox',
        }}
        labelAlign="left"
      >
        <Form.Item
          label="Application Name"
          name="appName"
          rules={[
            {
              required: true,
              pattern: new RegExp('^[a-zA-Z0-9][a-zA-Z0-9_\\-$ .]*$'),
              message:
                'Must begin with an alphanumeric character and can contain letters, numbers, spaces, and the following characters: . _ - $',
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Redirect URL"
          name="redirectUrl"
          rules={[
            { required: true, message: 'Redirect URL is required' },
            {
              type: 'url',
              message: URL_MESSAGE,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Homepage Link"
          name="homepageLink"
          rules={[
            {
              type: 'url',
              message: URL_MESSAGE,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Privacy Policy Link"
          name="privacyPolicyLink"
          rules={[
            {
              type: 'url',
              message: URL_MESSAGE,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item
          label="Terms of Service Link"
          name="termsOfServiceLink"
          rules={[
            {
              type: 'url',
              message: URL_MESSAGE,
            },
          ]}
        >
          <Input />
        </Form.Item>
        <Form.Item label="Application Type" name="appType">
          <Select>
            <Select.Option value="Web">Web</Select.Option>
            <Select.Option value="Mobile">Mobile</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Environment" name="environment">
          <Select>
            <Select.Option value="Sandbox">Sandbox</Select.Option>
            <Select.Option value="Live">Live</Select.Option>
          </Select>
        </Form.Item>
        <Form.Item label="Description" name="appDescription">
          <TextArea rows={3} maxLength={140} />
        </Form.Item>
        <ApplicationLogo {...props} />
        <Form.Item {...tailFormItemLayout}>
          <ButtonDiv>
            <CancelButton htmlType="submit">
              <Link to="/home/applications">Cancel</Link>
            </CancelButton>
            <OkButton type="primary" htmlType="submit">
              OK
            </OkButton>
          </ButtonDiv>
        </Form.Item>
      </AppForm>
    </Spin>
  );
};

export default NewApplicationForm;
